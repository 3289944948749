import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/FetchCroppedImage.scss';

function FetchGeneratedImage({ generatedImageID: generatedImageID }) {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        // This function fetches the image and sets the blob URL to state
        const fetchImage = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/generated-image/${generatedImageID}`, {
                    responseType: 'blob',
                    withCredentials: true,  // If your backend needs cookies or auth headers
                }, { withCredentials: true });
                const blob = response.data;
                const blobUrl = URL.createObjectURL(blob);
                setImageUrl(blobUrl);
            } catch (error) {
                console.error("Error fetching the image:", error);
            }
        };

        if (generatedImageID) {
            fetchImage();
        }

        // This is useful if you want to revoke the blob URL and free up resources when the component unmounts
        // return () => {
        //     if (imageUrl) {
        //         URL.revokeObjectURL(imageUrl);
        //     }
        // };
    }, [generatedImageID]);

    const downloadImage = () => {
        // Create a new 'a' element
        const a = document.createElement('a');
        a.href = imageUrl;
        a.download = `GeneratedImage_${Date.now()}.png`; // You can name the file whatever you like
        // Explicitly set the type
        a.type = 'image/png';
        // Append to body to ensure visibility in the DOM
        document.body.appendChild(a);
        a.click(); // Simulate the click event
        // Clean up
        document.body.removeChild(a);
    };

    return (
        <>
            {/* {imageUrl && <img className="matted-image text-center" src={imageUrl} alt="Processed Image" />} */}
            {imageUrl && (
                <div className="image-wrapper--generated">
                    <img className="matted-image text-center" src={imageUrl} alt="Processed File" />
                    <div className="overlay" onClick={downloadImage}>
                        <div className="download-container">
                            <h4>Download</h4>
                            <svg className="download-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="bgCarrier" strokeWidth="0"></g>
                                <g id="tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="iconCarrier">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17 16V14H16V16H8V14H7V16C7 16.5523 7.44772 17 8 17H16C16.5523 17 17 16.5523 17 16ZM14.1465 10.6464L12.5 12.2929V7H11.5V12.2929L9.85356 10.6464L9.14645 11.3536L12 14.2071L14.8536 11.3536L14.1465 10.6464Z" fill="#000000"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FetchGeneratedImage;
