import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import '../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';

const Account = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext); // Get the user object from AuthContext
    const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
    const [isUnsubscribedButServiceActive, setIsUnsubscribedButServiceActive] = useState(false);
    const [hasInvoices, setHasInvoices] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);
    const [hasGeneratedImages, setHasGeneratedImages] = useState(false);
    const [hasBackgroundRemovedImages, setHasBackgroundRemovedImages] = useState(false);
    const [hasBackgroundRegeneration, setHasBackgroundRegeneration] = useState(false);
    const [hasUpscaledImages, setHasUpscaledImages] = useState(false);

    // Fetches image statuses and sets corresponding state variabels for link delivery
    useEffect(() => {
        const checkImageStatus = async () => {
            const generatedImageResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/generated-image-status`, { withCredentials: true });
            if (generatedImageResponse.data.hasImages) { setHasGeneratedImages(true) }

            const freeMattedImageResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/free-matted-image-status`, { withCredentials: true });
            if (freeMattedImageResponse.data.hasImages) { setHasBackgroundRemovedImages(true) }

            const croppedImageResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/cropped-image-status`, { withCredentials: true });
            if (croppedImageResponse.data.hasImages) { setHasBackgroundRegeneration(true) }

            const upscaledImageResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/upscaled-image-status`, { withCredentials: true });
            if (upscaledImageResponse.data.hasImages) { setHasUpscaledImages(true) }
        };

        checkImageStatus();

    }, [])

    // Checks user subscription status and sets state flags necessary for delivering the unsubscribe link
    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-subscription`, { withCredentials: true });
                if (response.data.packageType === 'subscription') {
                    setHasActiveSubscription(true);
                    setIsUnsubscribedButServiceActive(response.data.isUnsubscribedButServiceActive);
                }
            } catch (error) {
                console.error('Error checking subscription:', error);
            }
        };

        checkSubscription();
    }, []);

    // Checks invoice status and sets the necessary state flags for delivering the invoice link
    useEffect(() => {
        const checkInvoices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/invoices`, { withCredentials: true });
                if (response.data.length > 0) {
                    setHasInvoices(true);
                    setInvoiceList(response.data);
                }
            } catch (error) {
                console.error('Error checking invoices:', error);
            }
        };

        checkInvoices();
    }, []);

    // The invoice route
    const handleInvoiceRequest = () => {
        navigate("/account/invoice", { state: { invoiceList: invoiceList } });
    }

    // The Generated Images route
    const handleGeneratedImagesRequest = () => {
        navigate("/account/generated-images");
    }

    // The Background-removed Images route
    const handleFreeMattedImagesRequest = () => {
        navigate("/account/background-removed-images");
    }

    //The Regenerate Backgrounds Route
    const handleRegenerateBackgroundsRequest = () => {
        navigate("/account/regenerate-backgrounds");
    }

    // Turning off invitation-system in production as of version 0.0.2 on 20.12.2023
    // The Friend Invitation Route
    // const handleFriendInviteRequest = () => {
    //     navigate("/account/invite-friend");
    // }

    // The Manage Subscription route
    const handleManageSubscriptionRequest = () => {
        navigate("/account/manage-subscription");
    }

    // The Help route
    const handleHelpRequest = () => {
        navigate("/account/help");
    }

    // The UpscaledImages route
    const handleUpscaledImagesRequest = () => {
        navigate("/account/upscaled-images");
    }

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - User Account</title>
                <meta name="description" content="Access all your generatebg account specific details here. Download generated backgrounds, manage subscription, and more." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/account`} />
            </Helmet>
            <h1>Your Account</h1>
            <div className="processing-card">
                <p className="small-text account-info">🚀 Your login Information: {user && user.email}</p>
                {hasUpscaledImages && (
                    <p>
                        <button className="generic-account-button" onClick={handleUpscaledImagesRequest}>View Generated High-res Images</button>
                    </p>
                )
                }
                {hasGeneratedImages && (
                    <p>
                        <button className="generic-account-button" onClick={handleGeneratedImagesRequest}>Generate High-res Backgrounds / View Previews</button>
                    </p>
                )
                }
                {hasBackgroundRegeneration && (
                    <p>
                        <button className="generic-account-button" onClick={handleRegenerateBackgroundsRequest}>Re-Generate Previews (Low-res Backgrounds)</button>
                    </p>
                )}
                {hasBackgroundRemovedImages && (
                    <p>
                        <button className="generic-account-button" onClick={handleFreeMattedImagesRequest}>View Background-removed Images</button>
                    </p>
                )}
                {/* Turning off invitation-system in production as of version 0.0.2 on 20.12.2023 */}
                {/* <p>
                <button className="generic-account-button" onClick={handleFriendInviteRequest}>Invite Friend</button>
            </p> */}
                <p>
                    <button className="generic-account-button" onClick={handleHelpRequest}>Help</button>
                </p>
                {hasInvoices && (
                    <p>
                        <button className="generic-account-button" onClick={handleInvoiceRequest}>Invoices</button>
                    </p>
                )}
                {hasActiveSubscription && !isUnsubscribedButServiceActive && (
                    <button className="unsubscribe-button" onClick={handleManageSubscriptionRequest}>Manage Subscription</button>
                )}
                {isUnsubscribedButServiceActive && (
                    <>
                        <p className="small-text account-info">❗ Your subscription has been cancelled. You can still access our services to use any remaining unused image credits in your package.</p>
                        <p className="small-text account-info">❗ After you have exhausted all remaining image credits, you will no longer be able to use our services.</p>
                        <p className="small-text account-info">❗ If the cancelation happened automatically, it is highly likely that it was due to repeated failed payment attempts.</p>
                        <p className="small-text account-info">❗ If this was a mistake or if you wish to resubscribe, please visit our website and ensure that your payment details are up to date.</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default Account;