import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingSpinner from './LoadingSpinner';

const ProtectedRoute = ({ children }) => {
    const { isAuthorized, isLoading, isLoggingOut } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {

        if (!isLoading && !isAuthorized && !isLoggingOut) {
            navigate('/login-required');
        }
    }, [isLoading, isAuthorized, isLoggingOut, navigate]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!isAuthorized) {
        return null;  // This ensures the component doesn't render its children while waiting for the useEffect to kick in
    }

    return children;
};

export default React.memo(ProtectedRoute);