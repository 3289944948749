import React, { useState, useEffect, useContext } from 'react';
import '../styles/paymentManagementStyles.scss';
import { AuthContext } from '../../context/AuthContext.js';

const PackageCard = ({ title, packageType, packages, selectedPkg, setSelectedPkg, handleCheckout, isHighlighted }) => {
    const [isDefaultPackage, setIsDefaultPackage] = useState(true);
    const authContext = useContext(AuthContext);
    const [isSignupValid, setIsSignupValid] = useState(true);

    // if (packages && packageType === 'subscription' && isDefaultPackage) {
    //     const defaultPackage = packages.find(pkg => pkg.packageName === 'Pro');
    //     if (defaultPackage) {
    //         setSelectedPkg(defaultPackage);
    //         console.log('Default subscription set');
    //     }
    // } else if (packages && packageType === 'imageToGo' && isDefaultPackage) {
    //     const defaultPackage = packages.find(pkg => pkg.packageName === '50ToGo');
    //     if (defaultPackage) {
    //         setSelectedPkg(defaultPackage);
    //         console.log('Default ImageToGo set');
    //     }
    // };

    useEffect(() => {
        if (isDefaultPackage) {
            let defaultPackage;
            if (packages && packageType === 'subscription') {
                defaultPackage = packages.find(pkg => pkg.packageName === 'Monthly 30: High-Res');
                // console.log('Default subscription set');
            } else if (packages && packageType === 'imageToGo') {
                defaultPackage = packages.find(pkg => pkg.packageName === '30ToGo');
                // console.log('Default ImageToGo set');
            }

            if (defaultPackage) {
                setSelectedPkg(defaultPackage);
            }
        }
    }, [packages, packageType, isDefaultPackage, setSelectedPkg]);

    return (
        <div className={`package-card ${isHighlighted ? 'highlighted' : ''}`}>
            <p className="package-card-title">{title}</p>
            {selectedPkg ? (
                <p className="package-card-price">
                    <span className="package-card-price-number"><b>€{(selectedPkg.price / selectedPkg.imageCountLimit).toFixed(2)}</b></span>
                    <span className="package-card-price-text-1">per</span>
                    <span className="package-card-price-text-2">credit</span>
                </p>
            ) : ''
            }
            {packageType === 'free' ? (
                <div className="package-card-option-free">
                    <svg className="open-lock" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path d="M801 385.104l-456.96.001-16.56-74.273C298.24 205.456 347.296 100.4 459.568 70.32c111.136-29.776 209.088 33.936 237.824 141.12l13.6 53.967c4.576 17.073 22.112 27.2 39.2 22.624 17.072-4.576 27.2-22.112 22.624-39.184L759.2 194.879C721.216 53.039 588.815-30.561 443.008 8.495 296.64 47.71 227.296 187.919 264.672 327.407l12.864 57.696H224c-70.592 0-128 57.408-128 128v384c0 70.592 57.408 128 128 128h576c70.592 0 128-57.408 128-128v-384c0-70.592-57.408-128-128-128zm64 512c0 35.28-28.72 64-64 64H224c-35.28 0-64-28.72-64-64v-384c0-35.28 28.72-64 64-64h576c35.28 0 64 28.72 64 64v384zm-352-320c-35.344 0-64 28.656-64 64 0 23.632 12.96 44.032 32 55.12v104.88c0 17.664 14.336 32 32 32s32-14.336 32-32v-104.88c19.04-11.088 32-31.504 32-55.12 0-35.344-28.656-64-64-63z" />
                    </svg>

                    <div className="package-card-list">
                        <li className="package-card-list-item">
                            <span className="package-card-colored-tick">✔️</span>
                            <span className="package-card-list-text">Remove Background (only)</span>
                        </li>
                        <li className="package-card-list-item">
                            <span className="package-card-colored-tick">✔️</span>
                            <span className="package-card-list-text">Image Size upto 5 MB</span>
                        </li>
                        <li className="package-card-list-item">
                            <span className="package-card-colored-tick">✔️</span>
                            <span className="package-card-list-text">Image Res upto 4K</span>
                        </li>
                        <button
                            className="package-card-button"
                            onClick={() => authContext.isAuthorized ? setIsSignupValid(false) : window.location.href = '/register'}
                        >
                            Sign Up
                        </button>
                        {isSignupValid ? '' : (<li className="package-card-list-item">
                            <span className="package-card-colored-tick">❗</span>
                            <span className="package-card-list-text">You've already signed up.</span>
                        </li>)}
                    </div>
                </div>
            ) : (
                packages && packages.map((pkg) => (
                    <div key={pkg._id} className="package-card-option">
                        <input
                            type="radio"
                            id={pkg.packageName}
                            name={packageType}
                            value={pkg.packageName}
                            onChange={() => {
                                if (setSelectedPkg) {
                                    setSelectedPkg(pkg);
                                    setIsDefaultPackage(false); // Turn off default package selection on change
                                }
                            }}
                            checked={selectedPkg && selectedPkg._id === pkg._id}
                        />
                        <label htmlFor={pkg.packageName}>
                            <span className="moment-combo">
                                <span className="first-moment">{pkg.firstMoment} </span>
                                <span className="second-moment">{pkg.secondMoment} </span>
                            </span>
                            <span className="price">€ {pkg.price} </span>
                            <span className="third-moment"><b>€ {(pkg.price / pkg.imageCountLimit).toFixed(2)} / credit</b></span>
                        </label>
                    </div>
                ))
            )}
            {packageType === 'subscription' && (
                <button
                    className="package-card-button"
                    onClick={() => authContext.isAuthorized ? handleCheckout() : window.location.href = '/register'}
                >
                    Subscribe Now
                </button>
            )}
            {packageType === 'imageToGo' && (
                <button
                    className="package-card-button"
                    onClick={() => authContext.isAuthorized ? handleCheckout() : window.location.href = '/register'}
                >
                    Buy Now
                </button>
            )
            }
        </div >
    );
};

export default PackageCard;