import React, { useState } from 'react';
import '../styles/CommonComponentStyles.scss';
import { useNavigate } from 'react-router-dom';
import TermsEn from './core_content/TermsEn.js'; // English version
import TermsDe from './core_content/TermsDe.js'; // German version
import { Helmet } from 'react-helmet-async';

const GeneralTermsAndConditions = () => {
    const navigate = useNavigate();
    const [isEnglish, setIsEnglish] = useState(true); // State to toggle between languages

    // Routes one level back to /account/help
    const handleBackRequest = () => {
        navigate("/");
    };

    const toggleLanguage = () => {
        setIsEnglish(!isEnglish); // Toggle the language state
    };

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - General Terms and Conditions</title>
                <meta name="description" content="Read generatebg General Terms and Conditions here" />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/general-terms-conditions`} />
            </Helmet>
            <button className="generic-account-button--center" onClick={handleBackRequest}>Go Back Home</button>
            <button className="generic-account-button--center" onClick={toggleLanguage}>
                {isEnglish ? 'Switch to German' : 'Auf Englisch wechseln'}
            </button>
            {isEnglish ? <TermsEn /> : <TermsDe />}
        </div>
    );
};

export default GeneralTermsAndConditions;
