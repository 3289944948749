import React from 'react';
import '../styles/testimonialStyles.scss';
import TestimonialCard from './TestimonialCards';
import Karthik from '../../assets/testimonials/Karthik.webp';
import Sabine from '../../assets/testimonials/Sabine.webp';
import Tobias from '../../assets/testimonials/Tobias.webp';
import Patrick from '../../assets/testimonials/Patrick.webp';
import Philipp from '../../assets/testimonials/Philipp.webp';
import Nick from '../../assets/testimonials/Nick.webp';

const testimonials = [
    {
        title: "Very satisfied & would recommend!",
        text: "I bought a one-time plan for 4 images and found the app very practical. When I had questions, the support was quick to respond. I highly recommend the app!",
        imageSrc: Sabine,
        author: "Sabine O.",
        stars: 5,
        role: "Sales & Purchasing Professional"
    },
    {
        title: "Clean & useful app. Recommended!",
        text: "I use generatebg for creating mock product images in design projects. It’s simple and does its job well.",
        imageSrc: Karthik,
        author: "Karthik R.",
        stars: 4,
        role: "Lead Designer"
    },
    {
        title: "Quick & reliable feature updates",
        text: "For our Ecommerce applications, we had special requests to the team at Generatebg. They delivered at a lightning pace. Good Stuff!",
        imageSrc: Patrick,
        author: "Patrick N.",
        stars: 5,
        role: "Founder & Generalist"
    },
    {
        title: "Fan of the free remove background feature",
        text: "Besides the main feature to generate new backgrounds, I regularly use the free feature to remove backgrounds. Highly recommend this service :)",
        imageSrc: Tobias,
        author: "Tobias D.",
        stars: 4,
        role: "Researcher & Trainer"
    },
    {
        title: "Highly satisfied",
        text: "I'm highly satisfied with Generatebg. I'm still at the beginning but for the areas in which I need the tool I can't complain.",
        imageSrc: Nick,
        author: "Nick S.",
        stars: 5,
        role: "Senior Ecommerce Manager"
    },
    {
        title: "Good tool - saves me time",
        text: "This tool saves me a lot of time when I need to do 3D print product photo shoots. Highly recommended!",
        imageSrc: Philipp,
        author: "Philipp K.",
        stars: 4,
        role: "3D Print Shop Owner & Mech. Eng."
    },
    // Add more testimonials as needed
];

const Testimonial = () => {
    return (
        <>
            <h2 className="testimonial-header">
                <span className="heart">❤ </span>
                Loved by our customers
                <span className="heart"> ❤</span>
            </h2>
            <div className="testimonial">
                {testimonials.map((testimonial, index) => (
                    <TestimonialCard key={index} testimonial={testimonial} />
                ))}
            </div>
        </>
    );
};

export default Testimonial;
