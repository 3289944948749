import React from 'react';
import '../../styles/LegalStyles.scss';
import OfficialGermanNotice from './OfficialGermanNotice.js';

const PrivacyEn = () => {
    return (
        <div className="legal-doc">
            <OfficialGermanNotice />
            <h1 className="title-left">Privacy Policy</h1>
            <h3 className="title-left">A. Introduction</h3>
            <p>
                generatebg UG (haftungsbeschränkt) (hereinafter collectively referred to as "the Company", "we" or "us") takes the protection of your personal data seriously and would like to inform you about data protection in our company. With the enactment of the EU General Data Protection Regulation (Regulation (EU) 2016/679; hereinafter: "GDPR"), additional obligations have been imposed on us to ensure the protection of personal data of the individuals affected by processing (hereafter also referred to as “user”, “you”, “your”, “customer” or “data subject”).
            </p>
            <p>
                As long as we either alone or jointly with others decide on the purposes and means of data processing, we are under the obligation to inform you about the nature, scope, purpose, duration, and legal basis of the processing, see Art. 13 & Art. 14 GDPR. With this privacy policy, we inform you about how your personal data are processed by us.
            </p>
            <h3 className="title-left">B. Basic Details</h3>
            <h3>1. Definitions</h3>
            <p>
                According to Art. 4 GDPR, the following definitions apply to this privacy policy:
            </p>
            <p className="text-indented">
                –	"Personal data" according to Art. 4 No. 1 GDPR are all information relating to an identified or identifiable natural person ("data subject"). A person is identifiable if they can be directly or indirectly identified, in particular by reference to an identifier such as a name, an identification number, an online identifier, location data, or by means of information about their physical, physiological, genetic, mental, economic, cultural or social identity characteristics. Identifiability can also be given through the combination of such information or other additional knowledge. The nature, form, or embodiment of the information is irrelevant (photos, video or audio recordings can also contain personal data).
            </p>
            <p className="text-indented">
                – 	"Controller" according to Art. 4 No. 7 GDPR is the natural or legal person, authority, institution or other body that alone or jointly with others determines the purposes and means of the processing of personal data.
            </p>
            <p className="text-indented">
                – 	"Processing" according to Art. 4 No. 2 GDPR means any operation or set of operations which is performed on personal data, whether or not by automated means. This includes in particular the collection, recording, organization, structuring, storage, adaptation, retrieval, consultation, use, disclosure, dissemination, alignment, linking, restriction, erasure or destruction of personal data, as well as the modification of an original purpose or objective underlying data processing.
            </p>
            <p className="text-indented">
                – 	"Sub-processor" according to Art. 4 No. 8 GDPR is a natural or legal person, authority, institution or other body that processes personal data on behalf of the controller, particularly in accordance with their instructions. In data protection terms, a sub-processor is not a third party.
            </p>
            <p className="text-indented">
                – 	"Consent" of the data subject according to Art. 4 No. 11 GDPR means any freely given, specific, informed and unambiguous indication of the data subject's wishes by which they, by a statement or by a clear affirmative action, signify agreement to the processing of personal data relating to them.
            </p>
            <p className="text-indented">
                – 	"Third party" according to Art. 4 No. 10 GDPR is any natural or legal person, authority, institution or other body other than the data subject, the controller, the sub-processor, and the persons who, under the direct authority of the controller or sub-processor, are authorized to process the personal data; this includes other legal entities within the same group of companies.
            </p>
            <h3>2. Changes to the Privacy Policy </h3>
            <p>
                As part of the ongoing development of data protection laws as well as technological or organizational changes, our privacy notices are regularly reviewed for the need for adjustments or supplements. You will be informed about any changes.
            </p>
            <h3>3. No Obligation to Provide Personal Data</h3>
            <p>
                Conclusion of a contract is not made dependent on the provision of personal data. In principle, there is no legal or contractual obligation for you to provide us with your personal data. However, it is possible that certain services can only be provided limitedly or not at all if you do not provide the necessary data for this. If this is the case, you will be specifically informed.
            </p>
            <h3 className="title-left">C. Information about the Processing of Your Data</h3>
            <h3>1. Collection of Personal Data Concerning You</h3>
            <p>
                (1) When using our app, personal data about you will be collected.
            </p>
            <p>
                (2) Personal data is all data that relates to you as a person. This includes, among other things, your name, location data, IP address, device identification, SIM card number, your address and email address, your fingerprint, images, films, audio recordings, as well as your user behavior.
            </p>
            <h3>2. Legal Basis for Data Processing</h3>
            <p>
                (1) The processing of personal data is lawful if the data processing falls under one of the following justifications:
            </p>
            <p className="text-indented">
                – 	Art. 6 para. 1 sentence 1 lit. a GDPR ("Consent"): If the data subject has voluntarily, informedly, and unambiguously indicated by a declaration or other explicit affirmative action, that they agree to the processing of personal data concerning them for one or more specific purposes;
            </p>
            <p className="text-indented">
                – 	Art. 6 para. 1 sentence 1 lit. b GDPR: If processing is necessary for the performance of a contract to which the data subject is party, or to take steps at the request of the data subject prior to entering into a contract;
            </p>
            <p className="text-indented">
                – 	Art. 6 para. 1 sentence 1 lit. c GDPR: If processing is necessary for compliance with a legal obligation to which the controller is subject;
            </p>
            <p className="text-indented">
                – 	Art. 6 para. 1 sentence 1 lit. d GDPR: If processing is necessary to protect the vital interests of the data subject or of another natural person;
            </p>
            <p className="text-indented">
                – 	Art. 6 para. 1 sentence 1 lit. e GDPR: If processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller; or
            </p>
            <p className="text-indented">
                –   Art. 6 para. 1 sentence 1 lit. f GDPR ("Legitimate Interests"): If processing is necessary for the purposes of the legitimate (particularly legal or economic) interests pursued by the controller or by a third party, unless the data subject's conflicting interests or rights prevail (especially if the data subject is a minor).
            </p>
            <p>
                (2) Processing may also be based on several legal grounds. Any applicable legal basis will be explicitly mentioned in the following.
            </p>
            <h3>3. Data Collected During Download</h3>
            <p>
                (1) When downloading this app, necessary data about you are transmitted to the respective app store. In particular, the email address, username, customer identifier of the downloading account, the unique device identifier, payment information, and the time of the download are transferred to the app store.
            </p>
            <p>
                (2) We have no influence over the collection and processing of this data; it is carried out exclusively by the store. Responsibility for the processing and collection of your data lies solely with the app store you have selected. We bear no responsibility in this regard.
            </p>
            <h3>4. Data Collected During Usage</h3>
            <p>
                (1) To be able to provide all the functionalities of our app, data about you, as determined by us and necessary for the operation of the app, must inevitably be collected when using the app. We collect this data only if it is necessary for the fulfillment of the contract (Art. 6 para. 1 lit. b GDPR). Furthermore, we collect data if it is necessary for the functionality of the app and your interest in protecting your personal data does not prevail (Art. 6 para. 1 lit. f GDPR).
            </p>
            <p>
                (2) We collect and process the following data from you:
            </p>
            <p className="text-indented">
                –	Data you provide to us: For the use of the app, creating a user account is required. For this, you provide at least your login identifier (email address).
            </p>
            <p className="text-indented">
                –	Device information: Access data includes the IP address, device ID, type of device, device-specific settings and app settings, as well as app properties, the date and time of access, time zone, the amount of data transferred, and the report whether the data exchange was complete, app crashes, browser type, and operating system. This access data is processed to enable the technical operation of the app.
            </p>
            <p className="text-indented">
                –	Information with your consent: Other information, including GPS location data, is processed if you grant us permission.
            </p>
            <p className="text-indented">
                –	Contact form data: When using contact forms, the transmitted data are processed, including gender, name, address, company, email address, and the time of transmission.
            </p>
            <h3>5. Cookies</h3>
            <p>
                The "Cookie Policy" can be found at this link: <a href={`${process.env.REACT_APP_CLIENT_URL}/cookie-policy`}>({process.env.REACT_APP_CLIENT_URL}/cookie-policy)</a>.
            </p>
            <h3>6. Duration of Data Storage</h3>
            <p>
                (1) We delete your personal data as soon as they are no longer necessary for the purposes for which we collected or used them. We store your personal data for the duration of the usage or contractual relationship via the app. As a general rule, your data is stored only on our servers in the European Union and the USA, subject to any potential disclosure.
            </p>
            <p>
                (2) In the case of an ongoing legal dispute with you or another legal proceeding, storage may extend beyond the specified period.
            </p>
            <p>
                (3) Third parties and/or sub-processors we employ will store your data on their systems for as long as is necessary for the performance of the service for us in accordance with the respective assignment.
            </p>
            <p>
                (4) Legal requirements for the retention and deletion of personal data remain unaffected by the foregoing (e.g., § 257 HGB or § 147 AO). If the storage period prescribed by legal regulations expires, blocking or deletion of personal data will occur unless further storage by us is necessary and there is a legal basis for it.
            </p>
            <h3>7. Data Security</h3>
            <p>
                We employ appropriate technical and organizational security measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction, or unauthorized access by third parties, taking into account the state of the art, the costs of implementation, and the nature, scope, context, and purposes of processing as well as the existing risks of a data breach (including its likelihood and impact) for the data subject. Our security measures are continuously improved in line with technological development.
            </p>
            <h3>8. Automated Decision Making </h3>
            <p>
                There is no intention to use the personal data collected from you for any automated decision-making process (including profiling).
            </p>
            <h3>9. Purpose Modification</h3>
            <p>
                (1) Processing of your personal data for purposes other than those described will only occur if allowed by a legal provision or if you have consented to the changed purpose of data processing.
            </p>
            <p>
                (2) In the event of further processing for purposes other than those for which the data were originally collected, we will inform you about these purposes before the further processing and provide you with all other relevant information for this.
            </p>
            <h3 className="title-left">D. Responsibility for Your Data and Contacts</h3>
            <h3>1. Controller and Contact Details</h3>
            <p>(1) The party responsible for processing your personal data in the sense of Art. 4 No. 7 GDPR is us:</p>
            <p>generatebg UG (haftungsbeschränkt)</p>
            <p>Freiligrathstr. 47</p>
            <p>50935 Cologne</p>
            <p>Germany</p>
            <p>Email: support@generatebg.com</p>
            <p>
                For matters regarding data protection, our company data protection correspondent is always at your disposal. His contact details are:
            </p>
            <p>
                Hemanth Kumar Balasubramanian
            </p>
            <p>
                support@generatebg.com
            </p>
            <p>
                (2) Please contact the correspondent if you wish to assert your rights against us, or if you have any questions or comments about the collection and processing of your personal data.
            </p>
            <h3>2. Data Collection When Making Contact </h3>
            <p>
                When you contact us, your email address, name, and any other personal data you provide during the contact will be stored by us so that we can contact you to answer your query. These data will be deleted as soon as their storage is no longer necessary. If there are legal retention periods, the data will remain stored, but processing will be restricted.
            </p>
            <h3 className="title-left">E. Data Processing by Sub-processors and Third Parties</h3>
            <h3>1. Data Processing by Sub-processors </h3>
            <p>
                (1)  If we use commissioned service providers for individual functions of our app, they act only according to our instructions. They are contractually obliged under Art. 28 GDPR to comply with data protection regulations.
            </p>
            <p>
                (2) The following categories of recipients, who are usually third-parties or sub-processors, may have access to your personal data:
            </p>
            <p className="text-indented">
                –	Third-parties who are Service providers for the operation of our app and the processing of data stored or transmitted by the systems. The legal basis for the transfer is then Art. 6 para. 1 sentence 1 lit. b or lit. f GDPR, unless they are sub-processors;
            </p>
            <p className="text-indented">
                –	Sub-processors who are Service providers for the operation of our app and the processing of data stored or transmitted by the systems. The legal basis for the transfer is then Art. 28 in combination with Art. 6 Para. 1 lit. b or lit. f GDPR, depending on the specific purpose of the data processing;
            </p>
            <p className="text-indented">
                –	Government authorities, if this is necessary to fulfill a legal obligation. The legal basis for the transfer is Art. 6 para. 1 sentence 1 lit. c GDPR;
            </p>
            <p className="text-indented">
                –	Persons employed in the execution of our business operations. The legal basis for the transfer is Art. 6 para. 1 sentence 1 lit. b or lit. f GDPR.
            </p>
            <p>
                (3) We only pass on your personal data to third parties if you have given explicit consent to this under Art. 6 para. 1 sentence 1 lit. a GDPR.
            </p>
            <p>
                (4) Insofar as personal data about you is passed on by us to our subsidiaries, this is done based on existing data processing agreements.
            </p>
            <p>
                (5) Below, you will find the complete list of sub-processors with the purpose they fulfill and information about their respective organisations and privacy policies.
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Hosting of the Webseite:
            </p>
            <p className="text-indented">
                Service Provider: Hostinger International Ltd., 61 Lordou Vironos str., 6023 Larnaca, Cyprus.
            </p>
            <p className="text-indented">
                Website: https://www.hostinger.com/
            </p>
            <p className="text-indented">
                Privacy Policy: https://www.hostinger.com/legal/privacy-policy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Database Infrastructure:
            </p>
            <p className="text-indented">
                Service Provider: MongoDB, Inc., 1633 Broadway, 38th Floor New York, NY 10019, United States.
            </p>
            <p className="text-indented">
                Website: https://www.mongodb.com/
            </p>
            <p className="text-indented">
                Privacy Policy: https://www.mongodb.com/legal/privacy-policy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Cloud Computing Infrastructure:
            </p>
            <p className="text-indented">
                Service Provider: Replicate, Inc., 2261 Market Street #4056, San Francisco, California 94114, United States.
            </p>
            <p className="text-indented">
                Website: https://replicate.com/
            </p>
            <p className="text-indented">
                Privacy Policy: https://replicate.com/privacy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Data Storage Infrastructure:
            </p>
            <p className="text-indented">
                Service Provider: Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, L-1855, Luxembourg.
            </p>
            <p className="text-indented">
                Website: https://aws.amazon.com/
            </p>
            <p className="text-indented">
                Privacy Policy: https://aws.amazon.com/privacy/
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Payment System Infrastructure:
            </p>
            <p className="text-indented">
                Service Provider: Stripe Payments Europe, Limited (SPEL), 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, D02 H210, Ireland.
            </p>
            <p className="text-indented">
                Website: https://stripe.com/
            </p>
            <p className="text-indented">
                Privacy Policy: https://stripe.com/de/privacy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Web Performance & Security Services Infrastructure:
            </p>
            <p className="text-indented">
                Service Provider: Cloudflare Germany GmbH, Rosental 7, 80331 München
            </p>
            <p className="text-indented">
                Website: https://www.cloudflare.com/
            </p>
            <p className="text-indented">
                Privacy Policy: https://www.cloudflare.com/privacypolicy/
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Analytics and Event-handling:
            </p>
            <p className="text-indented">
                Service Provider: Google Tag Manager (Google LLC), Gordon House, Barrow Street, Dublin 4, Ireland
            </p>
            <p className="text-indented">
                Website: https://tagmanager.google.com/
            </p>
            <p className="text-indented">
                Privacy Policy: https://policies.google.com/privacy
            </p>
            <p>-----------------------------</p>
            <h3>2. Conditions for the Transfer of Personal Data to Third Countries </h3>
            <p>
                (1) As part of our business relationships, your personal data may be transferred or disclosed to third-party companies. These may also be located outside the European Economic Area (EEA). Such processing is carried out exclusively to fulfill contractual and business obligations and to maintain your business relationship. We will inform you of the specific details of the transfer in the relevant sections below.
            </p>
            <p>
                (2) The European Commission certifies some third countries with so-called adequacy decisions as having a level of data protection comparable to EEA standards (a list of these countries and a copy of the adequacy decisions can be found here: https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en). However, in other third countries to which personal data may be transferred, there may not be consistently high levels of data protection due to the lack of legal regulations. Where this is the case, we ensure that data protection is sufficiently guaranteed. This can be done through binding corporate rules, standard contractual clauses of the European Commission for the protection of personal data, certificates, or recognized codes of conduct. Please contact our data protection correspondent if you would like more information on this.
            </p>
            <h3>3. Legal Obligation to Transmit Data</h3>
            <p>
                In individual cases, we are legally obligated to provide lawfully collected personal data to third parties, especially to public authorities, according to Art. 6 para. 1 sentence 1 lit. c GDPR.
            </p>
            <h3 className="title-left">F. Your Rights</h3>
            <h3>1. Right to Information</h3>
            <p>
                You have the right under Art. 15 GDPR to obtain information about the personal data concerning you. This requires a request from you, which must be sent either by email or by post to the addresses provided above.
            </p>
            <h3>2. Objection to Data Processing and Revocation of Consent</h3>
            <p>
                (1) According to Art. 21 GDPR, you have the right to object at any time to the processing of personal data concerning you. We will stop processing your personal data unless we can demonstrate compelling reasons for the processing that outweigh your interests, rights, and freedoms, or if the processing serves the establishment, exercise, or defense of legal claims.
            </p>
            <p>
                (2) According to Art. 7 para. 3 GDPR, you have the right to revoke your consent – your voluntary, informed, and unambiguous will, expressed by a statement or other clear affirmative action, indicating that you agree to the processing of the relevant personal data for one or more specific purposes – at any time. This means that we may no longer continue the data processing based on this consent.
            </p>
            <p>
                (3) Please contact the contact point provided above to make this known.
            </p>
            <h3>3. Right to Rectification and Erasure</h3>
            <p>
                (1)  Insofar as personal data concerning you are inaccurate, you have the right, according to Art. 16 GDPR, to demand their immediate rectification from us. Furthermore, under the conditions specified in Art. 17 GDPR, you have the right to request the erasure of personal data concerning you. The right to erasure applies in particular if the data in question are no longer necessary for the purposes of collection or processing, if the data storage period has expired, if there is an objection, or if the processing is unlawful. Please contact the contact point provided above with a request to this effect.
            </p>
            <p>
                (2)  To assert these rights, please contact the contact point provided above.
            </p>
            <h3>4. Right to Restriction of Processing</h3>
            <p>
                (1) Under Art. 18 GDPR, you have the right to demand from us the restriction of processing of your personal data.
            </p>
            <p>
                (2)  Please contact the contact point provided above with a request to this effect.
            </p>
            <p>
                (3) The right to restriction of processing particularly applies if the accuracy of the personal data is contested between you and us; in this case, the right applies for a period enabling us to verify the accuracy of the personal data. The same applies if the successful exercise of an objection right between you and us is still contested. You also have this right in particular if you have a right to erasure and instead of erasure, you request restricted processing.
            </p>
            <h3>5. Right to Data Portability</h3>
            <p>
                (1) In accordance with Art. 20 GDPR, you have the right to receive the personal data concerning you, which you have provided to us, in a structured, commonly used, and machine-readable format.
            </p>
            <p>
                (2)  Please contact the contact point provided above with a request to this effect.
            </p>
            <h3>6. Right to Lodge a Complaint with a Supervisory Authority</h3>
            <p>
                (1) According to Art. 77 GDPR, you have the right to lodge a complaint about the collection and processing of your personal data with the competent supervisory authority.
            </p>
            <p>
                (2)  Jurisdiction is based on our company's location, your habitual residence, or your workplace.
            </p>

        </div>
    );
};

export default PrivacyEn;