import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Testimonials from './marketing/Testimonials';
// import landingVideoProduct from '../assets/wasserrad_landing_product.mp4';
// import landingVideoProductMOV from '../assets/wasserrad_landing_product.mov';
// import landingVideoPortrait from '../assets/wasserrad_landing_portrait.mp4';
// import landingGif from '../assets/landing_0.9.gif';
// import gridItem1 from '../assets/gridItem1.jpg';
// import gridItem2 from '../assets/gridItem2.jpg';
// import gridPair1 from '../assets/gridPair1.png';
// import gridPair2 from '../assets/gridPair2.png';
// import landingpng1 from '../assets/landing_png_1.png';
import spiceGrinderInput from '../assets/spice_grinder_input.webp';
import spiceGrinderWithAIGeneratedBackground from '../assets/spice_grinder_with_ai_generated_background.webp';
import ImageSlider from './ImageSlider';
import FAQSection from './FAQSection';
import Collage from './Collage';
// import landingpng2 from '../assets/landing_png_2.png';
import GeneratebgDemo from './GeneratebgDemo';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as Laurel } from '../assets/laurel/laurel.svg';
import { ReactComponent as Star } from '../assets/laurel/star.svg';
// import { ReactComponent as TrustPilotLogo } from '../assets/asSeenOn/trustpilot.svg';
// import TrustPilotLogo from '../assets/asSeenOn/trustpilot.png';
import { ReactComponent as TrustPilotLogo } from '../assets/asSeenOn/trustpilot.svg';
import { ReactComponent as ProductHuntLogo } from '../assets/asSeenOn/producthunt.svg';
// import HackerNewsLogo from '../assets/asSeenOn/hackernews.png';

const Home = function () {

    // const [currentH2Text, setCurrentH2Text] = useState(0);
    // const h2Texts = ['Product Photography', 'Headshot Photos', 'Hobby Projects'];
    // const [isVisible, setIsVisible] = useState(true);

    const generatebgDemoRef = useRef(null);
    const [isRotated, setIsRotated] = useState(true);


    const { isAuthorized, checkUserSession } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        checkUserSession();
    }, []);

    // The Generated Images route
    const handleGetStartedRequest = () => {
        isAuthorized ? navigate('/account') : navigate("/register");
    }

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (generatebgDemoRef.current) {
    //             const { top } = generatebgDemoRef.current.getBoundingClientRect();
    //             const windowHeight = window.innerHeight;
    //             setIsRotated(top > windowHeight / 2);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

    const handleDemoRequest = () => {
        if (generatebgDemoRef.current) {
            const elementPosition = generatebgDemoRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - 100; // Adjusts for 20px above the element

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };




    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         // First, hide the current text
    //         setIsVisible(false);

    //         // After a short delay, change the text and show it
    //         setTimeout(() => {
    //             setCurrentH2Text((prevText) => (prevText + 1) % h2Texts.length);
    //             setIsVisible(true);
    //         }, 500); // Adjust this delay as needed

    //     }, 3500); // Total cycle time including the delay

    //     return () => clearInterval(intervalId);
    // }, []);


    return (
        <div className="App-home">
            <Helmet>
                <title>AI Background Generator - High-res Backgrounds in One Click!</title>
                <meta name="description" content="Generate high-res backgrounds with one click!" />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}`} />
            </Helmet>
            <div className="App-landing--primary-block">
                <div className="App-landing-hero-left">
                    <ImageSlider
                        image1={spiceGrinderWithAIGeneratedBackground}
                        image2={spiceGrinderInput}
                    />
                    <p className="text-center App-landing--subtitle hidden">
                        Craft stunning backdrops for your shots with the simplest tool at your fingertips.
                    </p>
                    <p className="text-center App-landing--subtitle hidden">
                        Capture perfection every time.
                    </p>
                </div>
                <div className="App-landing-hero-right">
                    <h1 className="site-title">
                        {/* <span className="rocket--small">🚀</span>  */}
                        Beautiful backgrounds that convert visitors into buyers
                        {/* Generate High-res Backgrounds In An Instant */}
                    </h1>
                    <div className="laurel-container">
                        <Laurel className="laurel" />
                        <h2 className="site-subtitle">#1 AI Background Generator</h2>
                        <div className="laurel-star-container">
                            <Star className="laurel-star" />
                            <Star className="laurel-star" />
                            <Star className="laurel-star" />
                            <Star className="laurel-star" />
                            <Star className="laurel-star" />
                        </div>
                    </div>
                    <h3 className="site-super-subtitle">Generate high-res backgrounds in an instant!</h3>
                    <h3 className="site-super-subtitle">No more costly photographers & complicated photoshoots</h3>
                    <div className="call-to-action-section">
                        <button className="subtle-button" onClick={handleGetStartedRequest}>Generate Background</button>
                        <button className="generic-button" onClick={handleDemoRequest}>Free Demo</button>
                    </div>
                </div>
            </div>

            <div className="hero-footer-logos">
                <span>as seen on</span>
                {/* <TrustPilotLogo className="hero-footer-logo" /> */}
                {/* <img src={TrustPilotLogo}
                    alt="Trustpilot logo"
                    className="hero-footer-logo">
                </img> */}
                <TrustPilotLogo className="hero-footer-logo trustpilot" />
                <ProductHuntLogo className="hero-footer-logo producthunt" />
                {/* <img src={HackerNewsLogo}
                    alt="Hacker News logo"
                    className="hero-footer-logo hacker-news">
                </img> */}
            </div>

            <div className="App-landing--secondary-block">
                {/* <h2 className="App-landing--secondary-title">Try our product photo demo for free</h2> */}
                <div ref={generatebgDemoRef} className={`generatebgDemo ${isRotated ? 'generatebgDemo-rotated' : ''}`}>
                    <GeneratebgDemo
                        handleGetStartedRequest={handleGetStartedRequest}
                        isAuthorized={isAuthorized}
                    />
                </div>
            </div>

            <Testimonials />

            <div className="collage-super-wrapper">
                <div className="collage-copy-wrapper">
                    <h3 className="collage-heading">Instantly Enhance Your Product Photos with AI-Generated Backgrounds</h3>
                    <p className="collage-text">These product photos are not real. The backgrounds were all generated using our AI background generator</p>
                    <Collage
                        handleGetStartedRequest={handleGetStartedRequest}
                        isAuthorized={isAuthorized}
                    />
                </div>
            </div>
            <div className="App-landing--secondary-block">
                <h3 className="faq--heading">Frequently Asked Questions</h3>
                <FAQSection />
            </div>
        </div>
    )
};

export default Home;