import React from 'react';
import firstImage from '../../../assets/docs/001_is_generatebg_for_you/examples_of_images_that_work_well_with_generatebg.webp';
import secondImage from '../../../assets/docs/001_is_generatebg_for_you/examples_of_images_that_work_poorly_with_generatebg.webp';
import thirdImage from '../../../assets/docs/001_is_generatebg_for_you/pictorial_help_to_access_the_image_upload_guide.webp';


const IsThisProductForYou = () => {

    return (
        <>
            <h2 className="docs-header">Is Generatebg the Right Choice for You?</h2>
            <h3 className="docs-read-time">Estimated Reading Time: 2 minutes</h3>
            <h3 className="docs-subheader table-of-contents">Table of Contents</h3>
            <ul className="docs-list">
                <li className="docs-list-item">What Generatebg is good at</li>
                <li className="docs-list-item">Limits of our service</li>
                <li className="docs-list-item">How you can decide if Generatebg is for you</li>
            </ul>
            <hr className="horizontal-rule"></hr>
            <h3 className="docs-subheader">What Generatebg is Good at</h3>
            <p>
                Our models currently work well with simple subjects with clearly distinguishable backgrounds. Here are two examples of such images:
            </p>
            <img src={firstImage}
                alt="Two phone shots on the left with generated background on the right; the original shots feature simple subjects with clear and contrasting backgrounds. This leads to beautiful, exquisite generated backgrounds."
                className="docs-image">
            </img>
            <p>
                We are currently focussed on the following use cases:
            </p>
            <ul className="docs-list">
                <li className="docs-list-item"><b>Product photography</b> - For eshop owners, Amazon sellers, etc.</li>
                <li className="docs-list-item"><b>Portrait photography</b> - For social media profile pictures like Linkedin, Twitter, etc.</li>
            </ul>
            <p>Therefore, if you are interested in either of these use cases, Generatebg would be a good product for you.</p>
            <h3 className="docs-subheader">Limits of our service</h3>
            <p>Our current models do not perform well with complex subjects and/or backgrounds.</p>
            <p>Below, you can see how the Generatebg background-removal tool struggles with a complex subject:</p>
            <img src={secondImage}
                alt="An example of a complex subject, where the Generatebg background removal tool struggles with a plant with intricately long and interweaving leaves."
                className="docs-image">
            </img>
            <p>Please note that this limitation is with the current version of our models. We are constantly working on improving our models.</p>
            <p>But until we solve these challenges, these limitations exist, and we wish to be open about them.</p>
            <p>If you require background removal or background generation for complex subjects and/or cluttered backgrounds, then Generatebg is probably not a good fit for you.</p>
            <h3 className="docs-subheader">How You Can Decide if Generatebg is for You</h3>
            <p>You need not rely on this document or any of the images you see here. The good news is that you can test our background-removal service free of charge.</p>
            <p>First, sign up for a Generatebg account free of cost. Once you log in, you will notice that the "Remove Background" tool has appeared in the header.</p>
            <p>Head over there and try the service for an image you intend to use for background generation eventually. But before you do, please refer to the image upload guide for the best results.</p>
            <img src={thirdImage}
                alt="A guide on how to access the Generatebg background removal tool after logging into the service."
                className="docs-image">
            </img>
            <p>If your results are very poor, you can probably conclude that our models are not sufficient to work with the kind of images you expect.</p>
            <p>But should your images have mild artefacts around the edges (after background removal), our generation models handle them well.</p>
            <p>In short, you can see how well the background removal tool works with the images you wish to generate background for. The background removal tool is a good test for how well our models fit your requirements.</p>
            <p>If the initial results are good, Generatebg is probably a good fit for you. </p>
            <p>We hope you find our services useful and are <b>doing our best to improve our models as fast as possible!</b></p>
        </>
    );
};

export default IsThisProductForYou;
