import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import FetchCroppedImage from './FetchCroppedImage';
import '../styles/GenerateBackgroundStyles.scss';
import FetchGeneratedImages from './FetchGeneratedImages';
import '../styles/CommonComponentStyles.scss';
import ImageCountStatus from './ImageCountStatus';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const GenerateBackground = function () {
    const navigate = useNavigate();
    const location = useLocation();
    const croppedImageID = location.state?.croppedImageID;
    const [errorMessage, setErrorMessage] = useState('');

    const [prompt, setPrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [numImages, setNumImages] = useState(4);
    const [strictness, setStrictness] = useState(35);

    const [isLoading, setIsLoading] = useState(false);

    const [imageIDs, setImageIDs] = useState([]);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-subscription`, { withCredentials: true });
                const isPreviewCountLimitReached = response.data.isPreviewCountLimitReached
                if (isPreviewCountLimitReached) {
                    navigate('/service-declined');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            };
        };

        fetchSubscriptionStatus();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a JSON object from the state variables
        const dataToSend = {
            prompt: prompt,
            negativePrompt: negativePrompt,
            numImages: numImages,
            strictness: strictness,
            croppedImageID: croppedImageID
        };

        setIsLoading(true);  // set loading state to true when request starts

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL_2}/images/callGenerate`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                timeout: 600000 // Timeout set to 10 minutes
            }, { withCredentials: true });
            const data = response.data;
            setImageIDs(data.imageIDs);
            setErrorMessage('');  // Clear any previous error messages
        } catch (error) {
            console.error("There was an error sending the data:", error);
            // Check if the error message is related to the image generation limit
            if (error.response && error.response.status === 406) {
                // Debug code; remove in production
                // console.log('Generate Background Error Message: ', error.response);
                // Check if the error is a timeout
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    setErrorMessage('Our app is being impatient and timed out. But rest assured that the images you requested are still being generated. You can access them under account > View Generated Images after ~ 5 minutes.');
                }

                if (error.response.data) {
                    setErrorMessage(error.response.data);
                } else {
                    setErrorMessage('You have reached your image generation limit.');
                }
            } else {
                setErrorMessage('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setIsLoading(false);  // reset loading state after request completes or fails
        }
    };

    return (
        <>
            <Helmet>
                <title>generatebg - Generate Background</title>
                <meta name="description" content="Generate background (low-res preview) using generatebg here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/process/generate-background`} />
            </Helmet>
            <h1 className="Generate-Background-Title">Step 2: Generate Background</h1>
            <ImageCountStatus />
            <div className="Generate-Background">
                <div className="Generate-Background--Left">
                    <FetchCroppedImage
                        croppedImageID={croppedImageID}
                    />
                </div>

                <div className="Generate-Background--Right">
                    <form onSubmit={handleSubmit} className="user-form processing-card">
                        <label className="user-label">
                            Describe what you want in the background:
                            <input className="user-input" type="text" value={prompt} onChange={e => setPrompt(e.target.value)} />
                        </label>

                        <label className="user-label">
                            What should not be in the background?
                            <p className="hint">[Use only when necessary; recommended to leave as is]</p>
                            <input className="user-input" type="text" value={negativePrompt} onChange={e => setNegativePrompt(e.target.value)} />
                        </label>

                        {/* <label className="user-label">
                            Number of Output Images:
                            <input className="user-input custom-slider" type="range" min="1" max="4" value={numImages} onChange={e => setNumImages(e.target.value)} />
                            <span>{numImages}</span>
                        </label> */}

                        {/* Removed this field in version 0.0.2 */}
                        {/* <label className="user-label">
                            Number of Output Images:
                            <p className="hint">[Will be deducted from your package quota after generation]</p>
                            <select
                                className="user-input"
                                value={numImages}
                                onChange={e => setNumImages(e.target.value)}
                            >
                                <option value="2">2</option>
                                <option value="4">4</option>
                            </select>
                        </label> */}

                        {/* Old Strictness factor label/input ocmbo */}
                        {/* <label className="user-label">
                            Strictness Factor:
                            <p className="hint">[Use only when necessary; recommended range: 30% - 50%]</p>
                            <input className="user-input custom-slider" type="range" min="0" max="100" value={strictness} onChange={e => setStrictness(e.target.value)} />
                            <span>{strictness}%</span>
                        </label> */}

                        {/* New Strictness input */}
                        <label className="user-label">
                            Level of creative freedom in generation:
                            <p className="hint">[We strongly recommend 'medium' unless you are not satisfied with initial results ]</p>
                            <select
                                className="user-input"
                                value={strictness}
                                onChange={e => setStrictness(e.target.value)}
                            >
                                <option value={50}>Low</option>
                                <option value={35}>Medium</option>
                                <option value={25}>High</option>
                            </select>
                        </label>


                        {isLoading ? (
                            // Display loading spinner and message when isLoading is true
                            <div className="gen-loader">
                                {/* <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="gen-loading">
                                    <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                                        <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                                        <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                                    </g>
                                </svg> */}
                                <p className="rocket--big text-center">🚀</p>
                                <p className="user-message">🚀 Generating 4 previews (512 x 512 px). This can take upto  5 minutes.</p>
                                <p className="user-message">🚀 Too long for you? Why don't you take care of something else and check back here after ~ 5 minutes?</p>
                                <p className="user-message">🚀 Alternatively, you can check under <b>account &gt; Generate High-res Backgrounds / View Previews</b> after ~ 5 minutes as well.</p>
                            </div>
                        ) : ((!imageIDs.length && !errorMessage) ?
                            <button type="submit" className="user-button">Generate</button> : ''
                        )
                        }
                        {errorMessage && (
                            <p className="user-message">❗ {errorMessage}</p>
                        )}
                    </form>
                </div>
            </div>
            {(imageIDs.length > 0) ?
                (<>
                    <p className="user-message text-center">🚀 Your generated previews:</p>
                    <FetchGeneratedImages imageIDs={imageIDs} />
                </>)
                :
                ''}
        </>
    );
}

export default GenerateBackground;