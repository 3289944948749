import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/DemoStyles.scss';
import axios from 'axios';
import { ReactComponent as TryItOutIcon } from '../assets/tryItOut.svg';

const GeneratebgDemo = ({ handleGetStartedRequest, isAuthorized }) => {
    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isBouncerOff, setIsBouncerOff] = useState(false);
    const [demoHeaderText, setDemoHeaderText] = useState('');
    const [backgrounds, setBackgrounds] = useState([]);
    const [usedImages, setUsedImages] = useState(new Set());
    const [showGenerateButton, setShowGenerateButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isGenerationLimitActive, setIsGenerationLimitActive] = useState(false);
    const [isStep1Active, setIsStep1Active] = useState(true);
    const [isStep2Active, setIsStep2Active] = useState(false);
    const [isStep3Active, setIsStep3Active] = useState(false);
    const demoRef = useRef(null);
    const [mousePosition, setMousePosition] = useState(null);


    const imagesContext = require.context('../assets/demo', true, /\.webp$/);

    const getImage = (productKey, imageName) => {
        return imagesContext(`./${productKey}/${imageName}`);
    };

    const products = {
        product1: {
            images: ['product_1.webp'],
            outputs: Array.from({ length: 33 }, (_, i) => `output_${i + 1}.webp`)
        },
        product2: {
            images: ['product_2.webp'],
            outputs: Array.from({ length: 33 }, (_, i) => `output_${i + 1}.webp`)
        },
        // Add product2, product3, etc...
    };

    const handleImageClick = (productKey) => {
        setSelectedProduct(productKey);
        setIsBouncerOff(true);
        setDemoHeaderText('Your selected image')
        setBackgrounds([]);
        setUsedImages(new Set()); // Reset used images when a new product is selected
        setShowGenerateButton(true);
    };

    const generateBackgrounds = async () => {
        setIsStep1Active(false);
        setIsLoading(true);
        setIsStep3Active(false);
        setIsStep2Active(true);
        setShowGenerateButton(false);
        if (demoRef.current) {
            const elementPosition = demoRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - 100; // Adjusts for 20px above the element

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        };

        try {
            // Log the request date and time
            const requestDate = new Date().toISOString();

            // Prepare the data to be sent to your server
            const demoGenerateRequestData = {
                request_date: requestDate,
            };

            // Send the log data to your server
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/unlogged/demo-generation-request`, demoGenerateRequestData);
        } catch (err) {
            console.error('Error making demo generation request:', err);
        };

        // Generate a random delay between 1 second (1000ms) and 4 seconds (4000ms)
        const randomDelay = Math.floor(Math.random() * 3000) + 1000; // 3000 is the range (4000-1000)

        setTimeout(() => {
            const outputs = products[selectedProduct].outputs;
            // console.log('outputs: ', outputs);
            const randomBackgrounds = getRandomElements(outputs, 2);
            // console.log('randomBackgrounds: ', randomBackgrounds);
            setBackgrounds(randomBackgrounds);
            setShowGenerateButton(false);
            setIsLoading(false);
            setIsStep2Active(false);
            setIsStep3Active(true);
            if (demoRef.current) {
                const elementPosition = demoRef.current.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.scrollY + 300; // Adjusts for 20px above the element

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            };
            setMousePosition(null);
        }, randomDelay); // 4 seconds delay
    };

    const getRandomElements = (arr, count) => {
        let usedSet = new Set(usedImages); // Create a new Set from existing state
        let available = arr.filter(item => !usedSet.has(item)); // Filter out used images
        let result = [];
        while (result.length < count && available.length > 0) {
            const randomIndex = Math.floor(Math.random() * available.length);
            result.push(available[randomIndex]);
            usedSet.add(available[randomIndex]);
            available = available.filter(item => !usedSet.has(item)); // Update available images
        }
        if (available.length === 25) {
            setIsGenerationLimitActive(true);
            setIsLoading(false);
        }
        setUsedImages(usedSet); // Update the state with the new Set
        return result;
    };

    const handleMouseMove = (event) => {
        const { left, top } = event.target.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;
        setMousePosition({ x, y });
    };

    return (
        <div className="demo-wrapper">
            <h3 className="text-center demo-subtitle">{demoHeaderText}</h3>

            <div className="demo-prdoducts-wrapper">
                {/* Include the tryItOut SVG here */}
                <div className={`svg-wrapper ${isBouncerOff ? 'svg-wrapper-hidden' : ''}`}>
                    <TryItOutIcon className="try-it-out-icon" />
                </div>
                <div className="demo-products" ref={demoRef}>

                    {Object.keys(products).map((key) => (
                        <img
                            key={key}
                            // src={require(`../assets/demo/${key}/${products[key].images[0]}`)}
                            src={getImage(key, products[key].images[0])}
                            alt={`Product Photography - ${key}`}
                            onClick={() => handleImageClick(key)}
                            className={`product-image ${selectedProduct === key ? 'highlighted-product' : ''} ${selectedProduct && selectedProduct !== key ? 'hidden-product' : ''}`}
                        />
                    ))}
                </div>
            </div>
            {
                showGenerateButton && (
                    <button
                        className="demo-button"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={() => setMousePosition(null)}
                        onClick={generateBackgrounds}
                        style={
                            mousePosition ? {
                                background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, #9B77FF, #5927E5, #7A4FF3)`
                            } : {}
                        }
                    >
                        Generate random backgrounds
                    </button>
                )
            }
            {isLoading ? (
                <>
                    <p className="rocket--big">🚀</p>
                    <p className="text-center"> Generating requested backgrounds</p>
                    {/* <LoadingSpinner /> */}
                </>
            ) : (
                <>
                    {backgrounds.length > 0 && <h3 className="text-center demo-subtitle">🚀 Your generated backgrounds:</h3>}
                    <div className="demo-generated-images">
                        {backgrounds.map((bg, index) => (
                            <img key={index}
                                src={getImage(selectedProduct, bg)}
                                alt="Background"
                                className="demo-generated-image"
                            />
                        ))}
                    </div>
                </>
            )
            }
            {
                (!isGenerationLimitActive && !isLoading && (backgrounds.length > 0) && (backgrounds.length < 5)) && (
                    <button
                        className="demo-button"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={() => setMousePosition(null)}
                        onClick={generateBackgrounds}
                        style={
                            mousePosition ? {
                                background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, #9B77FF, #5927E5, #7A4FF3)`
                            } : {}
                        }
                    >
                        Generate more backgrounds
                    </button>
                )
            }
            {
                isGenerationLimitActive &&
                // <p className="text-center">❗ Generation limit reached. You may try again later.</p>
                <button
                    className="demo-button"
                    onMouseMove={handleMouseMove}
                    onMouseLeave={() => setMousePosition(null)}
                    onClick={handleGetStartedRequest}
                    style={
                        mousePosition ? {
                            background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, #9B77FF, #5927E5, #7A4FF3)`
                        } : {}
                    }
                >
                    Sign up now
                </button>
            }
            <div className="App-landing--steps">
                <p className={`steps-text ${isStep1Active ? 'active' : ''}`}><b>Step 1:</b> Choose a clean photo </p>
                <span className="App-arrow--right">→</span>
                <span className="App-arrow--down">↓</span>
                <p className={`steps-text ${isStep2Active ? 'active' : ''}`}><b>Step 2:</b> Our AI background generator goes to work </p>
                <span className="App-arrow--right">→</span>
                <span className="App-arrow--down">↓</span>
                <p className={`steps-text ${isStep3Active ? 'active' : ''}`}><b>Step 3:</b> Get beautiful AI generated backgrounds</p>
            </div>
        </div >
    );
};

export default GeneratebgDemo;
