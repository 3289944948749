import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import FetchGeneratedImage from './FetchGeneratedImage';
import '../styles/GenerateBackgroundStyles.scss';
import FetchUpscaledImages from './FetchUpscaledImages';
import '../styles/CommonComponentStyles.scss';
import ImageCountStatus from './ImageCountStatus';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const UpscaleImage = function () {
    const navigate = useNavigate();
    const location = useLocation();
    const generatedImageID = location.state?.generatedImageID;
    const [errorMessage, setErrorMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [imageIDs, setImageIDs] = useState([]);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-subscription`, { withCredentials: true });
                const isImageCountLimitReached = response.data.isImageCountLimitReached
                if (isImageCountLimitReached) {
                    navigate('/service-declined');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            };
        };

        fetchSubscriptionStatus();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a JSON object from the state variables
        const dataToSend = {
            generatedImageID: generatedImageID
        };

        setIsLoading(true);  // set loading state to true when request starts

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL_2}/images/call-upscale`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                timeout: 600000 // Timeout set to 10 minutes
            }, { withCredentials: true });
            const data = response.data;
            setImageIDs(data.imageIDs);
            setErrorMessage('');  // Clear any previous error messages
        } catch (error) {
            console.error("There was an error sending the data:", error);
            // Check if the error message is related to the image generation limit
            if (error.response && error.response.status === 406) {
                // Debug code; remove in production
                // console.log('Generate Background Error Message: ', error.response);
                // Check if the error is a timeout
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    setErrorMessage('Our app is being impatient and timed out. But rest assured that the images you requested are still being generated. You can access them under account > View Generated Images after ~ 5 minutes.');
                }

                if (error.response.data) {
                    setErrorMessage(error.response.data);
                } else {
                    setErrorMessage('You have reached your image generation limit.');
                }
            } else {
                setErrorMessage('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setIsLoading(false);  // reset loading state after request completes or fails
        }
    };
    console.log('imageIds: ', imageIDs);

    return (
        <>
            <Helmet>
                <title>generatebg - Generate High-res Background</title>
                <meta name="description" content="Generate high-res background for your image here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/process/generate-highres-image`} />
            </Helmet>
            <h1 className="Generate-Background-Title">Generate High-Res Image</h1>
            <ImageCountStatus />
            <div className="Generate-Background">
                <div className="Generate-Background--Left">
                    <FetchGeneratedImage
                        generatedImageID={generatedImageID}
                    />
                </div>

                <div className="Generate-Background--Right">
                    <form onSubmit={handleSubmit} className="user-form processing-card">
                        <p className="large-text">Would you like to generate a high-resolution version (2048 x 2048 pixels) of this image?</p>
                        <p className="large-text">Note that <b>once you click</b> on the below 'Generate Image' button, the generation process will start and <b>you will lose one high-res image</b> (2048 x 2048 px) from your quota.</p>
                        <p className="large-text">If you are sure about your choice, click on the button, and we will generate the high-res image for you.</p>
                        {isLoading ? (
                            // Display loading spinner and message when isLoading is true
                            <div className="gen-loader">
                                {/* <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="gen-loading">
                                    <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                                        <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                                        <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                                    </g>
                                </svg> */}
                                <p className="rocket--big text-center">🚀</p>
                                <p className="user-message">🚀 Generating your high-res image (2048 x 2048 px). This can take upto  5 minutes.</p>
                                <p className="user-message">🚀 Too long for you? Why don't you take care of something else and check back here after ~ 5 minutes?</p>
                                <p className="user-message">🚀 Alternatively, you can check under <b>account &gt; View Generated High-res Images</b> after ~ 5 minutes as well.</p>
                            </div>
                        ) : ((!imageIDs.length && !errorMessage) ?
                            <button type="submit" className="user-button">Generate Image</button> : ''
                        )
                        }
                        {errorMessage && (
                            <p className="user-message">❗ {errorMessage}</p>
                        )}
                    </form>
                </div>
            </div>
            <div className="output--centered">
                {(imageIDs.length > 0) ? (
                    <>
                        <p className="user-message text-center">🚀 Your high-res image:</p>
                        <FetchUpscaledImages imageIDs={imageIDs} />
                    </>
                )
                    : ''}
            </div>
        </>
    );
}

export default UpscaleImage;