import React from 'react';
import '../../styles/LegalStyles.scss';

const PrivacyDe = () => {
    return (
        <div className="legal-doc">
            <h1 className="title-left">Datenschutzerklärung</h1>
            <h3 className="title-left">A. Einleitung</h3>
            <p>
                Die generatebg UG (haftungsbeschränkt) (nachfolgend gemeinsam: "das Unternehmen", "wir" oder "uns") nimmt den Schutz Ihrer personenbezogenen Daten ernst und möchten Sie über den Datenschutz im Unternehmen informieren. Durch das Inkrafttreten der EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend: "DS-GVO") sind uns zusätzliche Pflichten auferlegt worden, die den Schutz personenbezogener Daten der von einer Verarbeitung betroffenen Person (wir sprechen Sie als betroffene Person nachfolgend auch mit „Nutzer“, „Sie“, „Ihnen“, Kunde" oder "Betroffener" an) sicherstellen sollen.
            </p>
            <p>
                Soweit wir entweder alleine oder gemeinsam mit anderen über Zweck und Mittel der Datenverarbeitung entscheiden, umfasst dies vor allem die Pflicht, Sie über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung zu informieren, vgl. Art. 13 & Art. 14 DS-GVO. Mit dieser Datenschutzerklärung informieren wir Sie darüber, in welcher Weise Ihre personenbezogenen Daten von uns verarbeitet werden.
            </p>
            <h3 className="title-left">B. Grundlegendes</h3>
            <h3>1. Begriffsbestimmungen</h3>
            <p>
                Gemäß Art. 4 DS-GVO liegen dieser Datenschutzerklärung folgende Begriffsbestimmungen zugrunde:
            </p>
            <p className="text-indented">
                –	"Personenbezogene Daten" nach Art. 4 Nr. 1 DS-GVO sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person ("Betroffener") beziehen. Identifizierbar ist eine Person, wenn sie direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, einer Online-Kennung, Standortdaten oder mithilfe von Informationen zu ihren physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen identifiziert werden kann. Die Identifizierbarkeit kann auch mittels einer Verknüpfung von derartigen Informationen oder anderem Zusatzwissen gegeben sein. Auf das Zustandekommen, die Form oder die Verkörperung der Informationen kommt es nicht an (auch Fotos, Video- oder Tonaufnahmen können personenbezogene Daten enthalten).
            </p>
            <p className="text-indented">
                – 	"Verantwortlicher" nach Art. 4 Nr. 7 DS-GVO ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
            </p>
            <p className="text-indented">
                – 	"Verarbeiten" nach Art. 4 Nr. 2 DS-GVO ist jeder Vorgang, bei dem mit personenbezogenen Daten umgegangen wird, gleich ob mit oder ohne Hilfe automatisierter Verfahren. Dies umfasst insbesondere das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung, die Verbreitung, den Abgleich, die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten sowie die Änderung einer Ziel- oder Zweckbestimmung, die einer Datenverarbeitung ursprünglich zugrunde gelegt wurde.
            </p>
            <p className="text-indented">
                – 	"Auftragsverarbeiter" nach Art. 4 Nr. 8 DS-GVO ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen, insbesondere gemäß dessen Weisungen, verarbeitet. Im datenschutzrechtlichen Sinne ist ein Auftragsverarbeiter kein Dritter.
            </p>
            <p className="text-indented">
                – 	"Einwilligung" nach Art. 4 Nr. 11 DS-GVO der betroffenen Person bezeichnet jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
            </p>
            <p className="text-indented">
                – 	"Dritter" nach Art. 4 Nr. 10 DS-GVO ist jede natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten; dazu gehören auch andere konzernangehörige juristische Personen.
            </p>
            <h3>2. Änderung der Datenschutzerklärung </h3>
            <p>
                Im Rahmen der Fortentwicklung des Datenschutzrechts sowie technologischer oder organisatorischer Veränderungen werden unsere Datenschutzhinweise regelmäßig auf Anpassungs- oder Ergänzungsbedarf hin überprüft. Über Änderungen werden Sie unterrichtet.
            </p>
            <h3>3. Keine Verpflichtung zur Bereitstellung personenbezogener Daten</h3>
            <p>
                Ein Vertragsabschluss wird nicht von der Bereitstellung personenbezogener Daten abhängig gemacht. Grundsätzlich besteht für Sie keine gesetzliche oder vertragliche Verpflichtung, uns Ihre personenbezogenen Daten zur Verfügung zu stellen. Es kann jedoch sein, dass bestimmte Angebote nur eingeschränkt oder gar nicht erbracht werden können, wenn Sie die dafür erforderlichen Daten nicht bereitstellen. Sofern dies Fall sein sollte, werden Sie gesondert darauf hingewiesen.
            </p>
            <h3 className="title-left">C. Informationen über die Verarbeitung Ihrer Daten</h3>
            <h3>1. Die Erhebung Sie betreffender personenbezogener Daten</h3>
            <p>
                (1) Bei der Nutzung unserer App werden personenbezogene Daten über Sie erhoben.
            </p>
            <p>
                (2) Personenbezogene Daten sind alle Daten, die sich auf Ihre Person beziehen. 	Unter anderem handelt es sich dabei um Ihren Namen, Ihre Standortdaten, Ihre IP-Adresse, die Gerätekennung, die SIM-Kartennummer, Ihre Adresse und E-Mail-Adresse, Ihr Fingerabdruck, Bilder, Filme, Audioaufnahmen, aber auch Ihr Nutzerverhalten.
            </p>
            <h3>2. Rechtsgrundlagen der Datenverarbeitung</h3>
            <p>
                (1) Eine Verarbeitung von personenbezogener Daten ist rechtlich, wenn die Datenverarbeitung unter einen der folgenden Rechtfertigungstatbestände fällt:
            </p>
            <p className="text-indented">
                – 	Art. 6 Abs. 1 S. 1 lit. a DS-GVO ("Einwilligung"): Wenn der Betroffene freiwillig, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung zu verstehen gegeben hat, dass er mit der Verarbeitung der ihn betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden ist;
            </p>
            <p className="text-indented">
                – 	Art. 6 Abs. 1 S. 1 lit. b DS-GVO: Wenn die Verarbeitung zur Erfüllung eines Vertrags, dessen Vertragspartei der Betroffene ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf die Anfrage des Betroffenen erfolgen;
            </p>
            <p className="text-indented">
                – 	Art. 6 Abs. 1 S. 1 lit. c DS-GVO: Wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der der Verantwortliche unterliegt
            </p>
            <p className="text-indented">
                – 	Art. 6 Abs. 1 S. 1 lit. d DS-GVO: Wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen des Betroffenen oder einer anderen natürlichen Person zu schützen;
            </p>
            <p className="text-indented">
                – 	Art. 6 Abs. 1 S. 1 lit. e DS-GVO: Wenn die Verarbeitung für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder
            </p>
            <p className="text-indented">
                – 	Art. 6 Abs. 1 S. 1 lit. f DS-GVO ("Berechtigte Interessen"): Wenn die Verarbeitung zur Wahrung berechtigter (insbesondere rechtlicher oder wirtschaftlicher) Interessen des Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die gegenläufigen Interessen oder Rechte des Betroffenen überwiegen (insbesondere dann, wenn es sich dabei um einen Minderjährigen handelt).
            </p>
            <p>
                (2) Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen beruhen. Jegliche zutreffende Rechtsgrundlage wird im Folgenden ausdrücklich genannt.
            </p>
            <h3>3. Beim Download erhobenen Daten</h3>
            <p>
                (1) Beim Download dieser App werden dafür erforderlichen Daten zu Ihrer Person an den entsprechenden App Store übermittelt. Insbesondere die E-Mail-Adresse, der Nutzername, die Kundennummer des herunterladenden Accounts, die individuelle Gerätekennziffer, Zahlungsinformationen sowie der Zeitpunkt des Downloads an den App Store übertragen werden.
            </p>
            <p>
                (2) Auf die Erhebung und Verarbeitung dieser Daten haben wir keinen Einfluss, sie erfolgt ausschließlich durch den Store. Die Verantwortung für die Verarbeitung und Erhebung Ihrer Daten liegt allein beim von Ihnen ausgewählten App Store. Eine Verantwortung unsererseits ist ausgeschlossen.
            </p>
            <h3>4. Bei Nutzung erhobene Daten</h3>
            <p>
                (1) Um jegliche Vorzüge unserer App zur Verfügung stellen zu können, müssen zwangsläufig bei Nutzung der App die von uns bestimmten und für den Betrieb der App erforderlichen Daten zu Ihrer Person erhoben werden. Wir erheben diese Daten nur, sofern dies für die Erfüllung des Vertrags erforderlich ist (Art. 6 Abs. 1 lit. b DS-GVO). Ferner erheben wir Daten, wenn dies für die Funktionsfähigkeit der App erforderlich ist und Ihr Interesse am Schutz Ihrer personenbezogenen Daten nicht überwiegt (Art. 6 Abs. 1 lit. f DS-GVO).
            </p>
            <p>
                (2) Wir erheben und verarbeiten folgende Daten von Ihnen:
            </p>
            <p className="text-indented">
                –	Daten, die Sie uns zur Verfügung stellen: Für die Nutzung der App ist die Erstellung eines Nutzerkontos erforderlich. Dafür geben Sie mindestens Ihren Anmeldenamen (Email Adresse) an.
            </p>
            <p className="text-indented">
                –	Geräteinformationen: Zu den Zugriffsdaten gehören die IP-Adresse, Geräte-ID, Geräteart, gerätespezifische Einstellungen und App-Einstellungen sowie App-Eigenschaften, das Datum und die Uhrzeit des Abrufs, Zeitzone die übertragene Datenmenge und die Meldung, ob der Datenaustausch vollständig war, Absturz der App, Browserart und Betriebssystem. Diese Zugriffsdaten werden verarbeitet, um den technischen Betrieb der App zu ermöglichen.
            </p>
            <p className="text-indented">
                –	Informationen mit Ihrer Einwilligung: Sonstige Informationen, u.a. GPS- Standortdaten, verarbeiten wir, wenn Sie uns dies gestatten.
            </p>
            <p className="text-indented">
                –	Kontaktformulardaten: Bei der Nutzung von Kontaktformularen werden die dadurch übermittelten Daten verarbeitet, u.a. Geschlecht, Name, Anschrift, Firma, E-Mail-Adresse und der Zeitpunkt der Übermittlung.
            </p>
            <h3>5. Cookies</h3>
            <p>
                User "Cookie Policy" finden unter diesem Link: <a href={`${process.env.REACT_APP_CLIENT_URL}/cookie-policy`}>({process.env.REACT_APP_CLIENT_URL}/cookie-policy)</a>.
            </p>
            <h3>6. Dauer der Datenspeicherung</h3>
            <p>
                (1) Wir löschen Ihre personenbezogenen Daten, sobald sie für die Zwecke, für die wir sie erhoben oder verwendet haben nicht mehr erforderlich sind. Wir speichern Ihre personenbezogenen Daten für die Dauer des Nutzungs- bzw. des Vertragsverhältnisses über die App. Eine Speicherung Ihrer Daten erfolgt grundsätzlich nur auf unseren Servern in der Europäischen Union und der USA, vorbehaltlich einer eventuellen Weitergabe.
            </p>
            <p>
                (2) Im Falle eines anhängigen Rechtsstreits mit Ihnen oder eines sonstigen rechtlichen Verfahren, kann die Speicherung über den angegebenen Zeitraum hinaus erfolgen.
            </p>
            <p>
                (3) Von uns eingesetzte Dritte und/oder Auftragsverarbeiter werden Ihre Daten auf deren System so lange speichern, wie es im Zusammenhang mit der Erbringung der Leistung für uns entsprechend dem jeweiligen Auftrag erforderlich ist.
            </p>
            <p>
                (4) Rechtliche Vorgaben zur Aufbewahrung und Löschung personenbezogener Daten bleiben von Vorstehendem unberührt (zB § 257 HGB oder § 147 AO). Sofern die durch die gesetzlichen Vorschriften vorgeschriebene Speicherfrist abläuft, erfolgt eine Sperrung oder Löschung der personenbezogenen Daten, es sei denn, dass eine weitere Speicherung durch uns erforderlich ist und dafür eine Rechtsgrundlage besteht.
            </p>
            <h3>7. Datensicherheit</h3>
            <p>
                Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Natur, des Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit und Auswirkungen) für den Betroffenen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
            </p>
            <h3>8. Automatisierte Entscheidungsfindung </h3>
            <p>
                Es besteht nicht die Absicht, die von Ihnen erhobene personenbezogene Daten für ein Verfahren zur automatisierten Entscheidungsfindung (einschließlich Profiling) zu verwenden.
            </p>
            <h3>9. Zweckänderung</h3>
            <p>
                (1) Verarbeitungen Ihrer personenbezogenen Daten zu anderen als den beschriebenen Zwecken erfolgen nur, sofern dies durch eine Rechtsvorschrift erlaubt ist oder Sie in den geänderten Zweck der Datenverarbeitung eingewilligt haben.
            </p>
            <p>
                (2) Im Falle einer Weiterverarbeitung zu anderen Zwecken als denen, für den die Daten ursprünglich erhoben worden sind, informieren wir Sie vor der Weiterverarbeitung über die Zwecke und stellen Ihnen sämtliche weitere hierfür maßgeblichen Informationen zur Verfügung.
            </p>
            <h3 className="title-left">D. Verantwortlichkeit für Ihre Daten und Kontakte</h3>
            <h3>1. Verantwortlicher und Kontaktdaten</h3>
            <p>(1) Die für die Verarbeitung Ihrer personenbezogenen Daten verantwortliche Stelle im Sinne des Art. 4 Nr. 7 DS-GVO sind wir</p>
            <p>generatebg UG (haftungsbeschränkt)</p>
            <p>Freiligrathstr. 47</p>
            <p>50935 Köln</p>
            <p>Deutschland</p>
            <p>Email: support@generatebg.com</p>
            <p>
                Ansprechpartner zum Thema Datenschutz steht Ihnen unser betrieblicher Datenschutzbeauftragter jederzeit zur Verfügung. Seine Kontaktdaten sind:
            </p>
            <p>
                Hemanth Kumar Balasubramanian
            </p>
            <p>
                support@generatebg.com
            </p>
            <p>
                (2) Wenden Sie sich an diese Kontaktstelle, sofern Sie die Ihnen zustehenden Rechte uns gegenüber geltend machen wollen, oder Fragen bzw. Anmerkungen zur Erhebung und Verarbeitung Ihrer personenbezogenen Daten haben.
            </p>
            <h3>2. Datenerhebung bei der Kontaktaufnahme </h3>
            <p>
                Wenn Sie mit uns Kontakt aufnehmen, dann werden Ihre E-Mail-Adresse, Ihr Name und alle weiteren personenbezogenen Daten, die Sie im Zuge der Kontaktaufnahme angegeben haben, von uns gespeichert, damit wir mit Ihnen zur Beantwortung der Frage Kontakt aufnehmen können. Diese Daten werden gelöscht, sobald die Speicherung nicht mehr erforderlich ist. Liegen gesetzliche Aufbewahrungsfristen vor, bleiben die Daten gespeichert, die Verarbeitung ist aber eingeschränkt.
            </p>
            <h3 className="title-left">E. Datenverarbeitung durch Auftragsverarbeiter und Dritte</h3>
            <h3>1. Auftragsdatenverarbeitung </h3>
            <p>
                (1) Sollten für einzelne Funktionen unserer App auf beauftragte Dienstleister zurückgegriffen werden, werden diese nur nach unserer Weisung tätig. Sie sind nach Art. 28 DS-GVO vertraglich dazu verpflichtet, die datenschutzrechtlichen Bestimmungen einzuhalten.
            </p>
            <p>
                (2) Folgende Kategorien von Empfängern, bei denen es sich im Regelfall um Auftragsverarbeiter handelt, erhalten ggf. Zugriff auf Ihre personenbezogenen Daten:
            </p>
            <p className="text-indented">
                –	Dritte, die als Dienstleister für den Betrieb unserer App und die Verarbeitung der durch die Systeme gespeicherten oder übermittelten Daten zuständig sind. Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f DS-GVO, soweit es sich nicht um Auftragsverarbeiter handelt;
            </p>
            <p className="text-indented">
                –	Auftragsverarbeiter, die als Dienstleister für den Betrieb unserer App und die Verarbeitung der durch die Systeme gespeicherten oder übermittelten Daten zuständig sind. Rechtsgrundlage für die Weitergabe ist dann Art. 28 in Verbindung mit Art. 6 Abs. 1 lit. b oder lit. f DS-GVO, je nach konkretem Zweck der Datenverarbeitung;
            </p>
            <p className="text-indented">
                –	Staatliche Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich ist. Rechtsgrundlage für die Weitergabe ist Art. 6 Abs. 1 S. 1 lit. c DS-GVO;
            </p>
            <p className="text-indented">
                –	Zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen. Rechtsgrundlage für die Weitergabe ist Art. 6 Abs. 1 S. 1 lit. b oder lit. f DS-GVO.
            </p>
            <p>
                (3) Wir geben Ihre personenbezogenen Daten ausschließlich an Dritte weiter, sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO eine ausdrückliche Einwilligung dazu erteilt haben.
            </p>
            <p>
                (4) Soweit personenbezogene Daten von Ihnen durch uns an unsere Tochtergesellschaften weitergegeben, geschieht dies aufgrund von bestehenden Auftragsverarbeitungsverhältnissen.
            </p>
            <p>
                (5) Unten finden Sie die gesamte Liste von Auftragsverarbeiter mit dem Zweck die jeweils erfüllen und Informationen über Ihre Unternehmung und Datenschutzerklärung.
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Hosting der Webseite:
            </p>
            <p className="text-indented">
                Anbieter: Hostinger International Ltd., 61 Lordou Vironos str., 6023 Larnaca, Cyprus.
            </p>
            <p className="text-indented">
                Webseite: https://www.hostinger.com/
            </p>
            <p className="text-indented">
                Datenschutzerklärung: https://www.hostinger.com/legal/privacy-policy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Datenbank Infrastruktur:
            </p>
            <p className="text-indented">
                Anbieter: MongoDB, Inc., 1633 Broadway, 38th Floor New York, NY 10019, United States.
            </p>
            <p className="text-indented">
                Webseite: https://www.mongodb.com/
            </p>
            <p className="text-indented">
                Datenschutzerklärung: https://www.mongodb.com/legal/privacy-policy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Cloud Computing Infrastruktur:
            </p>
            <p className="text-indented">
                Anbieter: Replicate, Inc., 2261 Market Street #4056, San Francisco, California 94114, United States.
            </p>
            <p className="text-indented">
                Webseite: https://replicate.com/
            </p>
            <p className="text-indented">
                Datenschutzerklärung: https://replicate.com/privacy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Datenspeicher Infrastruktur:
            </p>
            <p className="text-indented">
                Anbieter: Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, L-1855, Luxembourg.
            </p>
            <p className="text-indented">
                Webseite: https://aws.amazon.com/
            </p>
            <p className="text-indented">
                Datenschutzerklärung: https://aws.amazon.com/privacy/
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Zahlungssystem Infrastruktur:
            </p>
            <p className="text-indented">
                Anbieter: Stripe Payments Europe, Limited (SPEL), 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, D02 H210, Irland.
            </p>
            <p className="text-indented">
                Webseite: https://stripe.com/
            </p>
            <p className="text-indented">
                Datenschutzerklärung: https://stripe.com/de/privacy
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Infrastruktur für Web-Performance- und Sicherheitsdienste:
            </p>
            <p className="text-indented">
                Anbieter: Cloudflare Germany GmbH, Rosental 7, 80331 München
            </p>
            <p className="text-indented">
                Webseite: https://www.cloudflare.com/
            </p>
            <p className="text-indented">
                Datenschutzerklärung: https://www.cloudflare.com/privacypolicy/
            </p>
            <p>-----------------------------</p>
            <p className="text-indented">
                Analyse und Ereignisbehandlung:
            </p>
            <p className="text-indented">
                Anbieter: Google Tag Manager (Google LLC), Gordon House, Barrow Street, Dublin 4, Irland
            </p>
            <p className="text-indented">
                Webseite: https://tagmanager.google.com/
            </p>
            <p className="text-indented">
                Datenschutzerklärung: https://policies.google.com/privacy
            </p>
            <p>-----------------------------</p>
            <h3>2. Voraussetzungen der Weitergabe von personenbezogenen Daten an Drittländer </h3>
            <p>
                (1) Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften weitergegeben oder offengelegt werden. Diese können sich auch außerhalb des Europäischen Wirtschaftsraums (EWR) befinden. Eine derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der vertraglichen und geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung. Über die jeweiligen Einzelheiten der Weitergabe unterrichten wir Sie nachfolgend an den dafür relevanten Stellen.
            </p>
            <p>
                (2) Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen Datenschutz, der dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der Angemessenheitsbeschlüsse erhalten Sie hier: https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en). In anderen Drittländern, in die ggf. personenbezogene Daten übertragen werden, herrscht aber unter Umständen wegen fehlender gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau. Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz ausreichend gewährleistet ist. Möglich ist dies über bindende Unternehmensvorschriften, Standard-Vertragsklauseln der Europäischen Kommission zum Schutz personenbezogener Daten, Zertifikate oder anerkannte Verhaltenskodizes. Bitte wenden Sie sich an unseren Datenschutzbeauftragten, wenn Sie hierzu nähere Informationen erhalten möchten.
            </p>
            <h3>3. Gesetzliche Verpflichtung zur Übermittlung von Daten</h3>
            <p>
                Im Einzelfall unterliegen wir einer gesetzlichen Verpflichtung, rechtmäßig erhobene personenbezogen Daten für Dritte bereitzustellen, insbesondere den öffentlichen Stellen, gem. Art. 6 Abs. 1 S. 1 lit. c DS-GVO).
            </p>
            <h3 className="title-left">F. Ihre Rechte</h3>
            <h3>1. Auskunftsrecht</h3>
            <p>
                Sie haben das Recht im Rahmen von Art. 15 DS-GVO, Auskunft über die Sie betreffenden personenbezogenen Daten zu erhalten. Hierfür ist ein Antrag von Ihnen erforderlich, der entweder per E-Mail oder postalisch an die oben angegebenen Adressen zu senden ist.
            </p>
            <h3>2. Widerspruch gegen die Datenverarbeitung und Widerruf der Einwilligung</h3>
            <p>
                (1) Sie haben gemäß Art. 21 DS-GVO das Recht, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten Widerspruch einzulegen. Wir werden die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn, wir können zwingende Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
            </p>
            <p>
                (2) Gemäß Art. 7 Abs. 3 DS-GVO haben Sie das Recht Ihre einmal erteilte Einwilligung – also Ihr freiwilliger, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung verständlich gemachter Willen, dass Sie mit der Verarbeitung der betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden sind – jederzeit uns gegenüber zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, nicht mehr fortführen dürfen.
            </p>
            <p>
                (3) Zur Bekanntgabe wenden Sie sich bitte an die oben angegebene Kontaktstelle.
            </p>
            <h3>3. Recht zur Berichtigung und Löschung</h3>
            <p>
                (1) Insoweit Sie betreffende personenbezogene Daten unrichtig sind, haben Sie gemäß Art. 16 DS-GVO das Recht, von uns die unverzügliche Berichtigung zu verlangen. Unter den in Art. 17 DS-GVO genannten Voraussetzungen steht Ihnen zudem das Recht zu, die Löschung Sie betreffender personenbezogener Daten zu verlangen. Das Recht auf Löschung steht Ihnen insbesondere zu, wenn die fraglichen Daten für die Erhebungs- oder Verarbeitungszwecke nicht mehr notwendig sind, wenn der Datenspeicherungszeitraum verstrichen ist, ein Widerspruch vorliegt oder eine unrechtmäßige Verarbeitung vorliegt. Mit einem diesbezüglichen Antrag wenden Sie sich bitte an die oben angegebene Kontaktstelle.
            </p>
            <p>
                (2)  Zur Geltendmachung dieser Rechte wenden Sie sich bitte an die oben angegebene Kontaktstelle.
            </p>
            <h3>4. Recht auf Einschränkung der Verarbeitung</h3>
            <p>
                (1) Nach Art. 18 DS-GVO haben Sie das Recht, von uns die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </p>
            <p>
                (2) Mit einem diesbezüglichen Antrag wenden Sie sich bitte an die oben angegebene Kontaktstelle.
            </p>
            <p>
                (3) Das Recht auf Einschränkung der Verarbeitung steht Ihnen insbesondere zu, wenn die Richtigkeit der personenbezogenen Daten zwischen Ihnen und uns umstritten ist; das Recht steht Ihnen in diesem Fall für eine Zeitspanne zu, die für die Überprüfung der Richtigkeit erfordert wird. Entsprechendes gilt, wenn die erfolgreiche Ausübung eines Widerspruchsrechts zwischen Ihnen und uns noch umstritten ist. Dieses Recht steht Ihnen außerdem insbesondere dann zu, wenn Ihnen ein Recht auf Löschung zusteht und Sie anstelle einer Löschung eine eingeschränkte Verarbeitung verlangen.
            </p>
            <h3>5. Recht auf Datenübertragbarkeit</h3>
            <p>
                (1) Nach Maßgabe des Art. 20 DS-GVO haben Sie das Recht, von uns die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen, maschinenlesbaren Format zu erhalten.
            </p>
            <p>
                (2) Mit einem diesbezüglichen Antrag wenden Sie sich bitte an die oben angegebene Kontaktstelle.
            </p>
            <h3>6. Recht auf Beschwerde bei der Aufsichtsbehörde</h3>
            <p>
                (1) Sie haben gemäß Art. 77 DS-GVO das Recht, sich über die Erhebung und Verarbeitung Ihrer personenbezogenen Daten bei der zuständigen Aufsichtsbehörde zu beschweren.
            </p>
            <p>
                (2)  Die Zuständigkeit richtet sich nach unserem Firmensitz, Ihrem üblichen Aufenthaltsort oder Ihrem Arbeitsplatz.
            </p>

        </div>
    );
};

export default PrivacyDe;