import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FetchFreeMattedImages from './FetchFreeMattedImages';
import '../styles/CommonComponentStyles.scss';

const FreeMattedImages = () => {
    const navigate = useNavigate();
    const [imageIds, setImageIds] = useState([]);
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [page, setPage] = useState(1);
    const shouldImageCountBeUpdatedRef = useRef(true);
    const [imageCache, setImageCache] = useState({});

    useEffect(() => {
        const fetchImages = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/free-matted-images/${page}`, { withCredentials: true });
                setImageIds(prevIds => {
                    // Create a Set from the existing IDs for efficient lookup
                    const existingIdsSet = new Set(prevIds);
                    // Filter out any IDs that are already in the existingIdsSet
                    const newIds = response.data.imageIds.filter(id => !existingIdsSet.has(id));
                    // Set the LoadingImagesCount only if unique newIds are returned
                    if (shouldImageCountBeUpdatedRef.current) {
                        setLoadedImagesCount(prevCount => prevCount + response.data.imageIds.length);
                        shouldImageCountBeUpdatedRef.current = false;
                    }
                    // Return the new array with duplicates filtered out
                    return [...prevIds, ...newIds];
                });
                setTotalImagesCount(response.data.totalImagesCount);
            } catch (error) {
                console.error('Error fetching generated images:', error);
            }
        };

        fetchImages();
    }, [page]);

    // Routes one level back to /account
    const handleBackRequest = () => {
        navigate("/account");
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
        shouldImageCountBeUpdatedRef.current = true;
    }

    return (
        <>
            <div className="common-styles">
                <p className="text-center">
                    <button className="generic-account-button" onClick={handleBackRequest}>Go Back</button>
                </p>
                <h1>Your Background-removed Images</h1>
                {(
                    /* Pass the imageIds to the FetchGeneratedImages component */
                    imageIds.length > 0 &&
                    <FetchFreeMattedImages
                        imageCache={imageCache}
                        setImageCache={setImageCache}
                        imageIDs={imageIds}
                    />
                )}
                {(loadedImagesCount < totalImagesCount) && (
                    <button className="generic-account-button--center" onClick={handleLoadMore}>
                        Load More
                    </button>
                )}
            </div>
        </>
    );
};

export default FreeMattedImages;