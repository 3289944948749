import React from 'react';
import '../../styles/LegalStyles.scss';
import OfficialGermanNotice from './OfficialGermanNotice.js';

const ImprintEn = () => {
    return (
        <div className="legal-doc">
            <OfficialGermanNotice />
            <h1 className="title-left">Imprint as per § 5 TMG (Telemediengesetz - German Telemedia Act)</h1>
            <h3 className="title-left">1. Information according to § 5 TMG</h3>
            <h3>Provider:  </h3>
            <p>generatebg UG (haftungsbeschränkt)</p>
            <p>Freiligrathstr. 47</p>
            <p>50935 Cologne</p>
            <p>Germany</p>
            <p>Email: support@generatebg.com</p>

            <h3>Register Entry:</h3>
            <p>
                generatebg UG (haftungsbeschränkt) is a limited liability company in accordance with the GmbH law, based in Cologne, Germany, registered in the Commercial Register of the Cologne District Court under the number HRB 116617.
            </p>

            <h3>Represented by:</h3>
            <p>Managing Director: Hemanth Kumar Balasubramanian</p>

            <h3>VAT-ID according to § 27a UStG (Umsatzsteuergesetz - The German Value Added Tax Act):  </h3>
            <p>DE364438753</p>

            <h3 className="title-left">2. Additional Information</h3>
            <h3>Information according to § 2 Dienstleistungs-Informationsverordnung [Service Information Regulation]:</h3>
            <p>
                For services provided by us, our terms and conditions apply. You can find them at <a href={`${process.env.REACT_APP_CLIENT_URL}/general-terms-conditions`}>({process.env.REACT_APP_CLIENT_URL}/general-terms-conditions)</a>. These terms stipulate that German law applies and, where permissible, Cologne, Germany, is agreed upon as the jurisdiction.
            </p>

            <h3>Alternative Dispute Resolution according to Art. 14 para. 1 ODR-VO and § 36 VSBG (Verbraucherstreitbeilegungsgesetz - The German Consumer Dispute Resolution Act): </h3>
            <p>
                The EU platform for out-of-court online dispute resolution can be accessed at the following link: http://ec.europa.eu/consumers/odr/
                We are not legally obliged to participate in a dispute resolution procedure before a consumer arbitration board and generally not prepared to do so.
            </p>

            <h3 className="title-left">Liability</h3>
            <p>
                We are responsible for the contents of our websites in accordance with general laws, particularly under § 7 TMG. All content is created carefully and to the best of our knowledge.
            </p>
            <p>
                The information on this website (especially in text contributions, videos, forum posts, news articles, etc.), on linked or hosted videos, or on linked external sources is provided for general informational purposes only and does not constitute advice, a declaration of intent, or any other binding statement, unless it concerns descriptions of services or products. For content, contributions, forum posts, comments by users, or any other content, we do not guarantee accuracy, completeness, and timeliness, unless it concerns descriptions of services or products.
            </p>
            <p>
                Insofar as we refer to third-party websites through links on our websites, we cannot guarantee the timeliness, accuracy, and completeness of the linked content. These are outside our area of responsibility. We have no influence on their future design.
            </p>
            <p>
                In particular, responding to user questions, whether on our website/app or on any other social media portal, does not constitute advice, a declaration of intent, or any other binding statement.
            </p>
            <p>
                If, in your view, contents violate applicable law or are inappropriate, please inform us.
            </p>
            <p>
                The legal notices on this page and all questions and disputes related to the design of this website are subject to the laws of the Federal Republic of Germany.
            </p>
            <p>
                If you intend to send us a legal warning or notice, you are requested to contact us beforehand to avoid unnecessary legal disputes and costs. The cost note of a legal warning without prior contact with the operator will be rejected as unfounded in the sense of the duty to mitigate damage. The costs incurred by us will be invoiced.
            </p>

            <h3 className="title-left">4. Data Protection</h3>
            <p>Our data protection policy can be found here <a href={`${process.env.REACT_APP_CLIENT_URL}/privacy-policy`}>({process.env.REACT_APP_CLIENT_URL}/privacy-policy)</a>.</p>

            <h3 className="title-left">5. Copyright Notice</h3>
            <p>
                The texts, images, photos, videos, graphics, or other copyrighted content on our website are generally protected by copyright law. Therefore, any unauthorized use of these contents is prohibited, especially their reproduction, editing, or distribution. If you wish to use content or parts of it, please contact us in advance.
            </p>

            <h3 className="title-left">6. Social Media Profiles</h3>
            <p>This imprint also applies to the following social media profiles:</p>
            <p className="text-indented">–	Twitter: ...To be updated soon...</p>
        </div >
    );
};

export default ImprintEn;