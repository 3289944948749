import React from 'react';
import '../../styles/LegalStyles.scss';

const ImprintDe = () => {
    return (
        <div className="legal-doc">
            <h1 className="title-left">Impressum nach § 5 TMG</h1>
            <h3 className="title-left">1. Angaben nach § 5 TMG</h3>
            <h3>Anbieter:  </h3>
            <p>generatebg UG (haftungsbeschränkt)</p>
            <p>Freiligrathstr. 47</p>
            <p>50935 Köln</p>
            <p>Deutschland</p>
            <p>Email: support@generatebg.com</p>

            <h3>Registereintrag:</h3>
            <p>
                Die generatebg UG (haftungsbeschränkt) ist eine Gesellschaft mit beschränkter Haftung nach Maßgabe des GmbH-Gesetzes mit Sitz in Köln, Deutschland, eingetragen im Handelsregister des Amtsgerichts Köln unter der Nummer HRB 116617
            </p>

            <h3>Vertreten durch:</h3>
            <p>Geschäftsführer: Hemanth Kumar Balasubramanian</p>

            <h3>Umsatzsteuer-ID nach § 27a UStG:  </h3>
            <p>DE364438753</p>

            <h3 className="title-left">2. Weitere Angaben</h3>
            <h3>Information gemäß § 2 Dienstleistungs-Informationsverordnung:</h3>
            <p>
                Für von uns erbrachte Leistungen gelten unsere AGB. Sie finden sie unter <a href={`${process.env.REACT_APP_CLIENT_URL}/general-terms-conditions`}>({process.env.REACT_APP_CLIENT_URL}/general-terms-conditions)</a>. Darin ist geregelt, dass deutsches Recht Anwendung findet und dass, soweit zulässig, als Gerichtsstand Köln vereinbart wird.
            </p>

            <h3>Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG: </h3>
            <p>
                Die OS-Plattform der EU zur außergerichtlichen Online-Streitbeilegung ist unter folgendem Link erreichbar: http://ec.europa.eu/consumers/odr/
                Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir rechtlich nicht verpflichtet und im Allgemeinen nicht bereit.
            </p>

            <h3 className="title-left">Haftung</h3>
            <p>
                Wir sind für die Inhalte unserer Webseiten nach den allgemeinen Gesetzen verantwortlich, insbesondere nach § 7 TMG. Alle Inhalte werden sorgfältig und nach bestem Wissen erstellt.
            </p>
            <p>
                Die Angaben auf dieser Website (insbesondere in den Textbeiträgen, Videos, Forumsbeiträgen, Newsbeiträgen etc.), auf den verlinkten oder gehosteten Videos oder auf verlinkten externen Quellen erfolgen nur zu allgemeinen Informationszwecken und stellen keine Beratung, Willenserklärung oder sonst wie verbindliche Äußerung dar, wenn es sich nicht um Dienstleistungs- oder Produktbeschreibungen handelt. Für Content, Beiträge, Forumsbeiträge, Kommentare von Usern oder jeglichen Content übernehmen wir keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität, soweit es sich nicht um Dienstleistungs- oder Produktbeschreibungen handelt.
            </p>
            <p>
                Soweit wir auf unseren Internetseiten durch Links auf Internetseiten Dritter verweisen, können wir keine Gewähr für Aktualität, Richtigkeit und Vollständigkeit der verlinkten Inhalte übernehmen. Diese befinden sich außerhalb unseres Verantwortungsbereichs. Wir haben auf die zukünftige Gestaltung keinen Einfluss.
            </p>
            <p>
                Insbesondere stellt die Beantwortung von Userfragen, weder auf unserer Website/App noch in einem anderen Social Media Portal, keine Beratung, Willenserklärung oder sonst wie verbindliche Äußerung dar.
            </p>
            <p>
                Sollten aus Ihrer Sicht Inhalte gegen geltendes Recht verstoßen oder unangemessen sein, teilen Sie uns dies bitte mit.
            </p>
            <p>
                Die rechtlichen Hinweise auf dieser Seite sowie alle Fragen und Streitigkeiten im Zusammenhang mit der Gestaltung dieser Internetseite unterliegen dem Recht der Bundesrepublik Deutschland.
            </p>
            <p>
                Sollten Sie beabsichtigen, uns eine Abmahnung zukommen zu lassen, werden Sie zur Vermeidung unnötiger Rechtsstreitigkeiten und -kosten gebeten, uns zunächst im Vorfeld zu kontaktieren. Die Kostennote einer anwaltlichen Abmahnung ohne vorhergehende Kontaktaufnahme mit dem Betreiber wird im Sinne der Schadensminderungspflicht als unbegründet zurückgewiesen. Die uns damit entstehenden Kosten werden in Rechnung gestellt.
            </p>

            <h3 className="title-left">4. Datenschutz</h3>
            <p>Unsere Datenschutzerklärung finden Sie hier <a href={`${process.env.REACT_APP_CLIENT_URL}/privacy-policy`}>({process.env.REACT_APP_CLIENT_URL}/privacy-policy)</a>.</p>

            <h3 className="title-left">5. Urheberrechtshinweis</h3>
            <p>
                Die Texte, Bilder, Fotos, Videos, Grafiken oder sonstige urheberrechtlich geschützte Inhalte auf unserer Webseite unterliegen grundsätzlich dem Urheberrechtsschutz. Jede unberechtigte Verwendung dieser Inhalte ist deshalb untersagt, insbesondere ihre Vervielfältigung, Bearbeitung oder Verbreitung. Wenn Sie Inhalte oder Teile davon verwenden wollen, kontaktieren Sie uns bitte im Voraus.
            </p>

            <h3 className="title-left">6. Social Media-Profile</h3>
            <p>Dieses Impressum gilt auch für folgende Social Media-Profile:</p>
            <p className="text-indented">–	Twitter: ...wird demnächst aktualisiert...</p>
        </div >
    );
};

export default ImprintDe;