import React from 'react';
import '../../styles/LegalStyles.scss';
import OfficialGermanNotice from './OfficialGermanNotice.js';

const CookiesEn = () => {
    return (
        <div className="legal-doc">
            <OfficialGermanNotice />
            <h1 className="title-left">Cookie Policy</h1>
            <p>
                (1) We use cookies in the operation of our app. Cookies are small text files that are stored on the storage of your mobile device or computer and assigned to the mobile or web app you use, through which the entity that sets the cookie receives certain information. Cookies cannot execute programs or transfer viruses to your computer. They are used to make an app more user-friendly, secure, and effective.
            </p>
            <p>
                (2) Cookies contain data that enable the recognition of the used device. Sometimes cookies only contain information about settings that are not personally identifiable. Cookies cannot directly identify a user.
            </p>
            <p>
                (3) A distinction is made between session cookies, which are deleted as soon as you close your browser, and permanent cookies, which are stored beyond an individual session. Regarding their function, cookies can be further differentiated:
            </p>
            <p className="text-indented">
                –	Technical Cookies: These are essential for navigating within the app, using basic functions, and ensuring the app's security; they neither collect information about you for marketing purposes nor store which websites you have visited;
            </p>
            <p className="text-indented">
                –	Performance Cookies: These collect information about how you use our app, which pages you visit, and whether there are errors in the app usage; they do not collect information that could identify you – all collected information is anonymous and is only used to improve our app and find out what interests our users;
            </p>
            <p className="text-indented">
                –	Advertising Cookies, Targeting Cookies: These are used to provide the app user with tailored advertising within the app or offers from third parties and to measure the effectiveness of these offers; advertising and targeting cookies are stored for a maximum of 13 months;
            </p>
            <p className="text-indented">
                –	Sharing Cookies: These are used to enhance the interactivity of our app with other services (e.g., social networks); sharing cookies are stored for a maximum of 13 months.
            </p>
            <p>
                (4) Any use of cookies that is not strictly technically necessary constitutes data processing that is only allowed with your explicit consent, according to Art. 6 para. 1 sentence 1 lit. a GDPR. This particularly applies to the use of advertising, targeting, or sharing cookies. Furthermore, we only pass on your personal data processed by cookies to third parties if you have given explicit consent to do so, according to Art. 6 para. 1 sentence 1 lit. a GDPR.
            </p>
            <p>
                (5) Currently, in our web app, we only use essential technical cookies. In addition, our payment service provider uses their own cookies to integrate into our app to provide payment services for you. More information about their cookie policy can be found here: https://stripe.com/de/legal/cookies-policy. Similarly, our web performance and security services provider uses their own cookies to integrate into our app to provide security and performance services for you. More information about their cookie policy can be found here: https://www.cloudflare.com/privacypolicy/
            </p>
            <p>
                (6) You can find below, the list of cookies we use on our website and their respective purposes:
            </p>
            <p>-----------------------------</p>
            <p>Cookie Name: theme</p>
            <p>Purpose: Used to save the user's choice between light and dark theme for the website / web app across sessions.</p>
            <p>Retention period: indefinitely until the user clears the cookie or deletes it.</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: cookieAccepted</p>
            <p>Purpose: Used to save the state of cookie information popup. If the user has clicked on the 'Okay' button once, this cookie ensures the cookie information popup is not shown again.</p>
            <p>Retention period: 1 week.</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: accessToken</p>
            <p>Purpose: An encrypted cookie used to authenticate a logged in user (short term).</p>
            <p>Retention period: 1 hour.</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: refreshToken</p>
            <p>Purpose: An encrypted cookie used to authenticate a logged in user (long term).</p>
            <p>Retention period: 1 week.</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>
                (7) You can find below, the list of cookies our payment services provider (stripe) uses on our website and their respective purposes:
            </p>
            <p>-----------------------------</p>
            <p>Cookie Name: __stripe_mid</p>
            <p>Purpose: Long term cookie used for fraud prevention.</p>
            <p>Retention period: 1 Year.</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: __stripe_mid</p>
            <p>Purpose: Short term cookie used for fraud prevention.</p>
            <p>Retention period: 30 minutes.</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: __stripe_orig_props</p>
            <p>Purpose: Used to analyze how users interact with stripe's services.</p>
            <p>Retention period: 1 Year.</p>
            <p>Cookie Type: Performance (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: stripe.csrf</p>
            <p>Purpose: A security cookie used to protect user data from unauthorized access.</p>
            <p>Retention period: 90 days.</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: site-auth</p>
            <p>Purpose: Used to ensure proper functionality of stripe's services.</p>
            <p>Retention period: session</p>
            <p>Cookie Type: Session (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: machine_identifier</p>
            <p>Purpose: Long term cookie used for fraud prevention.</p>
            <p>Retention period: 1 Year</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: _ga, _gat, _gid, _gcl</p>
            <p>Purpose: Cookie from Google to control advanced script and event handling.</p>
            <p>Retention period: 2 Years</p>
            <p>Cookie-Typ: Analytics/event-handling (optional)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: private_machine_identifier</p>
            <p>Purpose: Long term cookie used for fraud prevention.</p>
            <p>Retention period: 1 Year</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: m</p>
            <p>Purpose: Long term cookie used for fraud prevention.</p>
            <p>Retention period: 2 Years</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: cf_clearance</p>
            <p>Purpose: Security cookie used to issue challenges that authenticate human users.</p>
            <p>Retention period: indefinite</p>
            <p>Cookie Type: Technical (essential)</p>
            <p>-----------------------------</p>
        </div>
    );
};

export default CookiesEn;