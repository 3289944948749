import React from 'react';
import { Link } from 'react-router-dom';
import './styles/CommonComponentStyles.scss';

const LoginRequired = () => {
    return (
        <div className="common-styles">
            <h1>Login Required</h1>
            <p className="text-center">You must be logged in to access this service.</p>
            <p className="text-center">Please <Link to="/login">log in</Link> and try again.</p>
        </div>
    );
};

export default LoginRequired;
