import React, { useState, useRef } from 'react';
import axios from 'axios';
import FetchFreeTransImage from '../FetchFreeTransImage';
import ImageUploadGuide from '../ImageUploadGuide';
import 'react-image-crop/dist/ReactCrop.css';
import '../../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';

const RemoveBackgroundAuthorized = () => {
    const [originalImageURL, setOriginalImageURL] = useState(null);
    const [serviceMessage, setServiceMessage] = useState(null);
    const [originalImageName, setOriginalImageName] = useState(null);
    const [freeTransImageID, setFreeTransImageID] = useState('');

    // State for the completed Matting
    const [isMatting, setIsMatting] = useState(false);
    const [isMattingError, setIsMattingError] = useState(false);
    const [completedMatting, setCompletedMatting] = useState(false);

    // Ref for image loaded
    const imgRef = useRef(null);


    // Function to create an image blob from an image URL
    const imageURLToBlob = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    };

    // Function that removes background of an image by calling a backend service
    const removebackground = async (e) => {
        e.preventDefault();

        // Display loading message and SVG
        setIsMatting(true);
        try {
            const originalImageBlob = await imageURLToBlob(originalImageURL);
            // Remove special characters from the original file name
            const cleanedFileName = originalImageName.replace(/[^\w\s.-_]/gi, '');
            const formData = new FormData();
            formData.append('image', originalImageBlob, cleanedFileName);

            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/images/removeBackground`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            setIsMatting(false);
            setCompletedMatting(true);
            setServiceMessage(`🚀 ${res.data.message}`);
            setFreeTransImageID(res.data.freeTransImageID);


        } catch (err) {
            setIsMatting(false);
            setIsMattingError(true);
            // Handle Axios error messages
            if (err.response && err.response.status === 429) {
                setServiceMessage("❗ You've made too many requests. Please wait a while and try again.");
            } else if (err.response.status === 401) {
                setServiceMessage('❗ Images must be smaller than 5MB in size (jpg, jpeg or png only).');
            } else if (err.response.status === 503) {
                setServiceMessage('❗ Apologies. We are currently unable to fulfill your request.')
            }

            else if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error(err.response.data);
                setServiceMessage(err.response.data);
            } else if (err.request) {
                // The request was made but no response was received
                console.error("No response received from server.");
                setServiceMessage("❗ No response received from server.");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Request error:", err.message);
                setServiceMessage("❗ An error occurred during the request.");
            }
        }
    };

    // Debug code; remove in prod
    // useEffect(() => {
    //     let isMounted = true;

    //     console.log('image:', image);
    //     console.log('imgRef.current:', imgRef.current);
    //     console.log('resizedImageURL:', originalImageURL);
    //     console.log('completedMatting: ', completedMatting)
    //     console.log('Image Mime Type: ', imageMimeType);


    //     return () => {
    //         isMounted = false;
    //     }
    // }, [image]);

    const handleFileChange = async e => {
        // Check if a file is selected
        if (!e.target.files[0]) {
            console.log("File selection was canceled");
            return; // Return early if no file is selected
        }

        // Validates the file format and errors out if the format is anything other than jpeg or png
        if (!(e.target.files[0].type === 'image/png') && !(e.target.files[0].type === 'image/jpeg')) {
            setServiceMessage("❗ File Formats Allowed - JPG, JPEG, & PNG only");
            setIsMattingError(true);
        }

        const imageURL = URL.createObjectURL(e.target.files[0]);
        setOriginalImageURL(imageURL);
        setOriginalImageName(e.target.files[0].name); // save original name
    };

    const [showGuide, setShowGuide] = useState(true);

    const toggleGuide = () => {
        setShowGuide(!showGuide);
    };

    return (
        <>
            <div className="common-styles">
                <Helmet>
                    <title>generatebg - Remove Background</title>
                    <meta name="description" content="Remove background from your input image here." />
                    <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/remove-background`} />
                </Helmet>
                <h1>Remove Background</h1>
                <form className="user-form processing-card">
                    <label className="user-label file-loader-label">
                        Select a file to proceed
                        <input className="user-input file-loader" type="file" name="image" onChange={handleFileChange} />
                    </label>
                    <p className="small-text file-loader-text">
                        File Formats Allowed - JPG, JPEG, & PNG only ---&gt;
                        <span className="info-button" onClick={toggleGuide}>Background Removal - Guide </span>
                    </p>

                    {showGuide && <ImageUploadGuide onClose={toggleGuide} />}

                    <br />

                    <div className="original-image-container-outer">
                        {originalImageURL &&
                            <>
                                <div className="original-img-container-inner">
                                    <img className="original-img" src={originalImageURL} onLoad={e => imgRef.current = e.currentTarget} alt="Your chosen file is loaded" />
                                </div>
                            </>
                        }
                    </div>

                    {(serviceMessage) ? <p className="small-text user-message">{serviceMessage}</p> : ''}
                    {isMatting ?
                        (
                            <p className="small-text user-message">Matting in progress...</p>
                        )
                        : ''}
                    {isMatting ?
                        <p className="rocket--big text-center">🚀</p>
                        // <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="matt-loading">
                        //     <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                        //         <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                        //         <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                        //     </g>
                        // </svg>
                        : ''}
                    {completedMatting ? <p className="small-text user-message">🚀 Preprocessing complete. Here is your image (hover over it / click on it to download it):</p> : ''}
                    {freeTransImageID ?
                        (
                            <>
                                <FetchFreeTransImage
                                    freeTransImageID={freeTransImageID}
                                />
                            </>
                        )
                        : ''}
                    {(originalImageURL && !isMatting && !completedMatting && !isMattingError) ?
                        <button className="user-button matt-button" onClick={removebackground} disabled={isMatting || completedMatting}>Remove Background</button>
                        : ''
                    }
                </form>

                <br />
            </div>
        </>
    );
};

export default RemoveBackgroundAuthorized;