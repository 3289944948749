import React, { useState } from 'react';
import './styles/FAQStyles.scss';

const FAQSection = () => {
    const faqs = [
        {
            question: "What file formats does Generatebg support?",
            answer: "Generatebg does not use any proprietary file formats. You can generate backgrounds easily using jpg and png files."
        },
        {
            question: "How is my privacy protected?",
            answer: "Founded in Germany 🇩🇪, we take your privacy very seriously. After you generate images, all of your files are deleted within 1 - 2 days. We do not store any personal information."
        },
        {
            question: "How long does it take for the AI to generate backgrounds?",
            answer: "Usually, it takes somewhere from a few seconds upto 5 minutes to generate backgrounds."
        },
        {
            question: "Can AI background generation lead to bad results?",
            answer: "Yes, AI background generation is not perfect. Our models do not think like human beings. Sometimes, you will notice that the proportions of the background elements are not correct or there are other unusual artefacts. For this reason, we provide 16 previews with each credit (high-res image) you purchase from us. Please feel free to check our pricing page to learn more about this."
        },
        {
            question: "Does the AI background generator work only with product photography?",
            answer: "While product photography is one of the main areas we are focusing on, Generatebg's AI background generator can be used for a variety of applications. You can try our free background removal tool to see if our offering fits your needs."
        },
        // Add more FAQs as needed
    ];

    return (
        <div className="faq-section">
            {faqs.map((faq, index) => (
                <FAQItem key={index} faq={faq} />
            ))}
        </div>
    );
};

const FAQItem = ({ faq }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={toggleOpen}>
                {faq.question}
                {/* <span className="faq-icon">{isOpen ? '✕' : '+'}</span> */}
                {/* <span className="faq-icon">{isOpen ? '✕' : '➕'}</span> */}
                <span className="faq-icon">{isOpen ? '✕' : '+'}</span>
            </div>
            {isOpen && <div className="faq-answer">{faq.answer}</div>}
        </div>
    );
};

export default FAQSection;
