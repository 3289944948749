import React, { useEffect } from 'react';
import '../styles/ImageGuideStyles.scss';
import imageGuideGood1 from '../../assets/imageGuide_good_1.webp';
import imageGuideBad1 from '../../assets/imageGuide_bad_1.webp';

const ImageUploadGuide = ({ onClose }) => {

    // Close popup when clicking outside the content area
    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('popup-overlay')) {
            onClose();
        }
    };

    useEffect(() => {
        // Add event listener
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            // Clean up the event listener
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>X</button>
                <h2>Image Upload Guide</h2>
                <p>Good for Uploading:</p>
                <ul>
                    <li>High-resolution JPG, JPEG, or PNG images</li>
                    <li>Images without heavy background clutter</li>
                    <li>One simple subject with a distinguishable background</li>
                    <li>Considerable background space surrounding the subject</li>
                    {/* Add more suitable examples */}
                </ul>
                <img src={imageGuideGood1} alt="Examples for good images" className="guide-image" />
                <p>Avoid Uploading:</p>
                <ul>
                    <li>Blurry or low-resolution images</li>
                    <li>Images with sensitive content</li>
                    <li>Little to no background space surrounding the subject</li>
                    <li>Cluttered background / complex subject or multiple subjects</li>
                    {/* Add more unsuitable examples */}
                </ul>
                <img src={imageGuideBad1} alt="Examples for bad images" className="guide-image" />
                <button onClick={onClose} className="generic-button--center">Close</button>
            </div>
        </div>
    );
};

export default ImageUploadGuide;
