import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/FreeTools.scss'; // Assuming you'll create and import a specific stylesheet for this component

const FreeTools = () => {
    const navigate = useNavigate();

    const tools = [
        {
            name: 'Remove Background',
            description: 'Easily remove backgrounds from your images.',
            path: '/free-tools/remove-background',
            icon: '🖼️', // Placeholder icon, you may want to replace it with an actual image or SVG
        },
        {
            name: 'Generate Vector Graphics',
            description: 'Transform pixel-based images to infinitely scalable vectorised images (SVGs).',
            path: '/free-tools/raster-to-vector',
            icon: '✨', // Placeholder icon, you may want to replace it with an actual image or SVG
            // // icon: '🎉', // Placeholder icon, you may want to replace it with an actual image or SVG
            // // icon: '🌐 To ✨', // Placeholder icon, you may want to replace it with an actual image or SVG
            // icon: '🏁 To ✨', // Placeholder icon, you may want to replace it with an actual image or SVG
        }
    ];

    const navigateToTool = (path) => {
        navigate(path);
    };

    return (
        <div className="tools-container">
            {tools.map((tool, index) => (
                <div key={index} className="tool-card" onClick={() => navigateToTool(tool.path)}>
                    <div className="tool-icon">{tool.icon}</div>
                    <div className="tool-info">
                        <h3>{tool.name}</h3>
                        <p>{tool.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FreeTools;
