import React from 'react';
import '../styles/CommonComponentStyles.scss';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PaymentCanceled = () => {
    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Payment Cancelled</title>
                <meta name="description" content="It looks like you cancelled the generatebg payment process. You may choose to go back to the pricing page here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/payment/cancel`} />
            </Helmet>
            <h1 className="payment-title">Payment Canceled</h1>
            <p className="payment-message">It seems you've canceled the payment. If there was an issue, please contact our support.</p>
            <br></br>
            <Link to="/pricing" className="payment-link">Go back to Pricing</Link>
        </div>
    );
}

export default PaymentCanceled;