import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/ImageCountStatus.scss';

const ImageCountStatus = () => {
    const [status, setStatus] = useState({
        remainingImages: 0,
        imageLimit: 0,
        packageType: ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImageCountStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/image-count-status`, {
                    withCredentials: true,
                });
                // console.log('response.data', response.data);
                setStatus(response.data);
            } catch (error) {
                console.error("Error fetching image count status:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchImageCountStatus();
    }, []);

    const renderPreviewMessage = () => {
        if (status.packageType === 'subscription') {
            return `${status.remainingPreviews} out of ${status.previewLimit} previews (512 x 512 px) remaining this month.`
        } else if (status.packageType === 'imageToGo') {
            return `${status.remainingPreviews} out of ${status.previewLimit} previews (512 x 512 px) left in your image-to-go package.`
        } else {
            return "Unable to determine your preview usage status. We'll try again later.";
        }
    };

    const renderImageMessage = () => {
        if (status.packageType === 'subscription') {
            return `${status.remainingImages} out of ${status.imageLimit} image credits (2048 x 2048 px) remaining this month.`;
        } else if (status.packageType === 'imageToGo') {
            return `${status.remainingImages} out of ${status.imageLimit} image credits (2048 x 2048 px) left in your image-to-go package.`;
        } else {
            return "Unable to determine your image usage status. We'll try again later.";
        }
    };

    return (
        <div className="image-count-status">
            {loading ? (
                <p className="image-status-message">Loading...</p>
            ) : (
                <>
                    <p className="image-status-message">{renderPreviewMessage()}</p>
                    <p className="image-status-message">{renderImageMessage()}</p>
                </>
            )}
        </div>
    );
};

export default ImageCountStatus;