import React, { useState } from 'react';
import axios from 'axios';
import '../styles/CommonComponentStyles.scss';
import LoadingSpinner from '../LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
    const navigate = useNavigate();
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [botCheckAnswer, setBotCheckAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userMessage, setUserMessage] = useState('');

    // Routes one level back to /account/help
    const handleBackRequest = () => {
        navigate("/account/help");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Simple bot-check (you can replace it with your logic)
        if (botCheckAnswer !== '9') {
            setUserMessage('Bot check failed. Please try again.');
            setIsLoading(false);
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/contact`,
                { subject, message },
                { withCredentials: true }
            );
            setUserMessage('Message sent successfully. You can expect an answer from us shortly!');
            setIsLoading(false);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 429) {
                    // Handle rate limit error
                    setUserMessage('❗ You have made too many contact requests, please try again later.');
                } else {
                    // Handle other types of errors
                    setUserMessage('❗ Error sending message. Please try again later.');
                }
                setIsLoading(false);
            } else {
                // Handle errors not related to the response (e.g., network issues)
                setUserMessage('❗ Error sending message. Please check your network and try again.');
                setIsLoading(false);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Contact</title>
                <meta name="description" content="Need help with something? Contact generatebg support here. We are here for you." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/account/help/contact`} />
            </Helmet>
            <p className="text-center">
                <button className="generic-account-button" onClick={handleBackRequest}>Go Back</button>
            </p>
            <br></br>
            <h1>Please Tell Us What You Are Looking For!</h1>
            <br></br>
            <form onSubmit={handleSubmit} className="user-form form-card">
                <label className="user-label">
                    Subject:
                    <input className="user-input" type="text" value={subject} onChange={e => setSubject(e.target.value)} required />
                </label>
                <label className="user-label">
                    Message:
                    <textarea className="user-input" value={message} onChange={e => setMessage(e.target.value)} required />
                </label>
                <label className="user-label">
                    What is 2 + 7? (Bot-check)
                    <input className="user-input" type="text" value={botCheckAnswer} onChange={e => setBotCheckAnswer(e.target.value)} required />
                </label>
                <button type="submit" className="user-button">Send Message</button>
            </form>
            {isLoading ? (<LoadingSpinner />
            ) : (
                userMessage && <p className="user-message">{userMessage}</p>
            )}
        </div>
    );
};

export default Contact;