import React, { useState } from 'react';
import '../../styles/FAQStyles.scss';

const FAQSection = () => {
    const faqs = [
        {
            question: "How does background removal work?",
            answer: "It's very simple. You upload a picture, our background-removal AI goes to work, and you get a background-removed image in an instant."
        },
        {
            question: "Is background removal really free?",
            answer: "Yes, there are no strings attached here. We require you to register an account with us for load-balancing and security purposes. Since we provide this service for free, we humbly request you to use our background removal service responsibly. Should users attempt to misuse the service, we have automated alogrithms that step in and restrict usage."
        },
        {
            question: "What file formats are supported?",
            answer: "You may upload JPG, JPEG, or PNG images. Once our AI has done its job, you will receive a PNG file to download. This file will have a transparent background that you can work with."
        },
        {
            question: "I wish to replace the existing background in my image with a new one. How do I do this?",
            answer: "Check out our paid feature to generate background for images. We are working on a simpler (free) background replacer tool that switches existing backgrounds with simple ones."
        },
        {
            question: "How is my privacy protected?",
            answer: "Founded in Germany 🇩🇪, we take your privacy very seriously. After you process images, all of your files are deleted within 1 - 2 days. We do not store any personal information."
        },
        {
            question: "I like the free tool, but I am looking for more features. Can you develop them?",
            answer: "We'd love to hear from you. Just go to Account > Help and send us a message describing what your requirement is, and we will get in touch with you shortly."
        },
        // Add more FAQs as needed
    ];

    return (
        <div className="faq-section">
            {faqs.map((faq, index) => (
                <FAQItem key={index} faq={faq} />
            ))}
        </div>
    );
};

const FAQItem = ({ faq }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={toggleOpen}>
                {faq.question}
                {/* <span className="faq-icon">{isOpen ? '✕' : '+'}</span> */}
                {/* <span className="faq-icon">{isOpen ? '✕' : '➕'}</span> */}
                <span className="faq-icon">{isOpen ? '✕' : '+'}</span>
            </div>
            {isOpen && <div className="faq-answer">{faq.answer}</div>}
        </div>
    );
};

export default FAQSection;
