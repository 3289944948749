import React from 'react';
import '../styles/CommonComponentStyles.scss';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Invoice = () => {
    const navigate = useNavigate();

    // Routes one level back to /account
    const handleBackRequest = () => {
        navigate("/account");
    };

    // Routes to /account/help/docs
    const handleDocsRequest = () => {
        navigate("/docs");
    };

    // Routes to /account/help/contact
    const handleContactRequest = () => {
        navigate("/account/help/contact");
    };

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Help</title>
                <meta name="description" content="Need help with something? Read the generatebg docs or contact our support here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/help`} />
            </Helmet>
            <p className="text-center">
                <button className="generic-account-button" onClick={handleBackRequest}>Go Back</button>
            </p>
            <br></br>
            <h1>Looking For Help? Look Into Our Docs!</h1>
            <br></br>
            <p className="text-center">
                <button className="generic-account-button" onClick={handleDocsRequest}>Docs</button>
            </p>
            <p className="text-center">
                <button className="generic-account-button" onClick={handleContactRequest}>Contact</button>
            </p>
        </div>
    );
};

export default Invoice;
