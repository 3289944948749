import React, { useState } from 'react';
import '../../styles/FAQStyles.scss';

const FAQSection = () => {
    const faqs = [
        {
            question: "What is raster graphics?",
            answer: "Raster graphics refers to pixel-based digital images. Typical file formats include JPG, PNG, etc. These image formats store color values (red, green, and blue) in each pixel, and when zoomed in, lose quality based on the total pixel count (known as resolution)."
        },
        {
            question: "What is vector graphics?",
            answer: "Vector graphics refers to digital images that are constructed by a combination of mathemtically defined geometries. Since mathematical geometries are smoothly scalable, each zoom-level is recomputed by your device to provide theoretically infinite scalability."
        },
        {
            question: "What is the advantage of using vector graphics over raster graphics?",
            answer: "Vector images are suitable for applications where an image has to scale perfectly. Typical applications include logo design, CNC machining processes, T-shirt printing processes, advertisement creatives design, poster design, etc."
        },
        {
            question: "Is it advisable to replace all raster images using vector images?",
            answer: "The short answer is no. Both approaches have their advantages and disadvantages. While vector graphics images provide smooth scalability, they are resource intensive both in terms of computation as well as storage. As a result, they are best suited for simple images that can be constructed using mathematical geometries. Anything more complex is best rendered using raster graphics."
        },
        {
            question: "Is this service (RASVEC) really for free?",
            answer: "Yes, there are no strings attached here. We require you to register an account with us for load-balancing and security purposes. Since we provide this service for free, we humbly request you to use RASVEC responsibly. Should users attempt to misuse the service, we have automated alogrithms that step in and restrict usage."
        },
        {
            question: "How is my privacy protected?",
            answer: "Founded in Germany 🇩🇪, we take your privacy very seriously. After you process images, all of your files are deleted within 1 - 2 days. We do not store any personal information."
        },
        {
            question: "I like the free tool, but I am looking for more features. Can you develop them?",
            answer: "We'd love to hear from you. Just go to Account > Help and send us a message describing what your requirement is, and we will get in touch with you shortly."
        },
        // Add more FAQs as needed
    ];

    return (
        <div className="faq-section">
            {faqs.map((faq, index) => (
                <FAQItem key={index} faq={faq} />
            ))}
        </div>
    );
};

const FAQItem = ({ faq }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={toggleOpen}>
                {faq.question}
                {/* <span className="faq-icon">{isOpen ? '✕' : '+'}</span> */}
                {/* <span className="faq-icon">{isOpen ? '✕' : '➕'}</span> */}
                <span className="faq-icon">{isOpen ? '✕' : '+'}</span>
            </div>
            {isOpen && <div className="faq-answer">{faq.answer}</div>}
        </div>
    );
};

export default FAQSection;
