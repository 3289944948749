import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import '../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';

const Register = function () {
    const { registerUser, isLoading, error, registrationSuccess, clearError } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordMismatchError, setPasswordMismatchError] = useState("");

    const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
    const [agreeToTermsOfService, setAgreeToTermsOfService] = useState(false);

    const [isNotificationActive, setIsNotificationActive] = useState(false);

    const canRegister = password && passwordConfirm && !passwordMismatchError && agreeToPrivacyPolicy && agreeToTermsOfService;

    // useEffect(() => {
    //     clearError();
    //     setStatus('success');
    //     console.log('useEffect registratinSuccess: ', registrationSuccess)
    //     console.log('error', error);
    //     // cleanup function
    //     return () => {
    //         setStatus('');
    //     };
    // }, [registrationSuccess, error]);  

    useEffect(() => {
        if (password !== passwordConfirm && passwordConfirm !== "") {
            setPasswordMismatchError('Passwords do not match');
        } else {
            setPasswordMismatchError('');
        }
        clearError();
    }, [password, passwordConfirm]);

    const handleRegister = (event) => {
        event.preventDefault();
        registerUser(email, password, passwordConfirm, isNotificationActive);
    }

    const handleFocus = () => {
        // Show the small-text elements
        document.querySelectorAll('.small-text').forEach(element => {
            element.style.display = 'block';
        });
    };

    const handleBlur = () => {
        // Hide the small-text elements
        document.querySelectorAll('.small-text').forEach(element => {
            element.style.display = 'none';
        });
    };

    const handleChange = (e) => {
        if (e.target.name === 'termsOfService') {
            setAgreeToTermsOfService(e.target.checked);
            setAgreeToPrivacyPolicy(e.target.checked);
        } else if (e.target.name === 'userNotification') {
            setIsNotificationActive(e.target.checked);
        }
    };



    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Register</title>
                <meta name="description" content="Want to generate background using generatebg? Signup here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/register`} />
            </Helmet>
            <h1>Enter Email and Password to Signup</h1>
            <form onSubmit={handleRegister} className="user-form form-card">
                <label className="user-label">
                    Email:
                    <input className="user-input" type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                </label>
                <label className="user-label">
                    Password:
                    <p className="small-text">(min. 8 chars - 1 num, 1 lowercase, 1 uppercase, & 1 special char (hyphen/dash '-' not allowed))</p>
                    <input
                        className="user-input"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required
                    />
                </label>
                <label className="user-label">
                    Confirm Password:
                    <p className="small-text">(min. 8 chars - 1 num, 1 lowercase, 1 uppercase, & 1 special char (hyphen/dash '-' not allowed))</p>
                    <input
                        className="user-input"
                        type="password" value={passwordConfirm}
                        onChange={e => setPasswordConfirm(e.target.value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required />
                </label>
                {/* <label className="checkbox-label">
                    <input
                        type="checkbox"
                        checked={agreeToPrivacyPolicy}
                        onChange={e => setAgreeToPrivacyPolicy(e.target.checked)}
                    />
                    I have read and agree to the&nbsp;
                    <a href="/privacy-policy" target="_blank">  Privacy Policy</a>
                </label> */}
                <label className="checkbox-label">
                    <input
                        type="checkbox"
                        name="termsOfService"
                        checked={agreeToTermsOfService}
                        onChange={handleChange}
                    />
                    I have read and agree to the the&nbsp;
                    <a href="/general-terms-conditions" target="_blank">General Terms and Conditions of Service</a>
                    &nbsp; and&nbsp; <a href="/privacy-policy" target="_blank">  Privacy Policy</a>
                </label>
                <label className="checkbox-label">
                <input
                    type="checkbox"
                    name="userNotification"
                    checked={isNotificationActive}
                    onChange={handleChange}
                />
                    Notify me about new feature updates and special offers
                </label>
                {passwordMismatchError && <p className="small-text failure">❗ {passwordMismatchError}</p>}
                <button
                    type="submit"
                    // className={`user-button ${passwordMismatchError || !password || !passwordConfirm ? 'user-button-disabled' : ''}`}
                    className={`user-button ${!canRegister ? 'user-button-disabled' : ''}`}
                    // disabled={passwordMismatchError || !password || !passwordConfirm}>
                    disabled={!canRegister}>
                    Signup
                </button>
                {isLoading ? (
                    <div className="app-loading-container">
                        <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="app-loading-spinner">
                            <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                                <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                                <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                            </g>
                        </svg>
                    </div>
                ) : (
                    <>
                        {error && (error !== 'You are not logged in') && <p className={`user-message failure`} >❗ {error}</p>}
                        {
                            registrationSuccess &&
                            <p className={`user-message success`}>
                                🚀 Almost there. Check your Email and click the validation link within the next 2 hours to complete your registration.
                            </p>
                        }
                    </>
                )
                }
            </form>
        </div >
    );
}

export default Register;