import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/FetchCroppedImagesStyles.scss';
import LoadingSpinner from '../LoadingSpinner';
import { useNavigate } from 'react-router-dom';

function FetchCroppedImages({ imageIDs, imageCache: externalImageCache, setImageCache: setExternalImageCache }) {
    const [imageData, setImageData] = useState([]);

    // Initialize the loading spinner state
    const [isLoading, setIsLoading] = useState(false);

    // Initialize internal cache if external cache is not provided
    const [internalImageCache, setInternalImageCache] = useState({});
    const isExternalCacheProvided = externalImageCache != null && setExternalImageCache != null;

    // Determine which cache and setter to use
    const imageCache = isExternalCacheProvided ? externalImageCache : internalImageCache;
    const setImageCache = isExternalCacheProvided ? setExternalImageCache : setInternalImageCache;

    // Set navigation states for generating backgrounds from trans images
    const [navigateWithImageID, setNavigateWithImageID] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (navigateWithImageID) {
            navigate("/process/generate-background", { state: { croppedImageID: navigateWithImageID } });
        }
    }, [navigateWithImageID, navigate]);


    useEffect(() => {
        // console.log("Image Keys:", imageKeys); // Debug Code; remove in prod

        const fetchAllImages = async () => {
            // Local array to store fetched image Id and blob URL combinations (objects)
            const fetchedImageData = [];

            setIsLoading(true); // Set loading to true before the request

            for (const imageID of imageIDs) {
                if (!imageCache[imageID]) {
                    try {
                        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/cropped-image/${imageID}`, {
                            responseType: 'blob',
                            withCredentials: true,
                        }, { withCredentials: true });
                        const blob = response.data;
                        const blobUrl = URL.createObjectURL(blob);
                        setImageCache(prevCache => ({ ...prevCache, [imageID]: blobUrl }));
                        fetchedImageData.push({ url: blobUrl, imageID });
                    } catch (error) {
                        console.error(`Error fetching the image with key ${imageID}:`, error);
                    }
                } else {
                    // If the image data is already in the cache, add it to the fetchedImageData object array
                    fetchedImageData.push({ url: imageCache[imageID], imageID });
                }
            }

            // Update state once with all the fetched blob URLs
            setImageData(fetchedImageData);


            setIsLoading(false); // Set loading to false after all the image URLs have been set

        };

        fetchAllImages();

        // Clean up blob URLs
        return () => {
            imageData.forEach(data => {
                URL.revokeObjectURL(data.url);
            });
        };
    }, [imageIDs]);

    return (
        <>
            {isLoading ? <LoadingSpinner /> : ''}
            <div className="cropped-images">
                {!isLoading ?
                    (
                        imageData.map((data, index) => (
                            <div key={index} className="image-wrapper">
                                <img
                                    className="cropped-image"
                                    src={data.url}
                                    alt={`cropped File ${index + 1}`}
                                />
                                <div className="overlay">
                                    <div className="download-container-wrapper">
                                        <a
                                            href={data.url}
                                            download={`croppedImage${index + 1}_${Date.now()}.png`}
                                            className="download-container"
                                        >
                                            <h4>Download</h4>
                                            <svg className="download-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="bgCarrier" strokeWidth="0"></g>
                                                <g id="tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                <g id="iconCarrier">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17 16V14H16V16H8V14H7V16C7 16.5523 7.44772 17 8 17H16C16.5523 17 17 16.5523 17 16ZM14.1465 10.6464L12.5 12.2929V7H11.5V12.2929L9.85356 10.6464L9.14645 11.3536L12 14.2071L14.8536 11.3536L14.1465 10.6464Z" fill="#000000"></path>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="generate-container">
                                        <button
                                            className="generate-container"
                                            onClick={() => setNavigateWithImageID(data.imageID)}
                                        >
                                            <h4>Generate Background</h4>
                                            <svg className="generate-svg" width="800px" height="800px" viewBox="0 -2 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                                <title>generate_background_svg</title>
                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="Dribbble-Light-Preview" transform="translate(-140.000000, -3921.000000)" fill="#000000">
                                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                                            <path className="generate-path" d="M98.141,3768.755 L98.129,3768.768 L98.102,3768.74 L98.141,3768.755 Z M98.143,3768.756 L98.141,3768.755 L98.156,3768.74 L98.143,3768.756 Z M102,3772.752 L98.129,3768.768 L96.74,3770.171 L92.118,3765.542 L92.106,3765.558 L91.977,3765.542 L86,3771.407 L86,3763 L102,3763 L102,3772.752 Z M95.586,3775 L98.2,3772.386 L100.814,3775 L95.586,3775 Z M92.758,3775 L86.297,3775 L92.11,3768.771 L95.34,3772.21 L92.758,3775 Z M84,3777 L104,3777 L104,3761 L84,3761 L84,3777 Z" id="generate_background_svg">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : ''
                }
            </div>
        </>
    );

}

export default FetchCroppedImages;