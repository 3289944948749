import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/CommonComponentStyles.scss';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const PurchasePackageMessage = () => {
    const navigate = useNavigate();

    // Navigate to the pricing route
    const handlePricingRequest = () => {
        navigate("/pricing");
    }

    const [isImageCountLimitReached, setIsImageCountLimitReached] = useState(true);
    const [isPreviewCountLimitReached, setIsPreviewCountLimitReached] = useState(true);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-subscription`, { withCredentials: true });
                setIsImageCountLimitReached(response.data.isImageCountLimitReached);
                setIsPreviewCountLimitReached(response.data.isPreviewCountLimitReached);
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            };
        };

        fetchSubscriptionStatus();
    }, [navigate]);


    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Background Removal Service Declined</title>
                <meta name="description" content="Your request to generate background was declined by generatebg. Please consider buying a valid package before requesting background generation." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/service-declined`} />
            </Helmet>
            <div className="user-message">
                <h1>❗ Service Declined</h1>
                {(isImageCountLimitReached && !isPreviewCountLimitReached) ?
                    <>
                        <p className="text-center">
                            You have reached the limit for high-res images in your current package.
                        </p>
                        <p className="text-center">
                            Note that <b>you can still continue generating low-res previews</b>.
                        </p>
                        <p className="text-center">
                            To continue generating high-res images, consider purchasing a higher-tier.
                        </p>
                        <p className="text-center">
                            Check out our packages and pricing here:
                        </p>
                    </>
                    : ''
                }
                {(isPreviewCountLimitReached && !isImageCountLimitReached) ?
                    <>
                        <p className="text-center" className="text-center">
                            You have reached the limit for low-res previews in your current package.
                        </p>
                        <p className="text-center" className="text-center">
                            Note that <b>you can still continue generating high-res images</b>.
                        </p>
                        <p className="text-center" className="text-center">
                            To continue generating low-res previews, consider purchasing a higher-tier.
                        </p>
                        <p className="text-center" className="text-center">
                            Check out our packages and pricing here:
                        </p>
                    </>
                    : ''
                }
                {(isPreviewCountLimitReached && isImageCountLimitReached) ?
                    <>
                        <p className="text-center">
                            You have reached the limit for both low-res previews and high-res images in your current package.
                        </p>
                        <p className="text-center">
                            To continue generating backgrounds, consider purchasing a higher-tier.
                        </p>
                        <p className="text-center">
                            Check out our packages and pricing here:
                        </p>
                    </>
                    : ''
                }
                <button className="generic-account-button" onClick={handlePricingRequest}>Pricing</button>
            </div>
        </div>
    );
}

export default PurchasePackageMessage;