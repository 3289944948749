import React from 'react';
import '../../styles/LegalStyles.scss';

const OfficialGermanNotice = () => {
    return (
        <div className="legal-notice">
            <p>
                Please be aware that the English translations of all legal documents provided here are for informational purposes only. These translations are intended to assist non-German-speaking readers in understanding the content of the original documents. However, in the case of any discrepancies, ambiguities, or conflicts between the English translation and the original German text, the German version shall prevail as the official and legally binding version. The German version of these documents is the definitive and authoritative text for all legal purposes.
            </p>
        </div >
    );
};

export default OfficialGermanNotice;