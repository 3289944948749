import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import FetchFreeRasvecImage from '../FetchFreeRasvecImage';
import 'react-image-crop/dist/ReactCrop.css';
import '../../styles/CommonComponentStyles.scss';
import '../../styles/RasterToVectorAuthorizedStyles.scss';
import { Helmet } from 'react-helmet-async';

const RasterToVectorAuthorized = () => {

    const [originalImageURL, setOriginalImageURL] = useState(null);
    const [serviceMessage, setServiceMessage] = useState(null);
    const [originalImageName, setOriginalImageName] = useState(null);
    const [freeRasvecImageID, setFreeRasvecImageID] = useState('');

    // State for the completed Matting
    const [isGenerating, setIsGenerating] = useState(false);
    const [isGenerationError, setIsGenerationError] = useState(false);
    const [completedConversion, setCompletedConversion] = useState(false);

    // New state to track if the submit button has been clicked at least once
    const [submitClickedOnce, setSubmitClickedOnce] = useState(false);

    // State to handle vector model selection
    const [vectorModel, setVectorModel] = useState(1);

    // States to handle Root Tracer Options
    const [mode, setMode] = useState('ehrenfeldSimple'); // 'ehrenfeldSimple' or 'ehrenfeldAdvanced' or 'Lindenthal'
    const [customOptions, setCustomOptions] = useState({
        ltres: { value: 0, min: 0, max: 10, type: 'float', label: 'Line error threshold' },
        qtres: { value: 0, min: 0, max: 10, type: 'float', label: 'Quadratic spline error threshold' },
        pathomit: { value: 5, min: 0, max: 20, type: 'int', label: 'Noise reduction path-length threshold' },
        rightangleenhance: { value: true, type: 'boolean', label: 'Enhance right-angles' },
        colorsampling: { value: 0, min: 0, type: 'int', max: 2, label: 'Color Sampling Mode (disabled or random or deterministic)' },
        numberofcolors: { value: 16, min: 2, max: 32, type: 'int', label: 'Number of colors in palette' },
        mincolorratio: { value: 0, min: 0, max: 64, type: 'int', label: 'Color-correction ratio' },
        colorquantcycles: { value: 1, min: 1, max: 5, type: 'int', label: 'Color quantization cylce count' },
        layering: { value: 0, min: 0, max: 1, type: 'int', label: 'Layering (sequentiaon or parallel)' },
        strokewidth: { value: 1, min: 0, max: 4, type: 'int', label: 'SVG stroke width' },
        linefilter: { value: true, type: 'boolean', label: 'Enable line filter noise reduction' },
        scale: { value: 1, min: 0.1, max: 3, type: 'float', label: 'Scaling factor for SVG' },
        roundcoords: { value: 1, min: 1, max: 4, type: 'int', label: 'Coordinate decimal precision' },
        blurradius: { value: 0, min: 0, max: 5, type: 'int', label: 'Gaussian blur' },
        blurdelta: { value: 20, min: 20, max: 256, type: 'int', label: 'Gaussian blur threshold delta' },
    });

    // States to handle Cube Tracer Options
    const [advancedOptions, setAdvancedOptions] = useState({
        colormode: { value: 'color', value1: 'color', value2: 'bw', type: 'string', label: 'color', subLabel1: 'Color Mode', subLabel2: 'Black & White' },
        color_precision: { value: 5, min: 1, max: 8, type: 'int', label: 'No. of significant bits in RGB Channel' },
        gradient_step: { value: 16, min: 0, max: 128, type: 'int', label: 'Color diff. between gradient layers' },
        filter_speckle: { value: 4, min: 0, max: 128, type: 'int', label: 'Eliminate patches smaller than x px' },
        hierarchical: { value: 'stacked', value1: 'stacked', value2: 'cutout', type: 'string', label: 'Cluster mode', subLabel1: 'Stacked', subLabel2: 'Cut-out' },
        mode: { value: 'spline', value1: 'spline', value2: 'polygon', value3: 'pixel', type: 'string', label: 'Curve fit mode', subLabel1: 'Spline', subLabel2: 'Polygon', subLabel3: 'Pixel' },
        corner_threshold: { value: 60, min: 0, max: 180, type: 'int', label: 'Min. corner angle' },
        segment_length: { value: 4, min: 4, max: 10, type: 'int', label: 'Min. path segment length' },
        splice_threshold: { value: 45, min: 0, max: 180, type: 'int', label: 'Min. spline splice angle' },
    });

    // Ref for image loaded
    const imgRef = useRef(null);

    // Ref to handle vector model selection
    const selectRef = useRef(null);

    // Function to create an image blob from an image URL
    const imageURLToBlob = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    };

    // Function that removes background of an image by calling a backend service
    const generateVectorFromRaster = async (payload, submitClickedOnce) => {
        if (!submitClickedOnce) return;
        // Display loading message and SVG
        setIsGenerating(true);
        try {
            const originalImageBlob = await imageURLToBlob(originalImageURL);
            const formData = new FormData();
            formData.append('image', originalImageBlob, originalImageName);

            // Append the payload as a JSON string (since formData supports string values only)
            formData.append('payload', JSON.stringify(payload));

            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/images/raster-to-vector`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            setIsGenerating(false);
            setCompletedConversion(true);
            setServiceMessage(`🚀 ${res.data.message}`);
            setFreeRasvecImageID(res.data.freeRasvecImageID);


        } catch (err) {
            setIsGenerating(false);
            setIsGenerationError(true);
            // Handle Axios error messages
            if (err.response && err.response.status === 429) {
                setServiceMessage("❗ You've made too many requests. Please wait a while and try again.");
            } else if (err.response.status === 401) {
                setServiceMessage('❗ Image must be smaller than 5MB.');
            } else if (err.response.status === 503) {
                setServiceMessage('❗ Apologies. We are currently unable to fulfill your request.')
            }

            else if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error(err.response.data);
                setServiceMessage(err.response.data);
            } else if (err.request) {
                // The request was made but no response was received
                console.error("No response received from server.");
                setServiceMessage("❗ No response received from server.");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Request error:", err.message);
                setServiceMessage("❗ An error occurred during the request.");
            }
        }
    };


    const handleFileChange = async e => {
        // Check if a file is selected
        if (!e.target.files[0]) {
            console.log("File selection was canceled");
            return; // Return early if no file is selected
        }

        // Validates the file format and errors out if the format is anything other than jpeg or png
        if (!(e.target.files[0].type === 'image/png') && !(e.target.files[0].type === 'image/jpeg')) {
            setServiceMessage("❗ File Formats Allowed - JPG, JPEG, & PNG only");
            setIsGenerationError(true);
        }

        const imageURL = URL.createObjectURL(e.target.files[0]);
        setOriginalImageURL(imageURL);
        setOriginalImageName(e.target.files[0].name); // save original name
    };

    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    const handleCustomOptionChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCustomOptions((prevOptions) => ({
            ...prevOptions,
            [name]: {
                ...prevOptions[name],
                value: type === 'checkbox' ? checked : prevOptions[name].type === 'int' ? parseInt(value, 10) : parseFloat(value),
            },
        }));
    };

    // This function applies the change from the temporary state to the actual state when the user finishes sliding
    const handleSliderChangeComplete = async (name, currentValue) => {
        // Update the state using the currentValue
        const updatedCustomOptions = {
            ...customOptions,
            [name]: {
                ...customOptions[name],
                value: currentValue,
            },
        };

        // Now update the state
        setCustomOptions(updatedCustomOptions);

        const simplifiedCustomOptions = Object.keys(updatedCustomOptions).reduce((acc, key) => {
            acc[key] = customOptions[key].value;
            return acc;
        }, {});

        const payload = {
            mode: mode,
            vectorModel: vectorModel,
            customOptions: simplifiedCustomOptions
        };

        await generateVectorFromRaster(payload, submitClickedOnce); // Pass current vectorModel value
    };

    const handleAdvancedOptionChangeForDropdown = async (name, selectedValue) => {
        let updatedAdvancedOptions = {};
        setAdvancedOptions(prevOptions => {
            updatedAdvancedOptions = { ...prevOptions };

            // Directly update the selected value
            updatedAdvancedOptions[name].value = selectedValue;

            // Implement logic to nullify dependent options based on selection
            // For 'colormode' affecting 'color_precision' and 'gradient_step', reset to defaults if switching back from 'bw'
            if (name === "colormode" && selectedValue === "color") {
                updatedAdvancedOptions.color_precision.value = 5; // Reset to default
                updatedAdvancedOptions.gradient_step.value = 16; // Reset to default
            } else if (name === "colormode" && selectedValue === "bw") {
                updatedAdvancedOptions.color_precision.value = null;
                updatedAdvancedOptions.gradient_step.value = null;
            };

            // Reset options to default when switching back to 'spline' from another mode
            if (name === "mode" && selectedValue === "spline") {
                // Assuming these are the default values for these fields
                updatedAdvancedOptions.corner_threshold.value = 60;
                updatedAdvancedOptions.segment_length.value = 4;
                updatedAdvancedOptions.splice_threshold.value = 45;
            } else if (name === "mode" && (selectedValue === "polygon" || selectedValue === "pixel")) {
                updatedAdvancedOptions.corner_threshold.value = null;
                updatedAdvancedOptions.segment_length.value = null;
                updatedAdvancedOptions.splice_threshold.value = null;
            };

            return updatedAdvancedOptions;
        });

        // Simplify the options for the payload
        const simplifiedAdvancedOptions = Object.keys(updatedAdvancedOptions).reduce((acc, key) => {
            // Exclude null values from payload
            if (updatedAdvancedOptions[key].value !== null) {
                acc[key] = updatedAdvancedOptions[key].value;
            }
            return acc;
        }, {});

        // Update the payload considering the new advancedOptions
        const payload = {
            mode: mode,
            vectorModel: vectorModel,
            customOptions: {},
            advancedOptions: simplifiedAdvancedOptions
        };

        await generateVectorFromRaster(payload, submitClickedOnce);

    };

    const handleAdvancedOptionChange = (e) => {
        const { name, value, type } = e.target;
        setAdvancedOptions(prevOptions => ({
            ...prevOptions,
            [name]: {
                ...prevOptions[name],
                value: type === 'int' ? parseInt(value, 10) : value
            },
        }));
    };

    const handleAdvancedSliderChangeComplete = async (name, currentValue) => {
        // Similar to handleSliderChangeComplete, but for advanced options
        const updatedAdvancedOptions = {
            ...advancedOptions,
            [name]: {
                ...advancedOptions[name],
                value: currentValue
            }
        };

        // Update the advancedOptions state
        setAdvancedOptions(updatedAdvancedOptions);

        // Simplify the options for the payload
        const simplifiedAdvancedOptions = Object.keys(updatedAdvancedOptions).reduce((acc, key) => {
            // Exclude null values from payload
            if (updatedAdvancedOptions[key].value !== null) {
                acc[key] = updatedAdvancedOptions[key].value;
            }
            return acc;
        }, {});

        // Update the payload considering the new advancedOptions
        const payload = {
            mode: mode,
            vectorModel: vectorModel,
            customOptions: {},
            advancedOptions: simplifiedAdvancedOptions
        };

        await generateVectorFromRaster(payload, submitClickedOnce);
    };

    // Handler for the submit button
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitClickedOnce(true);
        let simplifiedCustomOptions;
        let simplifiedAdvancedOptions;
        if (mode === 'ehrenfeldSimple') {
            simplifiedCustomOptions = {};
        } else if (mode === 'ehrenfeldAdvanced') {
            simplifiedCustomOptions = Object.keys(customOptions).reduce((acc, key) => {
                acc[key] = customOptions[key].value;
                return acc;
            }, {});
        } else if (mode === 'lindenthal') {
            simplifiedCustomOptions = {};
            simplifiedAdvancedOptions = Object.keys(advancedOptions).reduce((acc, key) => {
                // Exclude null values from payload
                if (advancedOptions[key].value !== null) {
                    acc[key] = advancedOptions[key].value;
                }
                return acc;
            }, {});
        }
        // Prepare the payload with the current state values
        const currentPayload = {
            mode: mode,
            vectorModel: vectorModel,
            customOptions: simplifiedCustomOptions,
            advancedOptions: simplifiedAdvancedOptions
        };
        await generateVectorFromRaster(currentPayload, true); // Pass current vectorModel value
    };

    // Handler for select change
    const handleSelectChange = async (e) => {
        console.log('triggering handleSelectChange');
        setVectorModel(e.target.value);
        const currentPayload = {
            mode: mode,
            vectorModel: e.target.value,
            customOptions: {}
        };

        await generateVectorFromRaster(currentPayload, submitClickedOnce);
    };

    return (
        <>
            <div className="common-styles">
                <Helmet>
                    <title>Raster To Vector</title>
                    <meta name="description" content="Transform any raster image to an infinitely Scalable vectorised image here." />
                    <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/raster-to-vector`} />
                </Helmet>
                <h1>Generate Vector Graphics (SVG)</h1>
                <form className="user-form processing-card">
                    <label className="user-label file-loader-label">
                        Select a file to proceed
                        <input className="user-input file-loader" type="file" name="image" onChange={handleFileChange} />
                    </label>
                    <p className="small-text file-loader-text">
                        File Formats Allowed - JPG, JPEG, & PNG only (max file size: 5MB)
                    </p>

                    {/* {showGuide && <ImageUploadGuide onClose={toggleGuide} />} */}

                    <br />

                    <div className="original-image-container-outer">
                        {originalImageURL &&
                            <>
                                <div className="original-img-container-inner">
                                    <img className="original-img" src={originalImageURL} onLoad={e => imgRef.current = e.currentTarget} alt="Your chosen file is loaded" />
                                </div>
                            </>
                        }
                    </div>

                    {(originalImageURL && !isGenerating && !isGenerationError) ?
                        <>
                            <p className="model-text">Choose Model:</p>
                            <div className="mode-selector">
                                <label>
                                    <input
                                        type="radio"
                                        value="ehrenfeldSimple"
                                        checked={mode === 'ehrenfeldSimple'}
                                        onChange={handleModeChange}
                                    /> Ehrenfeld (simple)
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="ehrenfeldAdvanced"
                                        checked={mode === 'ehrenfeldAdvanced'}
                                        onChange={handleModeChange}
                                    /> Ehrenfeld (advanced)
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="lindenthal"
                                        checked={mode === 'lindenthal'}
                                        onChange={handleModeChange}
                                    /> Lindenthal (advanced)
                                </label>
                            </div>

                            {mode === 'ehrenfeldSimple' ? (
                                // Render simple model selector
                                <label className="user-label rasvec-label">
                                    Choose Sub-model:
                                    <p className="hint">[We recommend 'Standard' for general purposes ]</p>
                                    <select
                                        className="user-input"
                                        value={vectorModel}
                                        ref={selectRef}
                                        onChange={e => handleSelectChange(e)}
                                    >
                                        <option value={1}>Standard (best general model)</option>
                                        <option value={2}>Simple Poster</option>
                                        <option value={3}>Complex Poster</option>
                                        <option value={14}>Premium Poster (large file size)</option>
                                        <option value={4}>Soft Curves</option>
                                        <option value={5}>Crisp Edges</option>
                                        <option value={6}>Highly Detailed (large file size)</option>
                                        <option value={7}>Silky Smooth</option>
                                        <option value={8}>Classic Grayscale</option>
                                        <option value={9}>Premium Color</option>
                                        <option value={10}>Basic Randomizer</option>
                                        <option value={11}>Complex Randomizer</option>
                                        <option value={12}>Modern Art</option>
                                        <option value={13}>Expressionist Art</option>
                                    </select>
                                </label>
                            ) : (mode === 'ehrenfeldAdvanced' ? (
                                // Render ehrenfeldAdvanced options
                                Object.entries(customOptions).map(([key, option]) => (
                                    typeof option.value === 'boolean' ? (
                                        <div key={key} className="custom-checkbox">
                                            <label>
                                                {option.label}
                                                <input
                                                    type="checkbox"
                                                    name={key}
                                                    checked={option.value}
                                                    onChange={
                                                        (e) => {
                                                            // Manually toggle the value for the checkbox, since we're about to set it.
                                                            const newValue = !option.value;

                                                            // Call the handleCustomOptionChange with the event
                                                            handleCustomOptionChange(e);

                                                            // Immediately after updating the state, call handleSliderChangeComplete
                                                            // Note: We're using newValue which is the intended new state of the checkbox.
                                                            handleSliderChangeComplete(key, newValue);
                                                        }
                                                    }
                                                />
                                                <span className="checkMark"></span>
                                            </label>
                                        </div>
                                    ) : (
                                        <div key={key} className="slider-container">
                                            <label>
                                                {option.label}
                                                <input
                                                    type="range"
                                                    name={key}
                                                    min={option.min} // Utilize the min and max from each option
                                                    max={option.max}
                                                    step={option.type === 'int' ? "1" : "0.1"}
                                                    value={option.value}
                                                    onChange={handleCustomOptionChange}
                                                    onMouseUp={() => handleSliderChangeComplete(key, customOptions[key].value)} // Apply change on mouse up
                                                    onTouchEnd={() => handleSliderChangeComplete(key, customOptions[key].value)} // Apply change on touch end
                                                />
                                            </label>
                                            <span className="slider-value">{option.value}</span>
                                        </div>
                                    )
                                ))
                            ) : (
                                // Render Lindenthal options
                                Object.entries(advancedOptions).map(([key, option]) => {
                                    // Conditionally render color_precision and gradient_step based on colormode
                                    if ((key === "color_precision" || key === "gradient_step") && advancedOptions.colormode.value === "bw") {
                                        return null; // Do not render these options if colormode is 'bw'
                                    }

                                    // Conditionally render corner_threshold, segment_length, splice_threshold based on mode
                                    if ((key === "corner_threshold" || key === "segment_length" || key === "splice_threshold") && (advancedOptions.mode.value === "polygon" || advancedOptions.mode.value === "pixel")) {
                                        return null; // Do not render these options if mode is 'polygon' or 'pixel'
                                    }


                                    if (option.type === 'string') {
                                        // Handle string type options with a dropdown list
                                        return (
                                            <div key={key} className="custom-dropdown">
                                                <label>
                                                    {option.label}
                                                    <select
                                                        name={key}
                                                        value={option.value}
                                                        onChange={(e) => {
                                                            // Manually set the value for the select dropdown
                                                            const selectedValue = e.target.value;

                                                            // Call a custom handle option change tailored for dropdowns
                                                            handleAdvancedOptionChangeForDropdown(key, selectedValue);
                                                        }}
                                                        className="custom-dropdown--user-input"
                                                    >
                                                        {/* Only display options that exist (i.e., have subLabels) */}
                                                        {option.subLabel1 && <option value={option.value1}>{option.subLabel1}</option>}
                                                        {option.subLabel2 && <option value={option.value2}>{option.subLabel2}</option>}
                                                        {option.subLabel3 && <option value={option.value3}>{option.subLabel3}</option>}
                                                    </select>
                                                </label>
                                            </div>
                                        );
                                    } else if (option.type === 'int') {
                                        // Handle integer type options with a slider
                                        return (
                                            <div key={key} className="slider-container">
                                                <label>
                                                    {option.label}
                                                    <input
                                                        type="range"
                                                        name={key}
                                                        min={option.min}
                                                        max={option.max}
                                                        step="1"
                                                        value={option.value}
                                                        onChange={handleAdvancedOptionChange}
                                                        onMouseUp={() => handleAdvancedSliderChangeComplete(key, option.value)} // Apply change on mouse up
                                                        onTouchEnd={() => handleAdvancedSliderChangeComplete(key, option.value)} // Apply change on touch end
                                                    />
                                                </label>
                                                <span className="slider-value">{option.value}</span>
                                            </div>
                                        );
                                    }
                                })
                            )
                            )
                            }

                            {(originalImageURL && !isGenerating && !completedConversion && !isGenerationError) ?
                                <button className="user-button matt-button" onClick={handleSubmit} disabled={isGenerating}>Generate SVG</button>
                                : ''}
                        </>
                        : ''
                    }

                    {(serviceMessage && !isGenerating) ? <p className="small-text user-message">{serviceMessage}</p> : ''}
                    {isGenerating ?
                        (
                            <p className="small-text user-message">Vectorising Image...</p>
                        )
                        : ''}
                    {isGenerating ?
                        <p className="rocket--big text-center">🚀</p>
                        // <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="matt-loading">
                        //     <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                        //         <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                        //         <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                        //     </g>
                        // </svg>
                        : ''}
                    {(completedConversion && !serviceMessage) ? <p className="small-text user-message">🚀 Looks Good. Here is your vectorised image (hover over it / click on it to download it):</p> : ''}
                    {(freeRasvecImageID && !isGenerating) ?
                        (
                            <>
                                <FetchFreeRasvecImage
                                    freeRasvecImageID={freeRasvecImageID}
                                />
                            </>
                        )
                        : ''}
                </form>

                <br />
            </div>
        </>
    );
};

export default RasterToVectorAuthorized;