import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';
import '../styles/CommonComponentStyles.scss';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("Processing your payment...");
    const location = useLocation();

    useEffect(() => {
        const updateUserData = async () => {
            // Extract the session ID from the URL
            const sessionId = new URLSearchParams(location.search).get("session_id");

            if (sessionId) {
                try {
                    // Send it to the backend to update user data
                    const response = await axios.post(
                        `${process.env.REACT_APP_SERVER_URL}/payment/complete-checkout`,
                        { sessionId },
                        { withCredentials: true }
                    );

                    if (response.data.success) {
                        setLoading(false);
                        setMessage("Thank you for your purchase! You can now access your package.");
                    } else {
                        setLoading(false);
                        setMessage(response.data.message);
                    }

                    // console.log("Checkout completed successfully");
                    // Handle any other logic, maybe setting user state or showing a confirmation.

                } catch (error) {
                    console.error("Error Completing Checkingout", error);
                }
            }
        };

        updateUserData();
    }, [location]);

    // The Generate Background route
    const handleGenerateBackgroundRequest = () => {
        navigate("/process");
    }

    return (
        <div div className="common-styles">
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Helmet>
                        <title>generatebg - Payment Successful</title>
                        <meta name="description" content="Your generatebg service payment process went through successfully." />
                        <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/payment/success`} />
                    </Helmet>
                    <h1 className="payment-title">{message.startsWith("Thank") ? "Payment Successful" : "Payment Error"}</h1>
                    <p className="payment-message">{message}</p>
                    <button className="generic-account-button--center" onClick={handleGenerateBackgroundRequest}>Generate Background</button>
                </>
            )}
        </div>
    );
}

export default PaymentSuccess;