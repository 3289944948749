import React from 'react';
import '../styles/paymentManagementStyles.scss';

const GenericError = ({ errorMessage }) => {
    return (
        <div className="generic-error">
            <p>❗ {errorMessage}.</p>
        </div>
    );
}

export default GenericError;