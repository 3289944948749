import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import '../../styles/CommonComponentStyles.scss';
// import '../../styles/RasterToVectorLandingStyles.scss';
import { Helmet } from 'react-helmet-async';
import ImageSlider from './ImageSliderRasvec';
import FAQSection from './FAQSectionRasvec';
// import catCartoonPNG from '../../../assets/RasvecDemo/cat_cartoon_zoomed.png';
// import catCartoonSVG from '../../../assets/RasvecDemo/cat_cartoon.svg';
import WomanDoingYogaPNG from '../../../assets/RasvecDemo/woman_doing_yoga_zoomed.png';
import WomanDoingYogaSVG from '../../../assets/RasvecDemo/woman_doing_yoga.svg';
import { ReactComponent as Laurel } from '../../../assets/laurel/laurel.svg';
import { ReactComponent as Star } from '../../../assets/laurel/star.svg';
import { ReactComponent as TrustPilotLogo } from '../../../assets/asSeenOn/trustpilot.svg';
import { ReactComponent as ProductHuntLogo } from '../../../assets/asSeenOn/producthunt.svg';


const RasterToVectorLanding = () => {

    const navigate = useNavigate();


    const handleGetStartedRequest = () => {
        navigate("/register");
    }

    return (
        <>
            <div className="App-home">
                <Helmet>
                    <title>Raster To Vector</title>
                    <meta name="description" content="Convert any raster image to an infinitely Scalable Vectorised image here." />
                    <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/raster-to-vector`} />
                </Helmet>

                <div className="App-landing--primary-block">
                    <div className="App-landing-hero-left">
                        <ImageSlider
                            image1={WomanDoingYogaSVG}
                            image2={WomanDoingYogaPNG}
                        />
                    </div>
                    <div className="App-landing-hero-right">
                        <h1 className="site-title">
                            {/* <span className="rocket--small">🚀</span>  */}
                            Generate infinitely scalable vector graphics in seconds
                            {/* Generate High-res Backgrounds In An Instant */}
                        </h1>
                        <h3 className="site-super-subtitle">Free, high-quality, & easy to use!</h3>
                        <h3 className="site-super-subtitle">Instant downloads!</h3>
                        <div className="call-to-action-section">
                            <button className="generic-button" onClick={handleGetStartedRequest}>Generate Vector graphics</button>
                        </div>
                    </div>
                </div>

                <div className="hero-footer-logos">
                    <span>as seen on</span>
                    {/* <TrustPilotLogo className="hero-footer-logo" /> */}
                    {/* <img src={TrustPilotLogo}
                    alt="Trustpilot logo"
                    className="hero-footer-logo">
                </img> */}
                    <TrustPilotLogo className="hero-footer-logo trustpilot" />
                    <ProductHuntLogo className="hero-footer-logo producthunt" />
                    {/* <img src={HackerNewsLogo}
                    alt="Hacker News logo"
                    className="hero-footer-logo hacker-news">
                </img> */}
                </div>

                <div className="App-landing--secondary-block">
                    <h3 className="faq--heading">Frequently Asked Questions</h3>
                    <FAQSection />
                </div>
            </div>
        </>
    );
};

export default RasterToVectorLanding;