import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    // Crop,
    // PixelCrop,
    // convertToPixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import pica from 'pica';
import ImageCountStatus from './ImageCountStatus';
import ImageUploadGuide from './ImageUploadGuide';
import { useNavigate } from 'react-router-dom';
import '../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';

const ProcessImage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [originalImageURL, setOriginalImageURL] = useState(null);
    const [transImageURL, setTransImageURL] = useState();
    const [resizedImageURL, setResizedImageURL] = useState(null);
    const [serviceMessage, setServiceMessage] = useState(null);
    const [originalImageName, setOriginalImageName] = useState(null);
    const [cropUploadMessage, setCropUploadMessage] = useState('');
    const [transImageID, setTransImageID] = useState('');
    const [crop, setCrop] = useState('');
    const [navigateWithCroppedImageID, setNavigateWithCroppedImageID] = useState(null);
    const [isFetchImageCount, setIsFetchImageCount] = useState(false);
    const imageSize = 2048;

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-subscription`, { withCredentials: true });
                const isPackageActive = response.data.hasActivePackage
                if (!isPackageActive) {
                    navigate('/service-declined');
                } else {
                    setIsFetchImageCount(true);
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            };
        };

        fetchSubscriptionStatus();
    }, [navigate]);


    // // State for crop settings
    // const [crop, setCrop] = useState({
    //     unit: '%',
    //     x: 0,
    //     y: 0,
    //     aspect: 1,
    //     height: 512,
    //     width: 512
    // });


    // This function defines the cropbox for images with width > height
    function centerAspectCropHeight100(mediaWidth, mediaHeight, aspect = 1) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    height: 100,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    };


    // This function defines the cropbox for images with height > width
    function centerAspectCropWidth100(mediaWidth, mediaHeight, aspect = 1) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    };

    // State for the completed crop
    const [completedCrop, setCompletedCrop] = useState(null);

    // State for the completed Matting
    const [isMatting, setIsMatting] = useState(false);
    const [isMattingError, setIsMattingError] = useState(false);
    const [completedMatting, setCompletedMatting] = useState(false);

    // Ref for image loaded
    const imgRef = useRef(null);

    // Loads GenerateBackground as soon as the cropping operation is complete; works in combination
    // with handleBackgroundGeneration(); check that function first to understand how this works
    useEffect(() => {
        if (navigateWithCroppedImageID) {
            navigate("/process/generate-background", { state: { croppedImageID: navigateWithCroppedImageID } });
        }
    }, [navigateWithCroppedImageID, navigate]);

    // // This use effect sets the completed crop to the crop state whenever setCrop is triggered
    // // This is done so that the user may trigger handleBackgroundGeneration even without onComplete flag trigger
    // // from react-image-crop
    // useEffect(() => {
    //     setCompletedCrop(crop);
    // }, [crop]);

    // ************ Utiltiy Functions Begin **************************************************************************************** //

    // Function to create an image blob from an image URL
    const imageURLToBlob = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    };

    // This function obtains the cropped image defined by the cropbox and returns it as a PNG blob (RGBA - 4 channels)
    const getCroppedImg = useCallback(() => {
        if (!completedCrop || completedCrop.x === null || completedCrop.y === null || completedCrop.width === null || completedCrop.height === null) {
            console.error('Crop is not completed or invalid: ', completedCrop);
            return Promise.reject(new Error('Crop is not completed or invalid'));
        }
        const image = imgRef.current;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // canvas.width = completedCrop.width;
        // canvas.height = completedCrop.height;

        canvas.width = imageSize;
        canvas.height = imageSize;

        ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            // completedCrop.width,
            // completedCrop.height
            imageSize,
            imageSize
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                resolve(blob);
            }, 'image/png');
        });
    }, [completedCrop]);


    // This function resizes the input image brime-style into a 2048xheight or width x 2048 image (depending on which side is larger)
    // Finally, it returns a URL of this resized image
    const resizeImage = (sourceImageURL, maxSize) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                let targetWidth, targetHeight;

                // Check whether the width or the height is larger
                if (this.width > this.height) {
                    // If width is larger, scale down the width to maxSize and scale the height to maintain the aspect ratio
                    targetHeight = maxSize;
                    targetWidth = (this.width / this.height) * targetHeight;
                    setCrop(centerAspectCropHeight100(targetWidth, targetHeight, 1));
                } else {
                    // If height is larger, scale down the height to maxSize and scale the width to maintain the aspect ratio
                    targetWidth = maxSize;
                    targetHeight = (this.height / this.width) * targetWidth;
                    setCrop(centerAspectCropWidth100(targetWidth, targetHeight, 1));
                }

                const canvas = document.createElement('canvas');
                canvas.width = targetWidth;
                canvas.height = targetHeight;

                const context = canvas.getContext('2d');

                context.drawImage(this, 0, 0, targetWidth, targetHeight);
                pica().resize(canvas, canvas).then(result => {
                    result.toBlob((blob) => {
                        if (!blob) {
                            reject(new Error('Image processing error'));
                        }
                        resolve(blob);
                    });
                }).catch(err => console.error('pica error:', err)); // Log any error from pica here
            };
            img.onerror = function () { reject(new Error('Image load error')); };  // Log any error from loading the image here
            img.src = sourceImageURL;
        });
    };

    // This function fetches the transImage URL from the backend
    const fetchtransImageURL = async (transImageID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/trans-image/${transImageID}`, {
                responseType: 'blob',
                withCredentials: true,  // If your backend needs cookies or auth headers
            }, { withCredentials: true });
            const blob = response.data;
            const blobUrl = URL.createObjectURL(blob);
            setTransImageURL(blobUrl);
            // console.log('transImageURL: ', transImageURL);
            try {
                const resizedImageBlob = await resizeImage(blobUrl, imageSize);
                // console.log('resizedImageBlob: ', resizedImageBlob);
                const objectURL = URL.createObjectURL(resizedImageBlob);
                setResizedImageURL(objectURL);
            } catch (error) {
                console.error('Error resizing transImage: ', error);
            }
        } catch (error) {
            console.error("Error fetching the transImage:", error);
        }
    }

    // ************ Utiltiy Functions End **************************************************************************************** //

    // Function that removes background of an image by calling a backend service
    const handleImageMatting = async (e) => {
        e.preventDefault();

        // Display loading message and SVG
        setIsMatting(true);
        setIsLoading(true);
        try {
            const originalImageBlob = await imageURLToBlob(originalImageURL);
            // Remove special characters from the original file name
            const cleanedFileName = originalImageName.replace(/[^\w\s.-_]/gi, '');
            const formData = new FormData();
            formData.append('image', originalImageBlob, cleanedFileName);

            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/images/mattImage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            setIsMatting(false);
            setIsLoading(false);
            setCompletedMatting(true);
            // console.log('res.data.message: ', res.data.message);
            setServiceMessage('🚀' + res.data.message);
            setTransImageID(res.data.transImageID);
            if (res.data.transImageID) {
                fetchtransImageURL(res.data.transImageID);
            }
            // console.log('res: ', res); // Debug code; remove in production
        } catch (err) {
            setIsMatting(false);
            setIsLoading(false);
            setIsMattingError(true);
            // Handle Axios error messages
            if (err.response && err.response.status === 429) {
                setServiceMessage("❗ You've made too many requests. Please wait a while and try again.");
            } else if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error(err.response.data);
                setServiceMessage("❗ " + err.response.data);
            } else if (err.request) {
                // The request was made but no response was received
                console.error("No response received from server.");
                setServiceMessage("❗ No response received from server.");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Request error:", err.message);
                setServiceMessage("❗ An error occurred during the request.");
            }
        }
    };


    //This function loads the input image from the user into a URL state object for use within this component
    const handleFileChange = async e => {

        // Check if a file is selected
        if (!e.target.files[0]) {
            console.log("File selection was canceled");
            return; // Return early if no file is selected
        }

        // Validates the file format and errors out if the format is anything other than jpeg or png
        if (!(e.target.files[0].type === 'image/png') && !(e.target.files[0].type === 'image/jpeg')) {
            setServiceMessage("❗ File Formats Allowed - JPG, JPEG, & PNG only");
            setIsMattingError(true);
        }

        const inputFile = e.target.files[0];
        const objectURL = URL.createObjectURL(inputFile);
        setOriginalImageURL(objectURL);
        setOriginalImageName(e.target.files[0].name); // save original name
    };

    // Thsi function handles uploads the cropped (trans) image and sets the croppedImageID 
    // react state, which eventually triggers the GenerateBackground component for the next pipeline step
    const handleBackgroundGeneration = async (e, transImageID) => {
        e.preventDefault(); // Prevent the default form submission behavior
        // navigate("/process/generate-background", { state: { transImageID } });
        setIsLoading(true);
        try {
            const croppedImageBlob = await getCroppedImg();
            const formData = new FormData();
            formData.append('image', croppedImageBlob, originalImageName);
            formData.append('transImageID', transImageID);

            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/images/upload-cropped-image/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            if (res.data.croppedImageID) {
                // Set the state variable with the croppedImageID
                setIsLoading(false);
                setNavigateWithCroppedImageID(res.data.croppedImageID);
            } else {
                console.log('No cropped image ID received; cannot proceed with background generation');
            }

        } catch (err) {
            // Handle Axios error messages
            setIsLoading(false);
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error(err.response.data);
                setCropUploadMessage('❗ ' + err.response.data);
            } else if (err.request) {
                // The request was made but no response was received
                console.error("No response received from server.");
                setCropUploadMessage("❗ No response received from server.");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Request error:", err.message);
                setCropUploadMessage("❗ An error occurred during the request.");
            }
        }
    };

    const [showGuide, setShowGuide] = useState(false);

    const toggleGuide = () => {
        setShowGuide(!showGuide);
    };

    return (
        <>
            <div className="common-styles">
                <Helmet>
                    <title>generatebg - Process Image</title>
                    <meta name="description" content="Pre-process your image as the first step before generating background here." />
                    <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/process`} />
                </Helmet>
                <h1>Generate Background</h1>
                <form className="user-form processing-card">
                    {isFetchImageCount ? <ImageCountStatus /> : ''}
                    <label className="user-label">
                        Step 1: Select a file to remove background
                        <input className="user-input file-loader" type="file" name="image" onChange={handleFileChange} />
                    </label>
                    <p className="small-text file-loader-text">
                        File Formats Allowed - JPG, JPEG, & PNG only ---&gt;
                        <span className="info-button" onClick={toggleGuide}>Image Upload - Guide </span>
                    </p>

                    {showGuide && <ImageUploadGuide onClose={toggleGuide} />}

                    <br />

                    <div className="original-image-container-outer">
                        {originalImageURL &&
                            (
                                <>
                                    <div className="original-img-container-inner">
                                        <img className="original-img" src={originalImageURL} onLoad={e => imgRef.current = e.currentTarget} alt="Your uploaded source file" />
                                    </div>
                                </>
                            )
                        }
                    </div>

                    {(serviceMessage) ? <p className="small-text user-message"> {serviceMessage}...</p> : ''}
                    {isMatting ? <p className="small-text user-message">Matting in progress...</p> : ''}
                    {completedMatting ? (
                        <>
                            <p className="small-text user-message">🚀 Preprocessing complete...</p>
                            <p className="small-text user-message">🚀 Click and drag the box to adjust your subject's placement...</p>
                            <p className="small-text user-message">🚀 If you are satisfied with the selection as it is, just click inside the box once below:</p>
                        </>
                    ) : ''}
                    {transImageID ?
                        <>
                            <div className="crop-container" >
                                {resizedImageURL &&
                                    <>
                                        <ReactCrop
                                            style={{
                                                ...{ objectFit: 'contain' },
                                                // ...(isInputHeightGreater ? { width: '512px' } : { height: '512px' })
                                            }}
                                            crop={crop}
                                            onChange={(_, c) => setCrop(c)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            locked={true}
                                        >
                                            <img
                                                className="crop-img"
                                                src={resizedImageURL}
                                                onLoad={e => imgRef.current = e.currentTarget}
                                                alt="The matted and cropped file is displayed here"
                                            // style={{
                                            //     ...(isInputHeightGreater ? { width: '512px' } : { height: '512px' })
                                            // }}
                                            />
                                        </ReactCrop>
                                    </>
                                }
                            </div>
                            {/* <button className="user-button" onClick={(e) => handleBackgroundGeneration(e, transImageID)}>
                                Generate Background
                            </button> */}
                        </>
                        : ''}
                    {isLoading ?
                        <p className="rocket--big text-center">🚀</p>
                        // <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="matt-loading">
                        //     <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                        //         <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                        //         <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                        //     </g>
                        // </svg>
                        : ''}
                    {completedCrop && !isLoading && (
                        <button className="user-button" onClick={(e) => handleBackgroundGeneration(e, transImageID)}>
                            Generate Background
                        </button>
                    )}
                    {cropUploadMessage && <p className="small-text user-message"> {cropUploadMessage}</p>}
                    {(originalImageURL && !isMatting && !completedMatting && !isMattingError) ?
                        <button className="user-button matt-button" onClick={handleImageMatting} disabled={isMatting || completedMatting}>Process Image</button>
                        : ''
                    }
                </form>

                <br />
            </div>
        </>
    );
};

export default ProcessImage;