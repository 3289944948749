import React from 'react';
import '../../styles/LegalStyles.scss';

const CookiesDe = () => {
    return (
        <div className="legal-doc">
            <h1 className="title-left">Cookie Policy</h1>
            <p>
                (1) Beim Betrieb unserer App nutzen wir Cookies. Bei Cookies handelt es sich um kleine Textdateien, die auf dem Gerätespeicher Ihres mobilen Endgerätes oder Computers abgelegt und der von Ihnen verwendeten mobilen oder Web App zugeordnet gespeichert werden und durch welche der Stelle, die das Cookie setzt, bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, eine App nutzerfreundlicher, sicherer und effektiver zu machen.
            </p>
            <p>
                (2) Cookies enthalten Daten, die eine Wiedererkennung des genutzten Geräts ermöglichen. Teils enthalten Cookies lediglich Informationen zu Einstellungen, die nicht personenbeziehbar sind. Cookies können einen Nutzer nicht direkt identifizieren.
            </p>
            <p>
                (3) Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden. Hinsichtlich ihrer Funktion unterscheidet man bei Cookies wiederum zwischen:
            </p>
            <p className="text-indented">
                –	Technical Cookies: Diese sind zwingend erforderlich, um sich innerhalb der App zu bewegen, grundlegende Funktionen zu nutzen und die Sicherheit der App zu gewährleisten; sie sammeln weder Informationen über Sie zu Marketingzwecken noch speichern sie, welche Webseiten Sie besucht haben;
            </p>
            <p className="text-indented">
                –	Performance Cookies: Diese sammeln Informationen darüber, wie Sie unsere App nutzen, welche Seiten Sie besuchen und zB ob Fehler bei der Appnutzung auftreten; sie sammeln keine Informationen, die Sie identifizieren könnten – alle gesammelten Informationen sind anonym und werden nur verwendet, um unsere App zu verbessern und herauszufinden, was unsere Nutzer interessiert;
            </p>
            <p className="text-indented">
                –	Advertising Cookies, Targeting Cookies: Diese dienen dazu, dem Appnutzer bedarfsgerechte Werbung innerhalb der App oder Angebote von Dritten anzubieten und die Effektivität dieser Angebote zu messen; Advertising und Targeting Cookies werden maximal 13 Monate lang gespeichert;
            </p>
            <p className="text-indented">
                –	Sharing Cookies: Diese dienen dazu, die Interaktivität unserer App mit anderen Diensten (z. B. sozialen Netzwerken) zu verbessern; Sharing Cookies werden maximal 13 Monate lang gespeichert.
            </p>
            <p>
                (4) Jeder Einsatz von Cookies, der nicht zwingend technisch erforderlich ist, stellt eine Datenverarbeitung dar, die nur mit einer ausdrücklichen Einwilligung Ihrerseits erlaubt ist, gem. Art. 6 Abs. 1 S. 1 lit. a DS-GVO. Dies gilt insbesondere für die Verwendung von Advertising, Targeting oder Sharing Cookies. Darüber hinaus geben wir Ihre durch Cookies verarbeiteten personenbezogenen Daten nur an Dritte weiter, wenn Sie eine ausdrückliche Einwilligung dazu erteilt haben, gem. Art. 6 Abs. 1 S. 1 lit. a DS-GVO.
            </p>
            <p>
                (5) Derzeit verwenden wir in unserer Web App nur unbedingt notwendige technische Cookies. Darüber hinaus verwendet unser Zahlungsdienstleister eigene Cookies zur Integration in unsere App, um Zahlungsdienste für Sie bereitzustellen. Weitere Informationen zu deren Cookie-Richtlinie finden Sie hier: https://stripe.com/de/legal/cookies-policy. Ebenso verwendet unser Anbieter von Web-Performance- und Sicherheitsdiensten eigenen Cookies zur Integration in unsere App, um Sicherheits- und Performance-dienste für Sie bereitzustellen. Weitere Informationen zu deren Cookie-Richtlinie finden Sie hier: https://www.cloudflare.com/privacypolicy/.
            </p>
            <p>
                (6) Unten finden Sie eine Liste der Cookies, die wir auf unserer Website / Web App verwenden, und deren jeweilige Zwecke:
            </p>
            <p>-----------------------------</p>
            <p>Cookie-Name: theme</p>
            <p>Zweck: Wird verwendet, um die Auswahl des Nutzers zwischen hellem und dunklem Theme für die Website/Web-App über Sessions hinweg zu speichern.</p>
            <p>Aufbewahrungszeitraum: unbegrenzt, bis der Nutzer das Cookie löscht oder entfernt.</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: cookieAccepted</p>
            <p>Zweck: Wird verwendet, um den Zustand des Cookie-Informationen-Popups zu speichern. Wenn der Nutzer einmal auf den "Okay"-Knopf geklickt hat, stellt dieses Cookie sicher, dass das Cookie-Informationen-Popup nicht wieder angezeigt wird.</p>
            <p>Aufbewahrungszeitraum: 1 Woche.</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: accessToken</p>
            <p>Zweck: Ein verschlüsseltes Cookie, das zur Authentifizierung eines angemeldeten Benutzers (kurzfristig) verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 1 Stunde.</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: refreshToken</p>
            <p>Zweck: Ein verschlüsseltes Cookie, das zur Authentifizierung eines angemeldeten Benutzers (langfristig) verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 1 Woche.</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>
                (7) Unten finden Sie eine Liste der Cookies, die unser Zahlungsdienstleister (stripe) auf unserer Website verwendet und deren jeweilige Zwecke:
            </p>
            <p>-----------------------------</p>
            <p>Cookie-Name: __stripe_mid</p>
            <p>Zweck: Langfristiges Cookie, das zur Betrugsprävention verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 1 Jahr.</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: __stripe_mid</p>
            <p>Zweck: Kurzfristiges Cookie, das zur Betrugsprävention verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 30 Minuten.</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: __stripe_orig_props</p>
            <p>Zweck: Wird verwendet, um zu analysieren, wie Nutzer mit den Diensten von Stripe interagieren.</p>
            <p>Aufbewahrungszeitraum: 1 Jahr.</p>
            <p>Cookie-Typ: Performance (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: stripe.csrf</p>
            <p>Zweck: Ein Sicherheitscookie, das zum Schutz der Nutzerdaten vor unbefugtem Zugriff verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 90 Tage.</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: site-auth</p>
            <p>Zweck: Wird verwendet, um die ordnungsgemäße Funktionalität der Dienste von Stripe zu gewährleisten.</p>
            <p>Aufbewahrungszeitraum: Session</p>
            <p>Cookie-Typ: Session (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: machine_identifier</p>
            <p>Zweck: Langfristiges Cookie, das zur Betrugsprävention verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 1 Jahr</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: _ga, _gat, _gid, _gcl</p>
            <p>Zweck: Cookie von Google zur Steuerung der erweiterten Script- und Ereignisbehandlung.</p>
            <p>Aufbewahrungszeitraum: 2 Jahre</p>
            <p>Cookie-Typ: Analyse/Ereignisbehandlung (optional)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: private_machine_identifier</p>
            <p>Zweck: Langfristiges Cookie, das zur Betrugsprävention verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 1 Jahr</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie-Name: m</p>
            <p>Zweck: Langfristiges Cookie, das zur Betrugsprävention verwendet wird.</p>
            <p>Aufbewahrungszeitraum: 2 Jahre</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
            <p>Cookie Name: cf_clearance</p>
            <p>Zweck: Sicherheitscookie, das zur Ausgabe von Herausforderungen zur Authentifizierung menschlicher Benutzer verwendet wird.</p>
            <p>Aufbewahrungszeitraum: unbegrenzt</p>
            <p>Cookie-Typ: Technical (essenziell)</p>
            <p>-----------------------------</p>
        </div>
    );
};

export default CookiesDe;