import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const UnsubscribeConfirmation = ({ onConfirm }) => {
    return (
        <div className="common-styles">
            <div className="manage-subscription">
                <p className="small-text account-info text-center">Are you sure you want to unsubscribe?</p>
                <p className="small-text account-info text-center">If you choose to unsubscribe, you will lose access to our services after you exhaust any remaining images in your package.</p>
                <p className="small-text account-info text-center">There's no going back on this.</p>
                <button className="cancel-unsubscribe-button" onClick={() => onConfirm(false)}>Cancel</button>
                <button className="unsubscribe-button" onClick={() => onConfirm(true)}>Yes, Unsubscribe</button>
            </div>
        </div>
    );
};

const ManageSubscription = ({ onUnsubscribe }) => {
    const navigate = useNavigate();
    const [showUnsubscribeConfirmation, setShowUnsubscribeConfirmation] = useState(false);
    const [message, setMessage] = useState("");
    const [isUnsubscribedButServiceActive, setIsUnsubscribedButServiceActive] = useState(false);

    // Checks user subscription status and sets state flags necessary for delivering the unsubscribe link
    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-subscription`, { withCredentials: true });
                if (response.data.packageType === 'subscription') {
                    setIsUnsubscribedButServiceActive(response.data.isUnsubscribedButServiceActive);
                }
            } catch (error) {
                console.error('Error checking subscription:', error);
            }
        };

        checkSubscription();
    }, []);

    // Makes the "Unsubscribe" request
    const handleUnsubscribe = async (confirm) => {
        if (confirm) {
            // Make the unsubscribe request to the backend
            try {
                await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/unsubscribe`, {}, { withCredentials: true });
                setMessage('You have successfully unsubscribed.');
                setIsUnsubscribedButServiceActive(true); // Set this to true since the user has unsubscribed but still has access
            } catch (error) {
                console.error('Error unsubscribing:', error);
                setMessage('An error occurred while unsubscribing. Please try again later.');
            }
        }
        setShowUnsubscribeConfirmation(false);
    };

    // Routes one level back to /account
    const handleBackRequest = () => {
        navigate("/account");
    };

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Manage Subscription</title>
                <meta name="description" content="Manage your generatebg account subscriptions here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/account/manage-subscription`} />
            </Helmet>
            <h1>Manage Your Subscription</h1>
            <br></br>
            <p className="text-center">
                <button className="generic-account-button" onClick={handleBackRequest}>Go Back</button>
            </p>
            {!showUnsubscribeConfirmation && !isUnsubscribedButServiceActive && (
                <button className="unsubscribe-button" onClick={() => setShowUnsubscribeConfirmation(true)}>Unsubscribe</button>
            )}
            {showUnsubscribeConfirmation && <UnsubscribeConfirmation onConfirm={handleUnsubscribe} />}
            {message && <p className="user-message text-center">❗ {message}</p>}
            {isUnsubscribedButServiceActive && (
                <>
                    <p className="small-text account-info text-center">❗ Your subscription has been cancelled. You can still access our services to use any remaining unused images in your package.</p>
                    <p className="small-text account-info text-center">❗ After you have exhausted all remaining images, you will no longer be able to use our services.</p>
                    <p className="small-text account-info text-center">❗ If the cancelation happened automatically, it is highly likely that it was due to repeated failed payment attempts.</p>
                    <p className="small-text account-info text-center">❗ If this was a mistake or if you wish to resubscribe, please visit our website and ensure that your payment details are up to date.</p>
                </>
            )}
        </div>
    );
};

export default ManageSubscription;