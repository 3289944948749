import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FetchUpscaledImages from './FetchUpscaledImages';
import { Helmet } from 'react-helmet-async';


const UpscaledImages = () => {
    const navigate = useNavigate();
    const [imageIds, setImageIds] = useState([]);
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [page, setPage] = useState(1);
    const shouldImageCountBeUpdatedRef = useRef(true);
    const [imageCache, setImageCache] = useState({});

    useEffect(() => {
        const fetchImages = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/user-upscaled-images/${page}`, { withCredentials: true });
                setImageIds(prevIds => {
                    // Create a Set from the existing IDs for efficient lookup
                    const existingIdsSet = new Set(prevIds);
                    // Filter out any IDs that are already in the existingIdsSet
                    const newIds = response.data.imageIds.filter(id => !existingIdsSet.has(id));
                    // Set the LoadingImagesCount only if unique newIds are returned
                    if (shouldImageCountBeUpdatedRef.current) {
                        setLoadedImagesCount(prevCount => prevCount + response.data.imageIds.length);
                        shouldImageCountBeUpdatedRef.current = false;
                    }
                    // Return the new array with duplicates filtered out
                    return [...prevIds, ...newIds];
                });
                setTotalImagesCount(response.data.totalImagesCount);
            } catch (error) {
                console.error('Error fetching generated images:', error);
            }
        };

        fetchImages();
    }, [page]);

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
        shouldImageCountBeUpdatedRef.current = true;
    }

    // Routes one level back to /account
    const handleBackRequest = () => {
        navigate("/account");
    };

    return (
        <>
            <div className="common-styles">
                <Helmet>
                    <title>generatebg - High-res Backgrounds</title>
                    <meta name="description" content="Access your high-res generated backgrounds here" />
                    <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/account/upscaled-images`} />
                </Helmet>
                <p className="text-center">
                    <button className="generic-account-button" onClick={handleBackRequest}>Go Back</button>
                </p>
                <h1>Your Generated High-Res Images</h1>
                <p className="text-center">Info 1: In case you have just submitted new image generations, please wait ~ 5 minutes and they will be probably ready for you here.</p>
                <p className="text-center">Info 2: <b>Preview downloads are free</b> (low-res: 512 x 512 px). Your <b>credits</b> are used <b>only for</b> generating <b>high-res images</b> (2048 x 2048 px).</p>
                {(
                    /* Pass the imageIds to the FetchGeneratedImages component */
                    imageIds.length > 0 &&
                    <FetchUpscaledImages
                        imageCache={imageCache}
                        setImageCache={setImageCache}
                        imageIDs={imageIds}
                    />
                )}
                {(loadedImagesCount < totalImagesCount) && (
                    <button className="generic-account-button" onClick={handleLoadMore}>
                        Load More
                    </button>
                )}
            </div>
        </>
    );
};

export default UpscaledImages;