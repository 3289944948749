import React from 'react';
import firstImage from '../../../assets/docs/002_how_to_use_generatebg/header_menu_showing_generatebg_functions.webp';
import secondImage from '../../../assets/docs/002_how_to_use_generatebg/background_removal_step_by_step_guide.webp';
import thirdImage from '../../../assets/docs/002_how_to_use_generatebg/initial_preprocessing_for_background_generation.webp';
import fourthImage from '../../../assets/docs/002_how_to_use_generatebg/steps_to_generate_low_res_previews.webp';
import fifthImage from '../../../assets/docs/002_how_to_use_generatebg/steps_to_generate_high_res_backgrounds.webp';

const HowToUseThisProduct = () => {

    return (
        <>
            <h2 className="docs-header">How To Use Our AI Background Generator?</h2>

            <h3 className="docs-read-time">Estimated Reading Time: 4 minutes</h3>

            <h3 className="docs-subheader table-of-contents">Table of Contents</h3>
            <ul className="docs-list">
                <li className="docs-list-item">Primary Functions of Generatebg</li>
                <li className="docs-list-item">Removing Backgrounds</li>
                <li className="docs-list-item">Important Side Note</li>
                <li className="docs-list-item">Generating Low-Resolution Backgrounds (Previews)</li>
                <li className="docs-list-item">Generating High-Resolution Backgrounds (2048 x 2048 px)</li>
            </ul>
            <hr className="horizontal-rule"></hr>

            <h3 className="docs-subheader">Primary Functions of Generatebg</h3>
            <p>Generatebg offers two primary functions:</p>
            <ul className="docs-list">
                <li className="docs-list-item">Remove Background</li>
                <li className="docs-list-item">Generate Background</li>
            </ul>
            <p>Both of these functions are available via the menu as shown below:</p>
            <img src={firstImage}
                alt="Header menu showing the various functions of generatebg in mobile mode; The remove background and generate background functions are highlighted."
                className="docs-image">
            </img>

            <h3 className="docs-subheader">Removing Backgrounds</h3>
            <p>If your goal is to remove the background from your image, just choose "Remove Background" from the menu, upload the image (after checking the background removal guide) and hit the "Remove Background" button.</p>
            <p>Once the matted image is available, just hover over it (or tap on it if on mobile) to download the background-removed image.</p>
            <img src={secondImage}
                alt="A step-by-step guide showing the background removal process. Upload the image, hit the remove background button and wait for the background-removed image to be served. Hover over it or tap on it to download the background-removed image."
                className="docs-image">
            </img>

            <h3 className="docs-subheader">Important Side Note</h3>
            <p>We will see in a moment that the "Generate Background" functionlity also removes the background of an image as an intermediate step.</p>
            <p>But if your goal is to just remove the background of an image, we highly recommend using the "Remove Background" functionality.</p>
            <p>The reason for this is that the "Remove Background" functionality retains the original resolution of your image, whereas the intermediate step in the "Generate Background" functionality alters the resolution of the image that is suited for our AI models to generate the background.</p>

            <h3 className="docs-subheader">Generating Low-Resolution Backgrounds (Previews)</h3>
            <p>First things first, please do not upload a background-removed image to the generate background tool. This might confuse our AI models as they remove the background anyway.</p>
            <p>To generate backgrounds, just upload your preferred image (after checking the image upload guide) and hit the "Process Image" button. This will trigger the background-removal process.</p>
            <p>Once the background-removed image is available, you will be able to adjust the placement of your subject. Once you are satisfied with the positioning, hit the "Generate Background" button:</p>
            <img src={thirdImage}
                alt="Initial pre-processing for background generation: involves removing the background and positioning the subject."
                className="docs-image">
            </img>
            <p>Once you hit the "Generate Background" button, you will be taken to the "Generate Background" wizard, where you can describe the background in plain words (more on this in a separate doc coming soon).</p>
            <p>There is also a field for describing what should <b>NOT</b> be in the background. We recommend using this only if there is a need for it.</p>
            <p>Similarly, we recommend the "level of creative freedom in generation" to be set to medium unless it proves to be advantageous to change it based on your experience.</p>
            <p>Once you are satisfied, just hit the "Generate" Button and the low-res previews will be generated for you; <b>note that no credits are consumed for previews</b>.</p>
            <p>You may hover over the images or tap on them if on mobile to either download them or to generate high-res backgrounds:</p>
            <img src={fourthImage}
                alt="Step-by-step process to generate low-res previews."
                className="docs-image">
            </img>

            <h3 className="docs-subheader">Generating High-Resolution Backgrounds (2048 x 2048 px)</h3>
            <p>Once you hit the "Generate High-Res" button, you will be taken to the "Generate High-Res Image" wizard, where all you will need to do is to hit the "Generate Image" button.</p>
            <p>Note that once you hit this button, one credit from your package will be consumed. <b>Previews do not consume any credits; each high-resolution background generated consumes 1 credit</b>:</p>
            <img src={fifthImage}
                alt="Step-by-step process to generate high-res backgrounds."
                className="docs-image">
            </img>
            <p>Once the high-resolution background image is generated, it will be served in the same wizard. You may hover over the image or tap on it (on mobile) to download it to your device.</p>
        </>
    );
};

export default HowToUseThisProduct;
