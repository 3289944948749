import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import themeStyles from './styles/ThemeToggleButton.module.scss';

const Header = function ({ isMenuOpen, toggleMenu }) {

    const location = useLocation();
    const { isAuthorized, checkUserSession } = useContext(AuthContext);


    // Pull the theme state from local storage (string) and set its functional value (boolean) to the local state variable
    const [darkTheme, setDarkTheme] = useState(() => {
        return localStorage.getItem('theme') === 'true';
    });

    // Function to toggle between light and dark themes
    function toggleTheme() {
        setDarkTheme(prevTheme => {
            const newTheme = !prevTheme;
            localStorage.setItem('theme', newTheme.toString());
            return newTheme;
        });
    }

    // Effect to enforce light mode on home route and manage body class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.remove('dark-mode');
        } else {
            document.body.classList.toggle('dark-mode', darkTheme);
        }
    }, [location.pathname, darkTheme]);

    // Checking the user's auth state right at the start
    useEffect(() => {
        checkUserSession();
    }, []);


    return (
        <>
            <div className={`header-bar ${isMenuOpen ? 'open' : ''} ${isAuthorized ? 'authorized' : ''}`} onClick={toggleMenu}>
                <span className="logo">🚀 Generatebg</span>
                <button className="menu-button" onClick={toggleMenu}>
                    {isMenuOpen ? 'Close' : 'Menu'}
                </button>
            </div>
            <nav className="App-nav">
                <ul className={`App-navList ${isMenuOpen ? 'open' : ''}`}>
                    <li onClick={toggleMenu}><NavLink className="App-link" to="/">Home</NavLink></li>
                    {!isAuthorized && (
                        <>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/free-tools">Free Tools</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/pricing">Pricing</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/docs">Docs</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/login">Login</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link call-to-action" to="/register">Get Started</NavLink></li>
                        </>
                    )}
                    {isAuthorized && (
                        <>
                            {/* <li onClick={toggleMenu}><NavLink className="App-link" to="/remove-background">Remove Background</NavLink></li> */}
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/process">Generate Background</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/free-tools">Free Tools</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/docs">Docs</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/pricing">Pricing</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/account">Account</NavLink></li>
                            <li onClick={toggleMenu}><NavLink className="App-link" to="/logout">Logout</NavLink></li>
                        </>
                    )}
                </ul>
                {location.pathname !== '/' && (
                    <div className={themeStyles.themeButtonWrapper}>
                        <span className={themeStyles.themeButton} onClick={toggleTheme}>
                            <svg className="theme-button" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path d="M12 4c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm0 14c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6zM12 7v10c2.757 0 5-2.243 5-5s-2.243-5-5-5z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                )}
            </nav >
        </>
    )
};

export default React.memo(Header);