import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/FetchFreeMattedImagesStyles.scss';
import LoadingSpinner from '../LoadingSpinner';

function FetchFreeMattedImages({ imageIDs, imageCache: externalImageCache, setImageCache: setExternalImageCache }) {
    const [imageUrls, setImageUrls] = useState([]);

    // Initialize the loading spinner state
    const [isLoading, setIsLoading] = useState(false);

    // Initialize internal cache if external cache is not provided
    const [internalImageCache, setInternalImageCache] = useState({});
    const isExternalCacheProvided = externalImageCache != null && setExternalImageCache != null;

    // Determine which cache and setter to use
    const imageCache = isExternalCacheProvided ? externalImageCache : internalImageCache;
    const setImageCache = isExternalCacheProvided ? setExternalImageCache : setInternalImageCache;

    useEffect(() => {
        // console.log("Image Keys:", imageKeys); // Debug Code; remove in prod

        const fetchAllImages = async () => {
            // Local array to store fetched blob URLs
            const fetchedUrls = [];

            setIsLoading(true); // Set loading to true before the request

            for (const imageID of imageIDs) {
                if (!imageCache[imageID]) {
                    try {
                        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/images/freeTransparent/${imageID}`, {
                            responseType: 'blob',
                            withCredentials: true,
                        }, { withCredentials: true });
                        const blob = response.data;
                        const blobUrl = URL.createObjectURL(blob);
                        setImageCache(prevCache => ({ ...prevCache, [imageID]: blobUrl }));
                        fetchedUrls.push(blobUrl);
                    } catch (error) {
                        console.error(`Error fetching the image with key ${imageID}:`, error);
                    }
                } else {
                    // If the image is already in the cache, add it to the fetchedUrls array
                    fetchedUrls.push(imageCache[imageID]);
                }
            }

            // Update state once with all the fetched blob URLs
            setImageUrls(fetchedUrls);

            setIsLoading(false); // Set loading to false after all the image URLs have been set

        };

        fetchAllImages();

        // Clean up blob URLs
        return () => {
            imageUrls.forEach(url => {
                URL.revokeObjectURL(url);
            });
        };
    }, [imageIDs]);

    return (
        <>
            {isLoading ? <LoadingSpinner /> : ''}
            <div className="free-matted-images">
                {!isLoading ?
                    (
                        imageUrls.map((url, index) => (
                            <div key={index} className="image-wrapper">
                                <img
                                    className="free-matted-image"
                                    src={url}
                                    alt={`Free Matted File ${index + 1}`}
                                />
                                <div className="overlay">
                                    <div className="download-container-wrapper">
                                        <a
                                            href={url}
                                            download={`MattedImage${index + 1}_${Date.now()}.png`}
                                            className="download-container"
                                        >
                                            <h4>Download</h4>
                                            <svg className="download-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="bgCarrier" strokeWidth="0"></g>
                                                <g id="tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                <g id="iconCarrier">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17 16V14H16V16H8V14H7V16C7 16.5523 7.44772 17 8 17H16C16.5523 17 17 16.5523 17 16ZM14.1465 10.6464L12.5 12.2929V7H11.5V12.2929L9.85356 10.6464L9.14645 11.3536L12 14.2071L14.8536 11.3536L14.1465 10.6464Z" fill="#000000"></path>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : ''
                }
            </div>
        </>
    );

}

export default FetchFreeMattedImages;