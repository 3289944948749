import React from 'react';
import '../../styles/LegalStyles.scss';
import OfficialGermanNotice from './OfficialGermanNotice.js';

const TermsEn = () => {
    return (
        <div className="legal-doc">
            <OfficialGermanNotice />
            <h1>General Terms and Conditions of generatebg UG (for users and clients)</h1>
            {/* Add your terms and conditions here in JSX format */}
            <p>
                Our Terms and Conditions apply when using the services of generatebg UG in the
                field of digital image editing based on artificial intelligence. In particular,
                the terms and conditions contain your rights and obligations as a user of our platforms as well as the
                most important data protection provisions.
            </p>
            {/* More paragraphs and formatting as needed */}
            <br></br>
            <h3>Preamble of generatebg UG</h3>
            <p>
                Welcome to <b>generatebg UG</b>, your innovative partner for digital image editing and creative design solutions. We are pleased that you have chosen our services to bring your projects to life with the highest efficiency and cost-effectiveness. Our web app, supported by the latest developments in artificial intelligence, offers you the possibility to generate custom backgrounds for your images with just a few clicks, thus realizing your creative visions.
            </p>
            <p>
                Our goal is not just to provide you with a tool, but to be an extension of your creative flair. We understand the challenges associated with image editing - the need for time, costs, and technical expertise. generatebg UG was founded with the vision of simplifying this process for you, our customer, so that you can focus on what's essential: your creativity and your business.
            </p>
            <p>
                The values of our company are deeply rooted in the aspiration to combine quality and user-friendliness. We are proud to offer a platform that:
            </p>
            <p className="text-indented">
                •	<b>Increases Efficiency</b>: Through automation and intelligent algorithms, we significantly accelerate your workflows.
            </p>
            <p className="text-indented">
                •	<b>Reduces Costs</b>: Our solutions are designed to provide you with the highest quality at a fraction of the cost of traditional image editing services.
            </p>
            <p className="text-indented">
                •	<b>User-Centric</b>: We focus on the intuitive usability of our web app, so you can achieve professional results without prior experience in image editing.
            </p>
            <p className="text-indented">
                •	<b>Innovation</b>: Continuous improvements and updates ensure that you always have access to the most advanced tools.
            </p>
            <p>
                By using our services, you agree to our General Terms and Conditions, which are intended to create a clear and fair usage environment for all our users. These terms and conditions reflect our commitment to service quality, data protection, and the continuous optimization of our services.
                We invite you to join our creative community and together push the boundaries of what's possible in digital image editing.
                <b>generatebg UG</b> – where your visions take shape.
            </p>
            <h3>§ 1 Scope of Application</h3>
            <p>
                (1) These General Terms and Conditions (hereinafter referred to as "GTC") apply to all contracts for the provision of digital services, especially image editing services
            </p>
            <br></br>
            <p>between</p>
            <br></br>
            <p>generatebg UG (haftungsbeschränkt)</p>
            <p>Freiligrathstr. 47</p>
            <p>50935 Cologne,</p>
            <p>Germany</p>
            <p>(mehr Informationen im Impressum: <a href={`${process.env.REACT_APP_CLIENT_URL}/imprint`}>({process.env.REACT_APP_CLIENT_URL}/imprint)</a>)</p>
            <br></br>
            <p>(hereinafter referred to as "provider", "we", or "us")</p>
            <br></br>
            <p>and you </p>
            <br></br>
            <p>(hereinafter referred to as "partner", "your" or "you").</p>
            <p>(2) The GTC apply regardless of whether you are a consumer or an entrepreneur.</p>
            <p>
                (3) You are a “consumer” if you are a natural person entering into a contract with us for purposes predominantly outside your trade, business, craft or profession.
            </p>
            <p>
                (4)You are an “entrepreneur” if you are a natural person or act on behalf of a legal entity or a legally capable partnership, entering into a contract with us in exercise of your commercial, freelance, independent, public-law or non-profit professional activity.
            </p>
            <p>
                (5) These GTC apply on all our platforms. “Platforms” include all our sales and operational channels and services, especially our websites or apps and our profiles on websites or apps of our partners.
            </p>
            <p>
                (6) The version of the GTC valid at the time of the agreement of the contract shall apply. Deviating GTC are not accepted. This also applies if we have not expressly objected to their inclusion. Different provisions may apply as far as determined otherwise in these GTC. In cases where legal texts or documents are translated into a language other than German, the German legal texts or documents are legally binding and applicable - the translated legal texts or documents are for better understanding only.
            </p>
            <p>
                (7) All agreements made between you and us in connection with a service are derived particularly from an order or contract and the related attachments, our confirmation, our acceptance, and, where not regulated therein, from these GTC. These GTC also apply to future orders or contracts that you place during or after the term of the contract, unless other GTC have been included at that time.
            </p>
            <p>
                (8)  Except for written amendments and supplements to these GTC, electronically or digitally created documents or records are equated with written documents or records.
            </p>
            <p>
                (9) These GTC also apply to other contracts agreed between you and us, unless there are specific GTC related to the other type of contract, and clauses of these GTC can be substantively applied.
            </p>
            <h3>§ 2 Scope of Services</h3>
            <p>
                (1) We provide digital services in the field of image editing, particularly using artificial intelligence. We offer you the following services:
            </p>
            <p className="text-indented">
                •	Background removal from graphics (images) up to 5 MegaBytes in size and 2048 x 2048 pixels in resolution (allowed formats: .jpg, .jpeg, and .png).
            </p>
            <p className="text-indented">
                •	Background generation for graphics (allowed formats: .jpg, .jpeg, and .png) with the following pricing packages:
            </p>
            <p className="text-indented">
                <b>
                    Monthly Plans
                </b>
            </p>
            <p className="text-indented">-	10 images / month (monthly fee)</p>
            <p className="text-indented">-	30 images / month (monthly fee)</p>
            <p className="text-indented">-	125 images / month (monthly fee)</p>
            <p className="text-indented">-	250 images / month (monthly fee)</p>
            <p className="text-indented">-	500 images / month (monthly fee)</p>
            <p className="text-indented">
                <b>
                    Image To Go (one-time purchase plans)
                </b>
            </p>
            <p className="text-indented">-	4 Images to go (one-time purchase)</p>
            <p className="text-indented">-	10 Images to go (one-time purchase)</p>
            <p className="text-indented">-	50 Images to go (one-time purchase)</p>
            <p className="text-indented">-	100 Images to go (one-time purchase)</p>
            <p className="text-indented">-	200 Images to go (one-time purchase)</p>
            <p className="text-indented">-	500 Images to go (one-time purchase)</p>
            <p>
                (2) After signing / agreement on a valid contract, you will receive access to our services, including software, online databases, functions, operating systems, documentation, and all other components of our software (“Services”). You can use the Services via our platforms - especially via our mobile apps for iOS, Android, and all other mobile devices as well as websites. Any technical or documentation material provided in any form may only be passed on or reproduced with our written consent. Changes to the Services as well as error corrections are only permissible to the extent necessary for the intended use of the Services. A reverse translation (decompilation) of the program code into another form of presentation is prohibited. Notwithstanding this right of use, we retain all rights to the Services including the source code and all copies or partial copies. You agree not to make the Services accessible to third parties, either in their original form or as complete or partial copies, without our express written consent. This also applies in the event of a complete or partial sale or dissolution of your company.
            </p>
            <p>(3)  The scope of our service is determined in each individual case by:</p>
            <p className="text-indented">
                -	Your order or contract, regardless of the platform you place it on, especially in the case of an online order (e.g., the service description during the ordering or contracting process, on the website or the app) or in text form (e.g., the order or contract documents including attachments, whether in paper form or electronically via email)
            </p>
            <h3>§ 3 Compensation and Execution</h3>
            <p>(1) We receive compensation for our services as per:</p>
            <p className="text-indented">-	Your order or contract according to the section on the scope of services.</p>
            <p>
                (2) We commit to performing the contracted services carefully and conscientiously. We do not conduct legal review of the service or the result of the service - especially regarding the authorship of the images used in image editing - particularly, you remain responsible for complying with third-party rights or public legal regulations.
            </p>
            <p>
                (3)  In carrying out our activities, we are not subject to any instructions regarding the manner of providing our services, the location of service provision, or the timing of service provision, unless otherwise agreed in writing. We will independently determine our working hours in a manner that effectively achieves the purpose of the service.
            </p>
            <p>
                (4) We are entitled to engage third parties, especially subcontractors, for service provision.
            </p>
            <p>
                (5) When a service is provided using "Artificial Intelligence" or AI tools such as Open-AI GPT-3, Open-AI GPT-4, Midjourney, Stable Diffusion, etc., we assume no liability for the accuracy and completeness of the results achieved, or for any legal infringements resulting from the use of such tools - especially for the protection of third-party rights such as data protection, copyright, or trademark rights. The provision of such services is exclusively through the AI tool, and neither the process nor the result is reviewed by us. You are responsible for the legal and content review.
            </p>
            <p>
                (6)  If a service is provided free of charge, we assume no liability for the accuracy and completeness of the results achieved - except for damages to life, body, or health and according to the Product Liability Act (Produkthaftungsgesetz). The content review is your responsibility.
            </p>
            <h3>§ 4 Conclusion of Contract</h3>
            <p>
                (1) When we offer our services on our platforms and third-party platforms (especially the Android and Apple App Stores), it does not constitute a binding offer to conclude a contract. The presentation is merely a non-binding showcase.
            </p>
            <p>
                (2)  Our offers and estimates are non-binding. Errors in cost estimates and offers can be corrected before acceptance of the order.
            </p>
            <p>
                (3) You can place a legally binding order or contract in any manner offered by our platforms or as we suggest in individual cases. In particular, you can do so as follows:
            </p>
            <p className="text-indented">
                -	By clicking an order or contract button, namely, the "Subscribe Now" or "Buy Now" button, on our platforms, especially on our websites and apps and our profiles on third-party websites and apps.
            </p>
            <p className="text-indented">
                -	By sending us filled-out order or contract documents through any common communication channel such as email, mail, fax, or our social media profiles, or by responding affirmatively to a contract conclusion offer sent to you via one of the aforementioned communication channels.
            </p>
            <p className="text-indented">
                -	By handing over filled-out order or contract documents – for example, in our or your premises or sales areas, at trade fairs, information or promotional events, or on any other occasion.
            </p>
            <p>
                (4) By placing an order or contract, you also agree to these Terms and Conditions and data processing according to our privacy policy. If you download the app from third-party platforms (such as Apple App Store, Google Play, or Amazon Appstore), the contractual terms and conditions and privacy policies of the respective third-party apply, to the extent that there is content overlap – especially regarding payment terms, right of withdrawal, provisions concerning user accounts and registration, privacy policies, the End User License Agreement for Licensed Applications (EULA), or the availability of services.
            </p>
            <p>
                (5) You are bound to the order or contract for a period of 2 weeks after placing the order or contract.
            </p>
            <p>
                (6) We can confirm the receipt of the submitted order or contract:
            </p>
            <p className="text-indented">
                -   via email to the email address you provided or used, or via message within our platforms, especially within our websites and apps and our profiles on third-party websites and apps
            </p>
            <p className="text-indented">
                -    with a confirmation letter by mail or by handover
            </p>
            <p>
                The confirmation does not constitute a binding acceptance of the order or contract unless it also declares acceptance along with the confirmation of receipt.
            </p>
            <p>
                (7) We generally confirm orders or contracts ourselves. However, a third party may issue a confirmation on our behalf if you have ordered or contracted our service on third-party platforms – especially a third-party website or app – where we maintain a profile.
            </p>
            <p>
                (8) A contract between you and us is only concluded/agreed upon when we
            </p>
            <p className="text-indented">-	explicitly accept the order or your contract. </p>
            <p className="text-indented">-	begin providing the service.</p>
            <p className="text-indented">-	issue an invoice.</p>
            <p className="text-indented">-	provide the service – wholly or partially.</p>
            <p className="text-indented">
                -	Set up an account for you on our platforms – whether through registration, the transfer of your data from a third-party provider, or in any other way.
            </p>
            <p>Acceptance can occur simultaneously with the confirmation.</p>
            <p>
                (9)  If there are multiple contract partners or if the contract partner is a partnership, they or the partners of the partnership are liable for our claims as joint debtors. We are entitled to rely on the instructions and information of an individual contract partner or partner of a partnership in the execution of the contract, particularly without consulting with the other contract partners or partners of a partnership, unless another party objects in writing. An objection entitles us to terminate the contract based on and with the consequences of a lack of cooperation.
            </p>
            <h3>§ 5 Registration, Account</h3>
            <p>
                (1)  If services can be accessed on our platforms without an account, by using our platforms you already offer to enter into a contract for the duration of the use of a platform according to these Terms and Conditions, which we accept by providing the service. In this case, by using our platforms, you offer to enter into a contract for the duration of the use of a platform according to these Terms and Conditions, which we accept by providing the service.
            </p>
            <p>
                (2) If one of our services requires setting up an account on our platforms, you can obtain it by registering.
            </p>
            <p>
                (3) The provision of data requested at registration is mandatory. You assure its completeness and accuracy. Registration can be done by entering your data on our platforms.
            </p>
            <p>
                (4) The following requirements apply for registration:
            </p>
            <p className="text-indented">
                -	Consumers are fully business-capable natural persons over 18 years of age or minors with limited business capacity acting with the consent of their parents.
            </p>
            <p className="text-indented">
                -	Companies as natural persons meet the requirements of a consumer.
            </p>
            <p className="text-indented">
                -	Entrepreneurs as partnerships or corporations as well as any other legal entities, associations, or communities are legally capable and have an authorized representative. Providing a PO Box is insufficient.
            </p>
            <p>
                (5) There is no entitlement to registration. We have the right to refuse a registration. Upon completion of the registration, a contractual relationship is established between you and us.
            </p>
            <p>
                (6) Upon registration, you receive an account containing all necessary data for its use. The account may only be used by you personally; in particular, you must not allow third parties to use the account or transfer the account to third parties (account sharing). The password can be changed at any time. Multiple accounts per person are permissible. The account exists until the termination takes effect. If you download the app from third-party platforms (such as Apple App Store, Google Play, or Amazon Appstore), the contractual terms and conditions and privacy policies of the respective third-party apply, to the extent that there is content overlap – especially regarding payment terms, right of withdrawal, provisions concerning user accounts and registration, privacy policies, the End User License Agreement for Licensed Applications (EULA), or the availability of the services.
            </p>
            <p>
                (7) You are responsible for the content and quality of all information provided. You assure that it is correct and complete.
            </p>
            <p>
                (8) You must not endanger the secure operation of our platforms. All actions that could harass other users of the platforms or go beyond the intended use of our platforms must be refrained from. You are especially obliged to refrain from the following:
            </p>
            <p className="text-indented">
                -	Uploading or sending files that contain a virus or other malicious software, or making other interventions that could impair the functionality or accessibility of the platforms or could alter or delete content,
            </p>
            <p className="text-indented">
                -	Uploading or sending any form of advertising, especially email advertising, SMS advertising, chain letters, or other harassing content,
            </p>
            <p className="text-indented">
                -	Subjecting the platforms to excessive strain or in any other way disrupting or endangering their functioning,
            </p>
            <p className="text-indented">
                -	Using crawlers, spiders, scrapers, or other automated mechanisms to access the platforms and collect content without written consent,
            </p>
            <p className="text-indented">
                -	Collecting or using information such as email addresses or phone numbers of other users without their prior consent,
            </p>
            <p className="text-indented">
                -	Duplicating, making publicly available, distributing, editing, or using content from the platforms or third parties without prior consent from us or the third parties, in a manner that goes beyond the intended use.
            </p>
            <p>
                (9) We reserve the right to take any action with respect to your account without stating reasons. In particular, we are entitled to request a statement from you, to temporarily block the account, issue a warning, or permanently block or delete the account. Furthermore, we explicitly reserve the right to assert civil and criminal claims. The sanctions do not affect the obligation to pay for services already agreed upon, especially if the service has already been (partially) provided.
            </p>
            <p>
                (10)  If the app was downloaded from third-party platforms, the contractual terms and conditions and privacy policies of the respective third-party provider may apply to registration and account, to the extent that there is content overlap.
            </p>
            <h3>§ 6 Prices, Payment, Default, Payment Terms, Offsetting, Right of Retention</h3>
            <p>
                (1) For our chargeable services on our platforms: The prices we state are – unless otherwise presented or agreed upon in individual cases – gross prices including value-added tax (VAT).
            </p>
            <p>
                (2) Our fees are due – unless a different agreement exists between you and us – upon conclusion of the contract and before the respective service is provided. Payment is to be made within 2 weeks of the dispatch of our invoice (invoice date) at the latest. Failure to pay results in default. In the event of payment default, we are entitled to claim default interest and further damages according to statutory provisions. The default interest rate for consumers is 5 percentage points above the base interest rate per year according to § 288 BGB (German Civil Code); for entrepreneurs, it is 9 percentage points above the base interest rate per year according to § 288 BGB.
            </p>
            <p>
                (3) We offer you the use of various payment services and options. You may use any payment method provided by us, in particular:
            </p>
            <p className="text-indented">-	transfer to an account specified by us,</p>
            <p className="text-indented">-	grant us a direct debit authorization or SEPA direct debit mandate,</p>
            <p className="text-indented">-	pay us via EC/Maestro card or credit card,</p>
            <p className="text-indented">-	pay us through a third-party platform (e.g., Apple App Store, Google Play, or Amazon Appstore),</p>
            <p className="text-indented">-	or pay us through a payment service provider specified by us (e.g., Stripe, PayPal, etc.),</p>
            <p>
                in each case, as long as we offer the respective payment option. We reserve the right to exclude or subsequently add payment options individually or generally.
            </p>
            <p>
                (4) You utilize the payment service of a payment service provider by clicking on the button of the payment service provider during the ordering process of services. You will be directed to the respective page of the payment service provider. You utilize the payment service of a third-party platform such as Apple App Store, Google Play, or Amazon Appstore by downloading our app through it. We only provide access to the page of the respective payment service provider or platform but do not become a contractual party. Usually, to use the payment services of a payment service provider or the platform, it is necessary to enter into a contractual relationship with the respective payment service provider. The respective contractual terms, general terms and conditions, and privacy policies apply.
            </p>
            <p>
                (5)  In the case of a granted direct debit authorization, a SEPA direct debit mandate, or payment by EC/Maestro card or credit card, we will initiate the charge to your account at the earliest on the due date. A granted direct debit authorization is valid until revoked for further orders.
            </p>
            <p>
                (6) You are not entitled to offset against our claims unless your counterclaims are legally established or undisputed, or when you assert defect claims or counterclaims from the same contractual relationship.
            </p>
            <p>
                (7) You may only exercise a right of retention if your counterclaim arises from the same contractual relationship and is legally established or undisputed.
            </p>
            <p>
                (8) In the event that payment for one of our claims from one or more contracts is not made on time, we are entitled to commission a debt collection agency (e.g., Creditreform) to collect the due claim. By signing the contract, you consent to us transmitting the data and information necessary for collecting the claim to the debt collection agency (e.g., Creditreform), and authorize the debt collection agency (e.g., Creditreform) to store and process the data. In particular, the name and address, contract date, as well as invoice number, invoice amount, and due date will be transmitted.
            </p>
            <p>
                (9) If the app was downloaded from third-party platforms, the contractual terms, general terms and conditions, and privacy policies of the respective third-party provider may apply, to the extent that there is content overlap.
            </p>
            <h3>§ 7 Duration of Contract and Termination of Contract</h3>
            <p>
                (1)The duration of the contract begins – unless otherwise agreed electronically or in writing – with the dispatch of the order or booking confirmation from the provider to the partner.
            </p>
            <p>
                (2) The agreed contract is in effect – unless otherwise agreed electronically or in writing – for an indefinite period.
            </p>
            <p>
                (3)  In the case of a minimum contract term, the contract extends for an indefinite period after the minimum term if it is not terminated in advance by either party with a notice period of one day before the respective end of the term. After the extension to an indefinite period, the contract can be terminated with a notice period of one day at the end of a month.
            </p>
            <p>
                (4)  If we terminate the contract for an important reason, you are obliged to reimburse us for the costs and fees that have demonstrably been incurred up to the point of termination. The services provided by us until then are to be billed proportionally, as far as possible, otherwise a complete billing will be done.
            </p>
            <p>
                (5) The termination can be made in the account or by email.
            </p>
            <p>
                (6) If at the time of receipt of the termination notice services owed by us have not yet been provided, we are released from our obligation to perform unless the parties agree in writing on the scope of the services still to be provided.
            </p>
            <p>
                (7)  For one-time "Image To Go" plans, the contract automatically ends after the agreed service has been provided. In this case, a termination is not necessary. If the agreed service is not provided within a specified period, the partner has the right to terminate the contract without observing a notice period.
            </p>
            <h3>§ 8 Grant of Rights to Work Results</h3>
            <p>
                (1) We acknowledge that, upon full payment of our fee, all rights to all work results (hereinafter referred to as “Protected Rights”), which exist from their use and/or are embodied in them, including all conceivable legal positions on ideas, designs, and creations, shall transfer to you completely and without restriction at the time of their creation. You hereby accept this transfer.
            </p>
            <p>
                (2) In the event that the transfer of rights provided for in paragraph (1) cannot be effectively carried out according to mandatory applicable law, we hereby grant you a comprehensive, exclusive, spatially and temporally unlimited right to use the work results or Protected Rights, valid for all types of use. To the extent possible under applicable law, we hereby unconditionally and irrevocably waive all moral rights in the work results that have already been created or will be created in the future, including the right to be named as the author and the right against distortion.
            </p>
            <p>
                (3) The transfer or grant of rights particularly includes the right to use the created work results for your own or third-party purposes in any way worldwide and for an unlimited period, both for a fee and free of charge, including use in and on products, whether own or those for third parties, in all types of use. It also includes the right to reproduce and/or publish the work results. The rights also include the right to edit, i.e., the authorization to further edit the work results or have them further edited by third parties.
            </p>
            <p>
                (4) The grant of rights also applies to new types of use that become known in the future.
            </p>
            <p>
                (5) You can transfer the granted rights in whole or in part to third parties without the need for a written agreement.
            </p>
            <p>
                (6) The aforementioned transfer of rights and grant of usage rights occurs only after the full payment of our fee.
            </p>
            <p>
                (7)  We may use the work results within the scope of our business operations and freely utilize them after removing customer-specific details.
            </p>
            <h3>§ 9 Right of Withdrawal</h3>
            <p>
                (1)  If you are an entrepreneur within the meaning of § 14 BGB (German Civil Code), the right of withdrawal does not apply. For consumers, the following applies:
            </p>
            <h3 className="title-left">Withdrawal Instructions</h3>
            <h3>Right of Withdrawal</h3>
            <p>You have the right to withdraw from this contract within fourteen days without giving any reason. </p>
            <p>The withdrawal period is fourteen days from the day of the agreement of the contract.</p>
            <p>
                To exercise your right of withdrawal, you must inform us (generatebg UG (haftungsbeschränkt), Freiligrathstr. 47, 50935 Cologne, Email address: support@generatebg.com) by means of a clear declaration (e.g., a letter sent by post, fax, or email) of your decision to withdraw from this contract. You may use the attached model withdrawal form, but it is not mandatory.
            </p>
            <p>
                To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your exercise of the right of withdrawal before the withdrawal period has expired.
            </p>
            <h3>Consequences of Withdrawal</h3>
            <p>
                If you withdraw from this contract, we shall reimburse to you all payments received from you, including the costs of delivery (with the exception of the additional costs arising if you chose a type of delivery other than the least expensive type of standard delivery offered by us), without undue delay and not later than fourteen days from the day on which we are informed about your decision to withdraw from this contract. We will use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you; in no event will you be charged any fees for this repayment.
            </p>
            <p>
                If you have requested that the services begin during the withdrawal period, you shall pay us a reasonable amount corresponding to the proportion of the services already provided by the time you inform us of the exercise of the right of withdrawal with respect to this contract, in comparison to the full coverage of the services provided for in the contract.
            </p>
            <h3 className="title-left">Sample Withdrawal Form</h3>
            <p>
                (If you want to withdraw from the contract, please fill out this form and send it back.)
            </p>
            <p>
                — To (generatebg UG (haftungsbeschränkt), Freiligrathstr. 47, 50935 Cologne, E-Mail address: support@generatebg.com):
            </p>
            <p>
                —  I/We (*) hereby revoke the contract agreed by me/us (*) for the provision of the following service (*)
            </p>
            <p>
                — Ordered on (*) / received on (*)
            </p>
            <p>
                — Name of the consumer(s)
            </p>
            <p>
                — Address of the consumer(s)
            </p>
            <p>
                — Signature of the consumer(s) (only if communicated on paper)
            </p>
            <p>
                — Date _______________ (*)
            </p>
            <p className="text-center"><b>-    END OF THIS SAMPLE WITHDRAWAL FORM -</b></p>
            <p>
                (2) The right of withdrawal does not exist, is extinguished, or can be excluded if there is a legally regulated case, an appropriate court decision, or another legal reason. Legally regulated cases arise in particular from §§ 312 g or 356 BGB (German Civil Code).
            </p>
            <p>
                (3) The right of withdrawal also expires for a contract on the supply of digital content not on a tangible medium if the provider has begun with the execution of the contract after the consumer
            </p>
            <p className="text-indented">
                1. has explicitly agreed that the provider begins with the execution of the contract before the end of the withdrawal period, and
            </p>
            <p className="text-indented">
                2. has confirmed his knowledge that he loses his right of withdrawal by his agreement at the beginning of the execution of the contract.
            </p>
            <p>
                (4) The right of withdrawal particularly expires for a contract for the provision of services also if the provider has completely provided the service and only started to perform the service after the consumer has given their explicit consent and simultaneously confirmed their knowledge that they lose their right of withdrawal upon complete fulfillment of the contract by the entrepreneur. For a contract concluded outside business premises, the consumer's consent must be transmitted on a durable medium. For a contract for the provision of financial services, the right of withdrawal expires differently from sentence 1 if the contract is fully performed by both sides at the express wish of the consumer before the consumer exercises his right of withdrawal.
            </p>
            <p>
                (5) If the app was downloaded from third-party platforms (such as App Store or Google Play Store), the contractual terms of the platform apply with respect to withdrawal. The withdrawal is made towards the platform, as the platforms act as an intermediary and your direct contractual partner.
            </p>
            <h3>§ 10 Obligation to Cooperate</h3>
            <p>
                (1) You will support us in providing our contractual services through reasonable cooperation actions. For example, you will promptly provide us with the necessary information, data, circumstances, conditions; supply documents, materials, items, or access needed for the performance of the service; promptly issue instructions and approvals to us and appoint a competent contact person who will not be replaced. Work results will be reviewed by you. In particular, you are to check them for legal compliance, especially for any violations of third-party rights. We are not obligated to conduct such a review.
            </p>
            <p>
                (2) To the extent that you are not authorized to communicate, provide, or make available as per paragraph 1, for example, due to violations of competition, data protection, trademark, and sign law, or any violations of third-party rights or official regulations, this also constitutes a lack of cooperation. You assure your authorization for the corresponding actions. We will not carry out a corresponding review. You will indemnify us upon first demand from any third-party claims that arise due to your lack of authorization and compensate us for any damage incurred due to the third-party claim, including any court and attorney fees incurred for legal defense. Otherwise, the statutory provisions apply.
            </p>
            <p>
                (3) Lack of, incomplete, damaging, or law-violating cooperation – for example, through communication or provision of incomplete, incorrect, or not legally usable information, data, materials, or documents – entitles us to terminate the contract, in the case of a contract with an entrepreneur, without affecting the agreed remuneration.
            </p>
            <p>
                (4) If we incur damage due to faulty cooperation, a claim for damages exists. In this case, you will also indemnify us from all claims of third parties that are asserted in connection with cooperation actions carried out by you at least with gross negligence.
            </p>
            <h3>§ 11 Service Delivery Deadline Period, Force Majeure, Performance Obstacles</h3>
            <p>
                (1) We are not bound by deadlines and dates in the execution of the service, unless agreed upon in individual cases. Service delivery deadline dates/times require written form.
            </p>
            <p>
                (2)  Insofar as we are prevented from performing the service due to the occurrence of unforeseeable, extraordinary events beyond our control and which could not be avoided with the due diligence reasonable in the circumstances of the individual case – regardless of whether it occurs at our end or yours – the service delivery period applicable to us is extended to an extent that makes the proper fulfillment of the service obligation possible. If performance becomes impossible, we are released from the obligation to perform, without any right of withdrawal or compensation for you. If similar obstacles occur at your end, the same legal consequences apply to your obligation to accept the service. The contracting parties will inform each other immediately of such obstacles.
            </p>
            <p>
                (3) Performance obstacles that are not attributable to the risk area of a contracting party release the parties from their performance obligations for the duration of the disturbance and the extent of its effect.
            </p>
            <h3>§ 12 Communication</h3>
            <p>
                (1) To ensure fast and easy communication, communication generally takes place via your account on our platforms. You consent to receiving information via email, if available, your account on our platforms, by postal mail, or through other means.
            </p>
            <p>
                (2) Sending and communication are at your risk. We are not responsible for and do not accept liability for disruptions in the internet's communication networks, server and software problems of third parties, or issues with postal or delivery services.
            </p>
            <h3>§ 13 Technical Availability, Data, Functionality, and Content</h3>
            <p>
                (1) The platforms are accessible 24 hours a day, 7 days a week, except in cases of force majeure or events outside our control, and subject to outages and maintenance works necessary for operation. We strive with great care for the highest possible availability. Availability depends, among other things, on your technical equipment. Availability interruptions can occur due to necessary maintenance and security work, or unforeseen events not within our control.
            </p>
            <p>
                (2) We are not liable for the loss of your data or resulting damages, provided that the damages would not have occurred with regular and complete backup of data on your part.
            </p>
            <p>
                (3) We can change any functionality, appearance, structure, or content of our platforms without obtaining your consent.
            </p>
            <p>
                (4) We are entitled to block or modify all contents – including user-generated content.
            </p>
            <h3>§ 14 Grant of Rights to Data</h3>
            <p>
                (1) You commit not to distribute via the platforms any texts, images, videos, audio files, and/or other content (“Files”) that violate applicable law, good morals, and/or these Terms and Conditions. You particularly commit to respecting the rights of third parties, such as copyrights, trademark rights, patent and utility model rights, design rights, database rights, and any other industrial property rights (hereinafter referred to as “Protected Rights”).
            </p>
            <p>
                (2) You hereby grant us a comprehensive, exclusive, spatially and temporally unlimited right to use the Files needed for the performance of the service, which you publish on our platforms or upload to our platform or user account for service performance, or transmit to us in any other way, however, limited to the type of use for the execution of our own services and our own advertising.
            </p>
            <p>
                (3) Insofar as we create Files for you, all copyrights and user rights remain with us.
            </p>
            <p>
                (4) If the app was downloaded from third-party platforms, the contractual terms, general terms and conditions, and privacy policies of the respective third-party provider may apply, to the extent that there is content overlap.
            </p>
            <h3>§ 15 Our Rights to Our Platforms</h3>
            <p>
                (1) You agree that the platforms and all related applications are database works and databases within the meaning of §§ 4 para. 2, 87a para. 1 UrhG (German Copyright Act), of which we are the legal owners. All associated applications are protected under §§ 69a ff. UrhG. They are protected by copyright.
            </p>
            <p>
                (2) The rights to all other elements of our platforms, in particular the usage and performance protection rights (ancilliary copyrights) to the content and documents posted by us or acquired through the grant of rights, also belong exclusively to us. In particular, trademarks, other distinguishing marks, company logos, protective notices, copyright notices, or other elements serving to identify our platforms must not be removed or altered. This also applies to printouts.
            </p>
            <h3>§ 16 Modification of Services</h3>
            <p>
                We reserve the right, in whole or in part, at any time, temporarily or permanently, to discontinue, modify, or restrict access to software, online databases, functions, operating systems, documentation, and all other components of our software and their functionality – as far as legally permissible, even without prior notice. In particular, we reserve the right to change, deactivate, convert free components into chargeable ones, cease supporting certain functions, or suspend compatibility (e.g., with certain types of devices or operating systems) for features of our services (such as design, layout, categories, structure, or availability).
            </p>
            <h3>§ 17 End User License Agreement (EULA)</h3>
            <p>
                (1) We grant you a personal, non-exclusive, revocable, non-transferable, and worldwide right to use the platforms - particularly any software features on the website or apps -, their contents, services, other functions, and all updates. This is granted exclusively for your own needs and within the scope of using the platforms and their services, excluding any other purposes.
            </p>
            <p>
                (2) Our digital products (especially apps, software) are licensed to you, not sold.
            </p>
            <p>
                (3) The license does not grant you the right to use the content. In particular, it is forbidden to:
            </p>
            <p className="text-indented">
                -	Adapt, modify, translate, edit, reverse engineer, disassemble, transcode, or reproduce or model the platforms or any part thereof through reverse engineering;
            </p>
            <p className="text-indented">
                -	Export the platforms, their contents, services, other functions, or updates, combine them entirely or partially with other software programs, or reproduce them entirely or partially, by any means and in any form, permanently or temporarily;
            </p>
            <p className="text-indented">
                -	Extract or reuse the contents of databases resulting from the platforms;
            </p>
            <p className="text-indented">
                -	Create works derived from the licensed platform;
            </p>
            <p className="text-indented">
                -	Use processes or software designed to copy the platforms, their contents, services, other functions, or updates without our consent;
            </p>
            <p className="text-indented">
                -	Set up systems capable of hacking the platforms.
            </p>
            <p className="text-indented">
                -	Offer or provide our services to third parties without our consent.
            </p>
            <p>
                (4) Violation of these prohibitions may result in criminal liability and the obligation to pay damages.
            </p>
            <p>
                (5) If the app was downloaded from third-party platforms, the contractual terms, general terms and conditions, and privacy policies of the respective third-party provider may apply, to the extent that there is content overlap.
            </p>
            <h3>§ 18  Copyright and Other Rights</h3>
            <p>
                We hold copyright or other rights to all images, films, texts, and other contents protected by copyright or similar rights, particularly intellectual property rights, published on our website, our profiles on other websites, our social media profiles, and all our platforms. The use of these images, films, texts, and other rights without our written consent is not permitted.
            </p>
            <h3>§ 19 Data Protection and Data Security</h3>
            <p>
                (1) We collect personal data from you and possibly other data transmitted by you or obtained by us in the course of contract fulfillment for the purpose of executing the contract and fulfilling contractual and pre-contractual obligations. The collection and processing of data are necessary for the fulfillment of the contract and are based on Article 6 (1) (b) GDPR. We process it according to the obligations of the GDPR. According to § 5 para. 1 GDPR, personal data must essentially:
            </p>
            <p className="text-indented">
                (a) be processed lawfully, fairly, and in a manner that is transparent to the data subject (“lawfulness, fairness, transparency”);
            </p>
            <p className="text-indented">
                (b) be collected for specified, explicit, and legitimate purposes and not further processed in a manner that is incompatible with those purposes (“purpose limitation”);
            </p>
            <p className="text-indented">
                (c) be adequate, relevant, and limited to what is necessary for the purposes of processing (“data minimization”);
            </p>
            <p className="text-indented">
                (d) be accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, with regard to the purposes for which they are processed, are erased or rectified without delay (“accuracy”);
            </p>
            <p className="text-indented">
                (e) be stored in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed (“storage limitation”);
            </p>
            <p className="text-indented">
                (f) be processed in a manner that ensures appropriate security of the personal data, including protection against unauthorized or unlawful processing and against accidental loss, destruction, or damage, using appropriate technical and organizational measures (“integrity and confidentiality”).
            </p>
            <p>
                (2) Data is generally not transmitted to third parties unless there is a corresponding obligation or if contract execution or compliance with a legal deadline makes data transmission necessary. For example, data transfer may be necessary to perform a query required for contract execution through a third party on your behalf, to forward your data to a payment provider, or to use freelancers to contribute to fulfilling a service obligation to you. In these cases, the service providers will often have a contractual relationship with you, so they act on their own responsibility.
            </p>
            <p>
                (3)  As soon as data is no longer necessary for the purpose of processing and if there is no legal retention obligation, it will be deleted by us. We store your data in the initiation and execution of our contractual relationship. It may also be necessary to retain data after the termination of our contract. For example, invoice data (billing documents) must be kept for 10 years according to § 147 of the German Fiscal Code (Abgabenordnung). As long as a service provider executing services for us also has a contract for performing your service with us, we remain obligated to retain the data according to the agreed retention periods.
            </p>
            <p>
                (4) You have the right to information, data portability, deletion, correction, restriction, or blocking of your personal data. In particular, you have a right to free information about all personal data concerning you.
            </p>
            <p>
                Your request can be addressed to us. Additionally, you have access to corresponding administrative or judicial remedies or the option to contact a supervisory authority.
            </p>
            <p>
                (5) If the app was downloaded from third-party platforms, the contractual terms, general terms and conditions, and privacy policies of the respective third-party provider may apply, to the extent that there is content overlap.
            </p>
            <h3>§ 20 Liability, Indemnification, and Reimbursement of Expenses</h3>
            <p>
                (1) We are liable to you in all cases of contractual and non-contractual liability for intent and gross negligence in accordance with the statutory provisions for damages or reimbursement of futile expenses.
            </p>
            <p>
                (2) In other cases, we are liable – unless otherwise stipulated in paragraph 3 – only for the breach of a contractual obligation, the fulfillment of which enables the proper execution of the contract in the first place and on the observance of which you as a contractual partner may regularly rely (so-called cardinal obligation), limited to compensation for the foreseeable and typical damage. In all other cases, our liability is excluded, subject to the provision in paragraph 3.
            </p>
            <p>
                (3) Our liability for damages resulting from injury to life, body, or health and under the Product Liability Act remains unaffected by the foregoing and all other limitations of liability, warranties, or responsibility exclusions in these terms and conditions and between us.
            </p>
            <p>
                (4) You indemnify us from any third-party claims made against us and/or our agents due to possible culpable violations of the partner against their obligations – particularly from these terms and conditions – upon first request. You reimburse us for any damage incurred due to the third-party claim, including any court and attorney fees incurred for legal defense. Otherwise, the statutory provisions apply.
            </p>
            <p>
                (5) We are entitled to reimbursement of expenses that we were entitled to consider necessary under the circumstances and were not responsible for, particularly any expenses for the protection of the contractual object, as well as a customary, reasonable remuneration.
            </p>
            <h3>§ 21 Service Location, Applicable Law, Contract Language, and Jurisdiction</h3>
            <p>
                (1) Cologne, Germany, is agreed upon as the service location for all services arising from the contract.
            </p>
            <p>
                (2) The law of the Federal Republic of Germany applies, excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG). If both you and we are merchants at the time of the conclusion of the contract and you have your seat in Germany at that time, our seat in Cologne is the exclusive place of jurisdiction. Otherwise, the applicable statutory provisions apply for local and international jurisdiction.
            </p>
            <p>
                (3) The contract language is German, unless otherwise agreed in writing. Any translated legal texts or documents are for better understanding only. In particular, in relation to a contractual agreement as well as these terms and conditions, the privacy policy, or any other legal texts or documents, the German versions are legally binding; this applies especially in cases of deviations or interpretive differences between such legal texts or documents.
            </p>
            <p>
                (4) Regarding disputes with consumers, the European Commission has created an Internet platform for online dispute resolution – the Alternative Dispute Resolution under the ODR regulation and § 36 VSBG. This platform serves as a contact point for the out-of-court resolution of disputes concerning contractual obligations arising from online sales contracts. Further information is available at the following link: <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>
            </p>
            <p>
                Participation in a dispute resolution procedure before a consumer arbitration board is not mandatory and is not undertaken by us.
            </p>
            <h3>§ 22 Final Provisions</h3>
            <p>
                (1) Amendments and supplements to these General Terms and Conditions are made in writing, and we reserve the right to make such changes. Changes presuppose that you are not unreasonably disadvantaged, there is no breach of trust and faith, and the change is not objected to. In the event of a change, a notification will be made via one of the communication channels – especially by email – 2 months before it takes effect. The change becomes effective if it is not objected to within this period – thereafter the amended terms and conditions will be valid.
            </p>
            <p>
                (2) The right to assign this contract to another company is reserved. It becomes valid 1 month after sending an assignment notification via one of our communication channels – especially by email – to you. In the event of an assignment, you have the right to terminate the contract, which is valid for 1 month after receipt of the assignment notification. All rights granted to us are also deemed granted to our legal successors.
            </p>
            <p>
                (3) In the event of the invalidity of individual provisions of these General Terms and Conditions, the legal validity of the remaining provisions is not affected. The invalid provision will be replaced by a valid provision that comes closest to the intended economic purpose.
            </p>
        </div>
    );
};

export default TermsEn;