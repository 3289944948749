import React from 'react';
import '../../styles/LegalStyles.scss';

const TermsDe = () => {
    return (
        <div className="legal-doc">
            <h1>Allgemeine Geschäftsbedingungen generatebg UG für Auftraggeber</h1>
            {/* Add your terms and conditions here in JSX format */}
            <p>Unsere AGB gelten bei der Inanspruchnahme der Leistungen von generatebg UG auf dem
                Gebiet der auf künstlicher Intelligenz basierten digitalen Bildbearbeitung als vereinbart.
                Insbesondere enthalten sie Ihre Rechte und Pflichten als Nutzer unserer Plattformen sowie die wichtigsten
                Datenschutzbestimmungen.
            </p>
            {/* More paragraphs and formatting as needed */}
            <br></br>
            <h3>Präambel generatebg UG</h3>
            <p>Herzlich willkommen bei <b>generatebg UG</b>, Ihrem innovativen Partner für digitale
                Bildbearbeitung und kreative Gestaltungslösungen. Wir freuen uns, dass Sie sich für
                unsere Dienste entschieden haben, um Ihre Projekte mit höchster Effizienz und kosteneffektiv
                zum Leben zu erwecken. Unsere Web-App, gestützt auf die neuesten Entwicklungen im Bereich der
                Künstlichen Intelligenz, bietet Ihnen die Möglichkeit, mit wenigen Klicks maßgeschneiderte
                Hintergründe für Ihre Bilder zu erstellen und damit Ihre kreativen Visionen zu verwirklichen.
            </p>
            <p>
                Unser Ziel ist es, Ihnen nicht nur ein Werkzeug in die Hand zu geben, sondern eine Erweiterung
                Ihrer kreativen Ader zu sein. Wir verstehen die Herausforderungen, die mit der Bildbearbeitung
                einhergehen – der Bedarf an Zeit, die Kosten und die technische Expertise. generatebg UG wurde
                mit der Vision gegründet, diesen Prozess für Sie als unseren Kunden zu vereinfachen, sodass Sie
                sich auf das Wesentliche konzentrieren können: Ihre Kreativität und Ihr Geschäft.
            </p>
            <p>
                Die Werte unserer Firma sind tief in dem Bestreben verankert, Qualität und Benutzerfreundlichkeit zu
                vereinen. Wir sind stolz darauf, eine Plattform anzubieten, die:
            </p>
            <p className="text-indented">
                •	<b>Effizienzsteigerung</b>: Durch Automatisierung und intelligente Algorithmen ermöglichen wir eine deutliche Beschleunigung Ihrer Arbeitsabläufe.
            </p>
            <p className="text-indented">
                •	<b>Kostenreduktion</b>: Unsere Lösungen sind darauf ausgerichtet, Ihnen höchste Qualität zu einem Bruchteil der Kosten herkömmlicher Bildbearbeitungsdienste zu bieten.
            </p>
            <p className="text-indented">
                •	<b>Benutzerzentrierung</b>: Wir setzen auf intuitive Bedienbarkeit unserer Web-App, damit Sie ohne vorherige Erfahrung im Bereich der Bildbearbeitung professionelle Ergebnisse erzielen können.
            </p>
            <p className="text-indented">
                •	<b>Innovation</b>: Kontinuierliche Verbesserungen und Updates gewährleisten, dass Sie stets Zugang zu den fortschrittlichsten Tools haben.
            </p>
            <p>
                Durch die Nutzung unserer Dienste stimmen Sie unseren Allgemeinen Geschäftsbedingungen zu, die dazu dienen, eine klare und faire Nutzungsumgebung für alle unsere Nutzer zu schaffen. Diese AGBs spiegeln unser Engagement für Servicequalität, Datenschutz und die stetige Optimierung unserer Dienste wider.
                Wir laden Sie ein, Teil unserer kreativen Gemeinschaft zu werden und gemeinsam die Grenzen des Möglichen in der digitalen Bildbearbeitung zu erweitern.
                <b> generatebg UG</b> – wo Ihre Visionen Gestalt annehmen.
            </p>
            <h3>§ 1 Geltungsbereich</h3>
            <p>
                (1) Diese Allgemeinen Geschäftsbedingungen (im Folgenden: „AGB“) gelten für alle
                Verträge über die Erbringung digitaler Dienstleistungen, insbesondere von Bildbearbeitungsleistungen
            </p>
            <br></br>
            <p>zwischen</p>
            <br></br>
            <p>generatebg UG (haftungsbeschränkt)</p>
            <p>Freiligrathstr. 47</p>
            <p>50935 Köln,</p>
            <p>(mehr Informationen im Impressum: <a href={`${process.env.REACT_APP_CLIENT_URL}/imprint`}>({process.env.REACT_APP_CLIENT_URL}/imprint)</a>)</p>
            <br></br>
            <p>(nachfolgend „Verwender“, „Unternehmer“, „wir“, oder „uns“ genannt)</p>
            <br></br>
            <p>und Ihnen </p>
            <br></br>
            <p>(nachfolgend „Partner“, „Ihr“ oder „Sie“ genannt).</p>
            <p>(2) Die AGB gelten unabhängig davon, ob Sie Verbraucher oder Unternehmer sind. </p>
            <p>
                (3) “Verbraucher” sind Sie, wenn Sie eine natürliche Person sind, die einen Vertrag mit uns zu Zwecken abschließt, der überwiegend weder Ihrer gewerblichen noch Ihrer selbständigen, freiberuflichen, öffentlich-rechtlichen oder gemeinnützigen beruflichen Tätigkeit zugerechnet werden kann.
            </p>
            <p>
                (4) “Unternehmer”, sind Sie, wenn Sie eine natürliche Person sind oder einen Vertragsschluss für eine juristische Person oder eine rechtsfähige Personengesellschaft tätigen, die bei Abschluss des Vertrages mit uns in Ausübung ihrer gewerblichen, freiberuflichen, selbständigen, öffentlich-rechtlichen oder gemeinnützigen beruflichen Tätigkeit handelt.
            </p>
            <p>
                (5) Diese AGB gelten auf allen unseren Plattformen. “Plattformen” sind alle unsere Vertriebs- und Operationskanäle und -dienste. Insbesondere sind es unsere Webseiten oder Apps und unsere Profile auf Webseiten oder Apps unserer Partner.
            </p>
            <p>
                (6) Maßgebend ist die jeweils bei Abschluss des Vertrags gültige Fassung der AGB. Abweichende AGB werden nicht akzeptiert. Dies gilt auch, wenn wir der Einbeziehung nicht ausdrücklich widersprochen haben. Etwas anderes kann gelten, soweit in diesen AGB in Einzelfällen etwas anderes bestimmt ist. Soweit in eine andere Sprache als Deutsch übersetzte Rechtstexte oder Dokumente bestehen, sind die deutschen Rechtstexte oder Dokumente rechtlich verbindlich und damit anwendbar – die übersetzten Rechtstexte oder Dokumente dienen alleine zum besseren Verständnis.
            </p>
            <p>
                (7) Alle zwischen Ihnen und uns im Zusammenhang mit einer Leistung getroffenen Vereinbarungen ergeben sich insbesondere aus einer Bestellung bzw. Beauftragung sowie den dazugehörigen Anlagen, unserer Bestätigung, unserer Annahme sowie ergänzend, soweit dort nicht geregelt, aus diesen AGB. Diese AGB finden auch auf spätere Bestellungen bzw. Beauftragungen Anwendung, die Sie während oder nach Ablauf der Vertragslaufzeit abgeben, es sei denn, zu diesem Zeitpunkt sind andere AGB einbezogen worden.
            </p>
            <p>
                (8) Mit Ausnahme schriftlicher Änderungen und Ergänzungen dieser AGB werden elektronisch oder digital erstellte Dokumente oder Unterlagen schriftlichen Dokumenten oder Unterlagen gleichgestellt.
            </p>
            <p>
                (9) Diese AGB gelten auch für andere, zwischen Ihnen und uns geschlossenen Verträge, soweit keine speziellen, auf die andere Vertragsart bezogenen AGB vorliegen und Klauseln dieser AGB inhaltlich Anwendung finden können.
            </p>
            <h3>§ 2 Leistungsumfang</h3>
            <p>
                (1) Wir erbringen digitale Leistungen auf dem Gebiet der Bildbearbeitung, insbesondere unter Einsatz der künstlichen Intelligenz. Dabei bieten wir Ihnen folgende Leistungen:
            </p>
            <p className="text-indented">
                •	Hintergrundentfernung von Graphiken mit Größe bis 5 MegaByte und Auflösung von 2048 x 2048 Pixel (erlaubte Formate: .jpg, .jpeg, und .png).
            </p>
            <p className="text-indented">
                •	Hintergrundgenerierung von Graphiken (erlaubte Formate: .jpg, .jpeg, und .png) mit den folgenden Preis Paketen:
            </p>
            <p className="text-indented">
                <b>
                    Monatliche Pläne
                </b>
            </p>
            <p className="text-indented">-	10 Bild / Monat (monatlicher Beitrag)</p>
            <p className="text-indented">-	30 Bilder / Monat (monatlicher Beitrag)</p>
            <p className="text-indented">-	125 Bilder / Monat (monatlicher Beitrag)</p>
            <p className="text-indented">-	250 Bilder / Monat (monatlicher Beitrag)</p>
            <p className="text-indented">-	500 Bilder / Monat (monatlicher Beitrag)</p>
            <p className="text-indented">
                <b>
                    Image To Go (einmalige Kauf Pläne)
                </b>
            </p>
            <p className="text-indented">-	4 Images (Bilder) to go (einmaliger Kauf)</p>
            <p className="text-indented">-	10 Images (Bilder) to go (einmaliger Kauf)</p>
            <p className="text-indented">-	50 Images (Bilder) to go (einmaliger Kauf)</p>
            <p className="text-indented">-	100 Images (Bilder) to go (einmaliger Kauf)</p>
            <p className="text-indented">-	200 Images (Bilder) to go (einmaliger Kauf)</p>
            <p className="text-indented">-	500 Images (Bilder) to go (einmaliger Kauf)</p>
            <p>
                (2) Sie erhalten zur Inanspruchnahme unserer Leistungen Zugriff auf Software, Online-Datenbanken, Funktionen, Betriebssysteme, Dokumentationen und alle anderen Bestandteile unserer Software (“Dienste”). Sie können die Dienste über unsere Plattformen nutzen - insbesondere über unsere mobilen Apps für iOS-, Android- und alle anderen mobilen Geräte sowie Webseiten. In jeglicher Form überlassenes Technik- oder Dokumentationsmaterial darf nur mit unserer schriftlichen Zustimmung weitergegeben oder vervielfältigt werden. Änderungen der Dienste sowie Fehlerkorrekturen sind nur in dem Umfang zulässig, als sie zur bestimmungsgemäßen Benutzung der Dienste notwendig sind. Eine Rückübersetzung (Dekompilierung) des Programmcodes in eine andere Darstellungsform ist untersagt. Unbeschadet dieses Nutzungsrechts behalten wir alle Rechte an den Diensten einschließlich des Quellcodes sowie aller Kopien oder Teilkopien. Sie verpflichten sich, die Dienste ohne unsere ausdrückliche schriftliche Zustimmung weder im Original noch in Form von vollständigen oder teilweisen Kopien Dritten zugänglich zu machen. Dies gilt auch für den Fall einer vollständigen oder teilweisen Veräußerung oder Auflösung Ihres Unternehmens.
            </p>
            <p>(3) Maßgeblich für den Umfang unserer Leistung ist im Einzelfall:</p>
            <p className="text-indented">
                -	Ihre Bestellung bzw. Ihr Auftrag, egal über welche unserer Plattformen Sie sie abgeben, insbesondere bei einer Online-Bestellung (beispielsweise die Leistungsbeschreibung im Rahmen des Beauftragungs- bzw. Bestellvorgangs, auf der Webseite oder der App) oder in Textform (beispielsweise die Bestell- bzw. Auftragsunterlagen samt Anhängen, unabhängig davon, ob in Papierform oder elektronisch per E-Mail)
            </p>
            <h3>§ 3 Vergütung und Durchführung</h3>
            <p>(1) Wir erhalten für unsere Leistungen eine Vergütung nach:</p>
            <p className="text-indented">-	Ihrer Bestellung bzw. dem Auftrag nach dem Paragrafen über den Leistungsumfang</p>
            <p>
                (2) Wir verpflichten uns, die vertraglich geschuldeten Leistungen sorgfältig und gewissenhaft zu Erbringen. Eine rechtliche Prüfung der Leistung bzw. des Leistungsergebnisses – insbesondere der Urheberschaft an den bei der Bildbearbeitung verwendeten Bilder – wird von uns nicht vorgenommen – insbesondere bleiben Sie verantwortlich für die Einhaltung von Rechten Dritter oder öffentlicher Rechtsvorschriften.
            </p>
            <p>
                (3) Bei der Durchführung unserer Tätigkeit sind wir etwaigen Weisungen im Hinblick auf die Art der Erbringung unserer Leistungen, den Ort der Leistungserbringung ebenso wie die Zeit der Leistungserbringung nicht unterworfen, soweit nichts Abweichendes schriftlich vereinbart wurde. Wir werden bei der Einteilung der Tätigkeitszeiten diese selbst in der Weise festlegen, dass der Leistungszweck effektiv erreicht wird.
            </p>
            <p>
                (4) Wir sind berechtigt, zur Erbringung der Leistung Dritte, insbesondere als Subunternehmer einzuschalten.
            </p>
            <p>
                (5) Wird eine Leistung vertragsgemäß unter Anwendung von “Künstlicher-Intelligenz” bzw. AI-Tools wie Open-AI GPT-3, Open-AI GPT-4, Midjourney, Stable Diffusion usw. erbracht, wird für die Richtigkeit und Vollständigkeit so erzielter Ergebnisse oder mögliche Rechtsverletzungen durch die Verwendung solcher Tools – insbesondere für die Wahrung von Rechten dritter wie Datenschutz-, Urheber- oder Markenrechte – keine Haftung übernommen. Die Erbringung entsprechender Leistungen erfolgt ausschließlich durch das AI-Tool und wird weder im Prozess noch im Ergebnis von uns überprüft. Die rechtliche sowie inhaltliche Überprüfung obliegt Ihnen.
            </p>
            <p>
                (6) Wird eine Leistung vertragsgemäß kostenfrei erbracht, wird für die Richtigkeit und Vollständigkeit so erzielter Ergebnisse – mit Ausnahme daraus entstehender Schäden des Lebens, des Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz – keine Haftung übernommen.  Die inhaltliche Überprüfung obliegt Ihnen.
            </p>
            <h3>§ 4 Vertragsschluss</h3>
            <p>
                (1) Wenn wir unsere Leistungen auf unseren Plattformen sowie den Plattformen Dritter (insbesondere den Android und Apple App-Stores) präsentieren, geben wir kein verbindliches Angebot zum Abschluss eines Vertrags ab. Bei der Präsentation handelt es sich lediglich um eine unverbindliche Darbietung.
            </p>
            <p>
                (2) Unsere Angebote und Kostenvoranschläge sind freibleibend. Kostenvoranschlags- und Angebotsfehler können vor der Auftragsannahme berichtigt werden.
            </p>
            <p>
                (3) Eine rechtsverbindliche Bestellung bzw. Beauftragung können Sie auf jede Weise abgeben, die unsere Plattformen anbieten oder wir Ihnen im Einzelfall vorschlagen. Insbesondere können Sie sie wie folgt abgeben:
            </p>
            <p className="text-indented">
                -	Indem Sie einen Bestellungs- bzw. Beauftragungs-Button/Knopf, nämlich den "Subscribe Now" oder "Buy Now" Knopf, auf unseren Plattformen anklicken, insbesondere auf unseren Webseiten und Apps sowie unseren Profilen auf Webseiten und Apps Dritter.
            </p>
            <p className="text-indented">
                -	Indem Sie uns ausgefüllte Auftrags- bzw. Vertragsunterlagen über jeden gängigen Kommunikationskanal wie E-Mail, Post, Fax oder unsere Social-Media-Profile zusenden oder auf ein von uns an Sie über einen vorgenannten Kommunikationskanal zugesandtes Vertragsabschlussangebot annehmend antworten.
            </p>
            <p className="text-indented">
                -	Indem Sie uns ausgefüllte Auftrags- bzw. Vertragsunterlagen übergeben – beispielsweise in unseren oder Ihren Räumlichkeiten oder Vertriebsflächen, auf Messen, Informations- oder Werbeveranstaltungen oder bei jeder anderen Gelegenheit.
            </p>
            <p>
                (4) Mit der Beauftragung bzw. Bestellung stimmen Sie zudem diesen AGB und der Datenverarbeitung gemäß unserer Datenschutzerklärung verbindlich zu. Wenn Sie die App von Plattformen Dritter herunterladen (beispielsweise Apple App Store, Google Play oder Amazon Appstore), gelten die Vertragsbedingungen AGB und Datenschutzbestimmungen des jeweiligen Drittanbieters, soweit inhaltliche Überschneidungen bestehen – insbesondere in Hinblick auf die Zahlungsbedingungen, das Widerrufsrecht, die Bestimmungen zum Benutzeraccount und der Anmeldung, die Datenschutzbestimmungen, den Endbenutzer-Lizenzvertrag für Lizenzierte Applikationen (EULA) oder die Verfügbarkeit der Dienste.
            </p>
            <p>
                (5) Sie sind an die Beauftragung bzw. Bestellung für die Dauer von 2 Wochen nach Abgabe der Beauftragung bzw. Bestellung gebunden.
            </p>
            <p>
                (6) Wir können den Zugang der abgegebenen Bestellung bzw. Auftrages
            </p>
            <p className="text-indented">
                -   per E-Mail an die von Ihnen angegebene oder genutzte E-Mail-Adresse bzw. per Nachricht innerhalb unserer Plattformen, insbesondre innerhalb unserer Webseiten und Apps sowie unseren Profilen auf Webseiten und Apps Dritter
            </p>
            <p className="text-indented">
                -    mit Bestätigungsschreiben per Brief oder durch Übergabe
            </p>
            <p>
                bestätigen. In der Bestätigung liegt keine verbindliche Annahme der Bestellung bzw. des Auftrages, es sei denn, darin wird neben der Bestätigung des Zugangs zugleich die Annahme erklärt.
            </p>
            <p>
                (7) Wir bestätigen die Bestellung bzw. Beauftragung grundsätzlich selbst. Allerdings kann auch ein Dritter in unserem Namen eine Bestätigung abgeben, wenn Sie unsere Leistung auf Plattformen Dritter – insbesondere einer Webseite oder App eines Dritten – bestellt bzw. beauftragt haben, auf der wir ein Profil unterhalten.
            </p>
            <p>
                (8) Ein Vertrag zwischen Ihnen und uns kommt erst zustande, wenn wir
            </p>
            <p className="text-indented">-	die Bestellung bzw. Ihren Auftrag ausdrücklich annehmen. </p>
            <p className="text-indented">-	mit der Leistungserbringung beginnen.</p>
            <p className="text-indented">-	eine Rechnung stellen.</p>
            <p className="text-indented">-	die Leistung – ganz oder teilweise – erbringen.</p>
            <p className="text-indented">
                -	Sie ein Konto auf unseren Plattformen einrichten – ob durch Anmeldung, die Übernahme Ihrer Daten von einem Drittanbieter oder auf eine andere Weise.
            </p>
            <p>Die Annahme kann gleichzeitig mit der Bestätigung erfolgen.</p>
            <p>
                (9) Bestehen mehrere Vertragspartner oder ist der Vertragspartner eine Personengesellschaft, haften sie bzw. die Gesellschafter der Personengesellschaft für unsere Forderung als Gesamtschuldner.  Wir sind berechtigt, uns bei der Vertragsdurchführung auf die Weisungen und Informationen eines einzelnen Vertragspartners bzw. Gesellschafters einer Personengesellschafters zu stützen, insbesondere ohne dies mit den Übrigen Vertragspartnern bzw. Gesellschaftern einer Personengesellschaft abzusprechen, soweit nicht ein anderer schriftlich widerspricht. Ein Widerspruch berechtigt uns zur Beendigung des Vertrags auf Grundlage und mit den Folgen einer fehlenden Mitwirkung.
            </p>
            <h3>§ 5 Anmeldung, Konto</h3>
            <p>
                (1) Sollten Leistungen auf unseren Plattformen auch ohne ein Konto in Anspruch genommen werden können, geben Sie bereits mit der Nutzung unserer Plattformen ein Angebot auf Abschluss eines Vertrages für die Dauer der Nutzung einer Plattform gemäß dieser AGB ab, welches wir durch die Erbringung der Leistung annehmen. In diesem Fall geben Sie mit der Nutzung unserer Plattformen ein Angebot auf Abschluss eines Vertrages für die Dauer der Nutzung einer Plattform gemäß dieser AGB ab, welches wir durch die Erbringung der Leistung annehmen.
            </p>
            <p>
                (2) Erfordert eine unserer Leistungen die Einrichtung eines Kontos auf unseren Plattformen, erhalten Sie es durch eine Anmeldung.
            </p>
            <p>
                (3) Die Angabe der bei Anmeldung abgefragten Daten ist verpflichtend. Sie versichern ihre Vollständigkeit und Richtigkeit. Die Anmeldung kann durch Eingabe Ihrer Daten auf unseren Plattformen erfolgen.
            </p>
            <p>
                (4) Folgende Voraussetzungen bestehen für die Anmeldung:
            </p>
            <p className="text-indented">
                -	Verbraucher sind unbeschränkt geschäftsfähige natürliche Personen über 18 Jahren oder beschränkt Geschäftsfähige, die mit Zustimmung Ihrer Eltern handeln
            </p>
            <p className="text-indented">
                -	Unternehmen als natürliche Personen erfüllen die Voraussetzungen eines Verbrauchers
            </p>
            <p className="text-indented">
                -	Unternehmer als Personen- oder Kapitalgesellschaften sowie jegliche anderen Körperschaften, Vereinigungen oder Gemeinschaften sind rechtsfähig und haben einen vertretungsberechtigten Vertreter. Die Angabe eines Postfachs genügt nicht.
            </p>
            <p>
                (5) Ein Anspruch auf Anmeldung besteht nicht. Wir sind berechtigt, eine Anmeldung abzulehnen. Mit dem Abschluss der Anmeldung entsteht zwischen Ihnen und uns ein Vertragsverhältnis.
            </p>
            <p>
                (6) Mit der Anmeldung erhalten Sie ein Konto, welches alle notwendigen Daten für die Nutzung enthält. Die Nutzung darf nur durch Sie selbst erfolgen, insbesondere dürfen Sie Dritten die Nutzung des Kontos nicht gestatten oder das Konto nicht an Dritte übertragen (Accountsharing). Das Passwort kann jederzeit geändert werden. Mehrere Konten einer Person sind zulässig. Das Konto besteht bis zum Eintritt der Wirkung der Kündigung. Wenn Sie die App von Plattformen Dritter herunterladen (beispielsweise Apple App Store, Google Play oder Amazon Appstore), gelten die Vertragsbedingungen AGB und Datenschutzbestimmungen des jeweiligen Drittanbieters, soweit inhaltliche Überschneidungen bestehen – insbesondere in Hinblick auf die Zahlungsbedingungen, das Widerrufsrecht, die Bestimmungen zum Benutzeraccount und der Anmeldung, die Datenschutzbestimmungen, den Endbenutzer-Lizenzvertrag für Lizenzierte Applikationen (EULA) oder die Verfügbarkeit der Dienste.
            </p>
            <p>
                (7) Sie sind für den Inhalt und die Qualität aller Angaben verantwortlich. Sie versichern, dass sie richtig und vollständig sind.
            </p>
            <p>
                (8) Sie dürfen den sicheren Betrieb unserer Plattformen nicht gefährden. Es ist alles zu unterlassen, was andere Benutzer der Plattformen belästigen könnte oder über die bestimmungsgemäße Benutzung unserer Plattformen hinausgeht. Sie sind insbesondere verpflichtet, folgendes zu unterlassen:
            </p>
            <p className="text-indented">
                -	Dateien hochzuladen oder zu versenden, die einen Virus oder sonstige Schadsoftware enthalten oder sonstige Eingriffe vorzunehmen, die die Funktionalität oder die Erreichbarkeit der Plattformen beeinträchtigen oder Inhalte verändern oder löschen könnte,
            </p>
            <p className="text-indented">
                -	Jede Form von Werbung hochzuladen oder zu versenden, besonders E-Mail-Werbung, SMS-Werbung, Kettenbriefe oder andere belästigende Inhalt,
            </p>
            <p className="text-indented">
                -	Die Plattformen einer übermäßigen Belastung auszusetzen oder auf jede andere Weise das Funktionieren zu stören oder zu gefährden,
            </p>
            <p className="text-indented">
                -	Ohne schriftliche Zustimmung Crawler, Spider, Scraper oder andere automatisierte Mechanismen zu nutzen, um auf die Plattformen zuzugreifen und Inhalte zu sammeln.
            </p>
            <p className="text-indented">
                -	Informationen wie E-Mail-Adressen oder Rufnummern anderer Benutzer ohne vorherige Einwilligung zu sammeln oder zu verwenden,
            </p>
            <p className="text-indented">
                -	Inhalte der Plattformen oder Dritter ohne vorherige Einwilligung durch uns oder die Dritten zu vervielfältigen, öffentlich zugänglich zu machen, zu verbreiten, zu bearbeiten oder in einer Art und Weise zu nutzen, die über die bestimmungsgemäße Nutzung hinausgeht.
            </p>
            <p>
                (9) Wir sind berechtigt, in Hinblick auf Ihr Konto ohne Angabe von Gründen jede Maßnahme zu treffen. Insbesondere sind wir berechtigt, Sie zu einer Stellungnahme aufzufordern, das Konto vorläufig zu sperren, eine Verwarnung auszusprechen oder das Konto dauerhaft zu sperren oder zu löschen. Darüber hinaus behalten wir uns ausdrücklich die Geltendmachung von zivil- und strafrechtlichen Ansprüchen vor. Die Sanktionen betreffen nicht die Zahlungspflicht bei bereits zustande gekommenen Leistungsverhältnissen, insbesondere wenn die Leistung bereits (teilweise) erbracht wurde.
            </p>
            <p>
                (10) Wurde die App von Plattformen Dritter herunterladen, können in Hinblick auf Anmeldung und Konto die Vertragsbedingungen AGB und Datenschutzbestimmungen des jeweiligen Drittanbieters gelten, soweit inhaltliche Überschneidungen bestehen.
            </p>
            <h3>§ 6 Preise, Zahlung, Verzug, Zahlungsbedingungen, Aufrechnung, Zurückbehaltungsrecht</h3>
            <p>
                (1) Für unsere kostenpflichtigen Leistungen auf unseren Plattformen gilt: Die von uns angegebenen Preise sind – soweit im Einzelfall nichts anderes präsentiert oder vereinbart wurde – Bruttopreise inklusive der Umsatzsteuer
            </p>
            <p>
                (2) Unsere Vergütung wird – soweit keine andere Vereinbarung zwischen Ihnen und uns besteht – nach Abschluss des Vertrages und vor der jeweiligen Leistungserbringung fällig. Sie ist spätestens innerhalb von 2 Wochen nach Versand unserer Rechnung (Rechnungsdatum) zu bezahlen. Unterbleibt die Zahlung, tritt Zahlungsverzug ein. Bei Zahlungsverzug sind wir berechtigt, nach den gesetzlichen Bestimmungen Verzugszinsen und weiteren Schadensersatz geltend zu machen. Der Verzugszins gegenüber Verbrauchern beträgt für das Jahr 5 Prozentpunkte über dem Basiszinssatz nach § 288 BGB; gegenüber Unternehmern beträgt der Verzugszins für das Jahr 9 Prozentpunkte über dem Basiszinssatz nach § 288 BGB.
            </p>
            <p>
                (3) Wir ermöglichen Ihnen die Nutzung verschiedener Zahlungsdienste und -möglichkeiten. Sie können zur Zahlung jeden von uns bereitgestellten Zahlungsweg nutzen, insbesondere
            </p>
            <p className="text-indented">-	auf ein von uns angegebenes Konto überweisen,</p>
            <p className="text-indented">-	uns eine Einzugsermächtigung oder SEPA-Lastschriftmandat erteilen,</p>
            <p className="text-indented">-	uns per EC-/Maestro- oder Kreditkarte bezahlen,</p>
            <p className="text-indented">-	uns über eine Plattform Dritter bezahlen (beispielsweise Apple App Store, Google Play oder Amazon Appstore),</p>
            <p className="text-indented">-	oder uns über einen von uns angegebenen Zahlungsdienstleister (beispielsweise Stripe, PayPal, usw.) bezahlen,</p>
            <p>
                jeweils, sofern wir eine entsprechende Zahlungsmöglichkeit anbieten. Wir behalten uns vor, Zahlungsmöglichkeiten individuell oder allgemein auszuschließen oder im Nachgang zu ergänzen.
            </p>
            <p>
                (4) Sie nehmen die Zahlungsleistung eines Zahlungsdienstleisters in Anspruch, indem Sie auf den Button des Zahlungsdienstleisters während des Bestellprozesses von Leistungen klicken. Sie werden auf die entsprechende Seite des jeweiligen Zahlungsdienstleisters geführt. Sie nehmen die Zahlungsleistung einer dritten Plattform wie Apple App Store, Google Play oder Amazon Appstore in Anspruch, indem Sie unsere App über ihn runterladen. Wir stellen hinsichtlich der Zahlung nur den Zugang zur Seite des jeweiligen Zahlungsdienstleisters oder der Plattform bereit, werden aber nicht Vertragspartei. Meistens ist es zur Nutzung von Zahlungsdiensten eines Zahlungsdienstleisters oder der Plattform erforderlich, ein Vertragsverhältnis mit dem entsprechenden Zahlungsdienstleister einzugehen. Es gelten die jeweiligen Vertragsbedingungen, AGB und Datenschutzbestimmungen.
            </p>
            <p>
                (5) Im Fall einer erteilten Einzugsermächtigung, eines SEPA-Lastschriftmandats oder der Zahlung per EC-/Maestro- oder Kreditkarte werden wir die Belastung Ihres Kontos frühestens zum Fälligkeitszeitpunkt veranlassen. Eine erteilte Einzugsermächtigung gilt bis auf Widerruf auch für weitere Aufträge.
            </p>
            <p>
                (6) Sie sind nicht berechtigt, gegenüber unseren Forderungen aufzurechnen, es sei denn, Ihre Gegenansprüche sind rechtskräftig festgestellt oder unbestritten, sowie dann, wenn Sie Mängelrügen oder Gegenansprüche aus demselben Vertragsverhältnis geltend machen.
            </p>
            <p>
                (7) Sie dürfen nur dann ein Zurückbehaltungsrecht ausüben, wenn Ihr Gegenanspruch aus demselben Vertragsverhältnis herrührt und rechtskräftig festgestellt oder unbestritten ist.
            </p>
            <p>
                (8) Für den Fall, dass auf eine unserer Forderung aus einem oder mehreren Verträgen nicht fristgerecht gezahlt wird, sind wir berechtigt, ein Inkassobüro (z.B. Creditreform) mit dem weiteren Einzug der fälligen Forderung zu beauftragen. Sie willigen mit Vertragsunterschrift ein, dass wir die zum Einzug der Forderung erforderlichen Daten und Informationen an das Inkassobüro (z.B. Creditreform) übermitteln und das Inkassobüro (z.B. Creditreform) zur Speicherung und Verarbeitung der Daten berechtigt ist. Insbesondere werden Name und Anschrift, Vertragsdatum, sowie Rechnungsnummer, Rechnungsbetrag und das Fälligkeitsdatum übermittelt.
            </p>
            <p>
                (9) Wurde die App von Plattformen Dritter herunterladen, können die Vertragsbedingungen AGB und Datenschutzbestimmungen des jeweiligen Drittanbieters gelten, soweit inhaltliche Überschneidungen bestehen.
            </p>
            <h3>§ 7 Laufzeit und Kündigung</h3>
            <p>
                (1) Die Laufzeit des Vertrags beginnt – soweit nichts Gegenteiliges elektronisch oder schriftlich vereinbart – mit der Absendung der Auftrags- bzw. Bestellungsbestätigung des Verwenders an den Partner.
            </p>
            <p>
                (2) Der geschlossene Vertrag läuft – soweit nichts Gegenteiliges elektronisch oder schriftlich vereinbart – auf unbestimmte Zeit.
            </p>
            <p>
                (3) Im Falle einer Mindestvertragslaufzeit verlängert sich der Vertrag nach der Mindestvertragslaufzeit auf eine unbestimmte Zeit, wenn er nicht vorab mit einer Frist von einem Tag zum jeweiligen Laufzeitende im Voraus von einer der Parteien gekündigt wird. Nach der Verlängerung auf unbestimmte Zeit kann der Vertrag mit einem Frist von einem Tag zum Ende eines Monats gekündigt werden.
            </p>
            <p>
                (4) Kündigen wir aus wichtigem Grund, so sind Sie verpflichtet, uns die Kosten und Vergütungen zu erstatten, die nachweislich bis zum Zeitpunkt der Kündigung angefallen sind. Die bis dahin von uns geleisteten Dienste sind anteilig abzurechnen, soweit dies möglich ist, anderenfalls erfolgt eine vollständige Abrechnung.
            </p>
            <p>
                (5) Die Kündigung kann im Konto oder per E-Mail erfolgen.
            </p>
            <p>
                (6) Sollten zum Zeitpunkt des Zuganges der Kündigungserklärung von uns geschuldete Leistungen noch nicht erbracht worden sein, so sind wir von unserer Leistungspflicht frei, es sei denn, die Parteien vereinbaren schriftlich den Umfang der noch zu erbringenden Leistungen.
            </p>
            <p>
                (7) Bei einmaligen „Image To Go“-Plänen endet der Vertrag automatisch nach Erbringung der vereinbarten Leistung. Eine Kündigung ist in diesem Fall nicht erforderlich. Sollte die vereinbarte Leistung nicht innerhalb eines vereinbarten Zeitraums erbracht werden, hat der Partner das Recht, den Vertrag ohne Einhaltung einer Kündigungsfrist zu kündigen.
            </p>
            <h3>§ 8 Rechteeinräumung an Tätigkeitsergebnissen</h3>
            <p>
                (1) Wir erkennen an, dass nach vollständiger Zahlung unserer Vergütung sämtliche Rechte an allen Tätigkeitsergebnissen (nachstehend „Schutzrechte“), die an den Tätigkeitsergebnissen bestehen, aus ihrer Nutzung entstehen und/oder in ihnen verkörpert sind, einschließlich aller denkbaren Rechtspositionen an Ideen, Entwürfen und Gestaltungen, im Zeitpunkt ihrer Entstehung vollständig und ohne Einschränkung auf Sie übergehen. Sie nehmen diese Übertragung hiermit an.
            </p>
            <p>
                (2) Für den Fall, dass die unter Abs. (1) vorgesehene Rechtsübertragung nicht wirksam nach zwingend anwendbarem Recht bewirkt werden kann, räumen wir Ihnen hiermit ein umfassendes, ausschließliches, räumlich und zeitlich unbegrenztes und für alle Nutzungsarten uneingeschränkt geltendes Nutzungsrecht an den Tätigkeitsergebnissen bzw. Schutzrechten ein. Soweit dies nach anwendbarem Recht möglich ist, verzichten wir hiermit unbedingt und unwiderruflich auf alle Urheberpersönlichkeitsrechte, die an bereits entstandenen oder zukünftigen Tätigkeitsergebnissen bestehen, inklusive des Namensnennungsrechts und des Entstellungsverbots.
            </p>
            <p>
                (3) Die Übertragung bzw. Rechtseinräumung umfasst insbesondere das Recht, die erstellten Tätigkeitsergebnisse für eigene oder fremde Zwecke in jeder Weise weltweit und zeitlich unbefristet, entgeltlich und unentgeltlich zu verwerten, einschließlich der Verwertung in und auf Produkten, ob eigene oder solche für Dritte, in allen Verwendungsarten. Sie umfasst außerdem das Recht, die Tätigkeitsergebnisse zu vervielfältigen und/oder zu veröffentlichen. Zu den Rechten gehört auch das Bearbeitungsrecht, dh. die Berechtigung, die Tätigkeitsergebnisse weiter zu bearbeiten oder durch Dritte weiter zu bearbeiten lassen.
            </p>
            <p>
                (4) Die Rechteeinräumung erfolgt des Weiteren auch für neue Nutzungsarten, die erst in der Zukunft bekannt werden.
            </p>
            <p>
                (5) Sie können die eingeräumten Rechte ganz oder teilweise auf Dritte übertragen, ohne dass es hierzu einer schriftlichen Vereinbarung bedarf.
            </p>
            <p>
                (6) Die vorstehend genannte Rechtsübertragung und Einräumung von Nutzungsrechten erfolgt erst nach vollständiger Zahlung unserer Vergütung.
            </p>
            <p>
                (7) Wir können die Tätigkeitsergebnisse im Rahmen unseres Geschäftsbetriebs nutzen und nach Entfernung der kundenspezifischen Details frei verwenden.
            </p>
            <h3>§ 9 Widerruf</h3>
            <p>
                (1) Falls Sie Unternehmer im Sinne des § 14 BGB sind, besteht das Widerrufsrecht nicht. Für Verbraucher gilt:
            </p>
            <h3 className="title-left">Widerrufsbelehrung</h3>
            <h3>Widerrufsrecht</h3>
            <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. </p>
            <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.</p>
            <p>
                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (generatebg UG (haftungsbeschränkt), Freiligrathstr. 47, 50935 Köln, E-Mailadresse: support@generatebg.com) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
            </p>
            <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </p>
            <h3>Folgen des Widerrufs</h3>
            <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
            </p>
            <p>
                Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
            </p>
            <h3 className="title-left">Muster-Widerrufsformular</h3>
            <p>
                (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
            </p>
            <p>
                — An (generatebg UG (haftungsbeschränkt), Freiligrathstr. 47, 50935 Köln, E-Mailadresse: support@generatebg.com):
            </p>
            <p>
                — Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung (*)
            </p>
            <p>
                — Bestellt am (*) / erhalten am (*)
            </p>
            <p>
                — Name des/der Verbraucher(s)
            </p>
            <p>
                — Anschrift des/der Verbraucher(s)
            </p>
            <p>
                — Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
            </p>
            <p>
                — Datum _______________ (*)
            </p>
            <p className="text-center"><b>-    ENDE DIESES MUSTERWIDERRUFSFORMULARS -</b></p>
            <p>
                (2) Das Widerrufsrecht besteht nicht, erlischt oder kann ausgeschlossen werden, wenn ein gesetzlich geregelter Fall, eine entsprechende gerichtliche Entscheidung oder ein sonstiger rechtlicher Grund besteht. Gesetzlich geregelte Fälle ergeben sich insbesondere aus §§ 312 g oder 356 BGB.
            </p>
            <p>
                (3) Das Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht auf einem körperlichen Datenträger befindlichen digitalen Inhalten auch dann, wenn der Unternehmer mit der Ausführung des Vertrags begonnen hat, nachdem der Verbraucher
            </p>
            <p className="text-indented">
                1. ausdrücklich zugestimmt hat, dass der Unternehmer mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und
            </p>
            <p className="text-indented">
                2. seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.
            </p>
            <p>
                (4) Das Widerrufsrecht erlischt insbesondere bei einem Vertrag zur Erbringung von Dienstleistungen auch dann, wenn der Unternehmer die Dienstleistung vollständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Verbraucher dazu seine ausdrückliche Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass er sein Widerrufsrecht bei vollständiger Vertragserfüllung durch den Unternehmer verliert. Bei einem außerhalb von Geschäftsräumen geschlossenen Vertrag muss die Zustimmung des Verbrauchers auf einem dauerhaften Datenträger übermittelt werden. Bei einem Vertrag über die Erbringung von Finanzdienstleistungen erlischt das Widerrufsrecht abweichend von Satz 1, wenn der Vertrag von beiden Seiten auf ausdrücklichen Wunsch des Verbrauchers vollständig erfüllt ist, bevor der Verbraucher sein Widerrufsrecht ausübt.
            </p>
            <p>
                (5) Wurde die App von Plattformen Dritter herunterladen (beispielsweise App-Store oder Google-Play-Store), gelten in Hinblick auf den Widerruf die Vertragsbedingungen der Plattform. Der Widerruf erfolgt gegenüber der Plattform, weil es sich bei den Plattformen um einen Zwischenhändler und Ihren direkten Vertragspartner handelt.
            </p>
            <h3>§ 10 Mitwirkungspflicht</h3>
            <p>
                (1) Sie werden uns bei der Erbringung unserer vertragsgemäßen Leistungen durch angemessene Mitwirkungshandlungen fördern. Sie werden uns beispielsweise die erforderlichen Informationen, Daten, Umstände, Verhältnisse unverzüglich mitteilen; Unterlagen, Materialien, Sachen oder Zugänge zur Erfüllung der Leistung zur Verfügung stellen; uns unverzüglich Weisungen und Freigaben erteilen und uns einen kompetenten Ansprechpartner benennen, der nicht ausgewechselt wird. Tätigkeitsergebnisse werden von Ihnen überprüft. Sie sind von Ihnen insbesondere auf rechtliche, dabei insbesondere auf jegliche Verstöße gegen Rechte Dritter zu prüfen. Wir sind nicht zu einer entsprechenden Prüfung verpflichtet.
            </p>
            <p>
                (2) Soweit Sie zur Mitteilung, Bereitstellung oder zur Verfügungsüberlassung nach Abs. 1 nicht berechtigt sind, beispielsweise weil wettbewerbs-, datenschutz-, marken- und kennzeichenrechtliche Verstöße oder jegliche Verstöße gegen Rechte Dritter oder behördliche Bestimmungen vorliegen, liegt ebenso fehlende Mitwirkung vor. Sie versichern Ihre Berechtigung zu den entsprechenden Handlungen. Eine entsprechende Überprüfung durch uns wird nicht erfolgen. Von etwaigen Ansprüchen Dritter, die wegen Ihrer fehlenden Berechtigung gegen uns vorgehen, werden Sie uns auf erstes Anfordern freistellen und uns jeglichen Schaden, der wegen der Inanspruchnahme durch den Dritten entsteht, einschließlich etwaiger für die Rechtsverteidigung anfallenden Gerichts- und Anwaltskosten, ersetzen. Im Übrigen gelten die gesetzlichen Bestimmungen.
            </p>
            <p>
                (3) Fehlende, unvollständige, schadensverursachende oder rechtsverletzende Mitwirkung – beispielsweise durch Mitteilung bzw. Zuleitung unvollständiger, unrichtiger oder nicht zur rechtmäßigen Verwendung geeigneter Informationen, Daten, Stoffe oder Unterlagen – berechtigt uns zur Beendung des Vertrags, im Falle eines Vertrages mit einem Unternehmer auch ohne Auswirkung auf die vereinbarte Vergütung.
            </p>
            <p>
                (4) Entsteht uns durch fehlerhafte Mitwirkung ein Schaden, besteht ein Schadensersatzanspruch. Sie stellen uns in diesem Fall ebenso von sämtlichen Ansprüchen Dritter frei, die Dritte im Zusammenhang mit von Ihnen zumindest grob fahrlässig fehlerhaft durchgeführten Mitwirkungshandlungen geltend machen.
            </p>
            <h3>§ 11 Leistungsfrist, Höhere Gewalt, Leistungshindernisse</h3>
            <p>
                (1) Wir sind – soweit nicht im Einzelfall vereinbart - nicht an Fristen und Termine bei der Ausführung der Leistung gebunden. Leistungstermine bedürfen der Schriftform.
            </p>
            <p>
                (2) Soweit wir an der Leistungserbringung durch den Eintritt unvorhersehbarer, außergewöhnlicher, von uns nicht zu vertretener Ereignisse gehindert werden und die wir trotz der nach den Verhältnissen des Einzelfalles zumutbaren Sorgfalt nicht abwenden konnten – unerheblich ob bei uns oder bei Ihnen, verlängert sich die für uns gültige Leistungszeit in einem Umfang, der die angemessene Erfüllung der Leistungspflicht möglich macht. Wird die Leistung unmöglich, werden wir von der Leistungsverpflichtung frei, ohne dass Ihnen ein Rücktritt oder Schadensersatz zustehen. Treten entsprechende Hindernisse bei Ihnen auf, so gelten die gleichen Rechtsfolgen auch für Ihre Abnahmeverpflichtung. Die Vertragspartner teilen einander entsprechende Hindernisse unverzüglich mit.
            </p>
            <p>
                (3) Leistungshindernisse, die nicht dem Risikobereich einer Vertragspartei zuzurechnen sind, befreien die Vertragsparteien für die Dauer der Störung und den Umfang ihrer Wirkung von den Leistungspflichten.
            </p>
            <h3>§ 12 Kommunikation</h3>
            <p>
                (1) Zur Gewährleistung einer schnellen und einfachen Kommunikation untereinander erfolgt die Kommunikation grundsätzlich über Ihr Konto auf unseren Plattformen. Sie willigen dazu ein, dass Ihnen Informationen per E-Mail, soweit vorhanden Ihrem Konto auf unseren Plattformen, postalisch oder auf anderem Weg zugesandt werden.
            </p>
            <p>
                (2) Der Versand und die Kommunikation erfolgen auf Ihr Risiko. Für Störungen in den Leitungsnetzen des Internets, für Server- und Softwareprobleme Dritter oder Probleme eines Post- oder Zustellungsdienstleisters sind wir nicht verantwortlich und haften nicht.
            </p>
            <h3>§ 13 Technische Verfügbarkeit, Daten, Funktionalität und Inhalte</h3>
            <p>
                (1) Die Plattformen sind 24 Stunden am Tag, 7 Tage die Woche zugänglich, außer im Fall höherer Gewalt oder einem außerhalb unseres Einflusses liegenden Ereignis und vorbehaltlich von Ausfällen und Wartungsarbeiten, die für den Betrieb erforderlich sind. Wir wirken mit großer Sorgfalt auf eine höchstmögliche Erreichbarkeit hin. Die Verfügbarkeit hängt unter anderem von Ihrer technischen Ausstattung ab. Verfügbarkeitsunterbrechungen können durch notwendige Wartungs- und Sicherheitsarbeiten oder unvorhergesehen Ereignissen eintreten, die nicht in unserem Einflussbereich liegen.
            </p>
            <p>
                (2) Wir haften nicht für Ihren Verlust von Daten oder von daraus resultierenden Schäden, soweit die Schäden durch eine regelmäßige und vollständige Sicherung der Daten bei Ihnen nicht eingetreten wären.
            </p>
            <p>
                (3) Wir können jegliche Funktionsweise, das Aussehen, den Aufbau oder die Inhalte unserer Plattformen verändern, ohne Ihre Zustimmung einzuholen.
            </p>
            <p>
                (4) Wir sind berechtigt, alle Inhalte – auch User-Generated-Content - zu sperren oder zu verändern.
            </p>
            <h3>§ 14 Rechteeinräumung an Daten</h3>
            <p>
                (1) Sie verpflichten sich, über die Plattformen keine Texte, Bilder, Video, Audiodateien und/oder sonstige Inhalte („Dateien“) zu verbreiten, die gegen geltendes Recht, gegen die guten Sitten und/oder gegen diese AGB verstoßen. Sie verpflichten sich insbesondere, die Rechte Dritter, wie Urheberrechte, Markenrechte, Patent- und Gebrauchsmusterrechte, Designrechte, Datenbankrechte sowie jegliche sonstigen gewerblichen Schutzrechte (nachstehend „Schutzrechte“), zu beachten.
            </p>
            <p>
                (2) Sie räumen uns hiermit ein umfassendes, ausschließliches, räumlich und zeitlich unbegrenztes, jedoch auf die Nutzungsart der Durchführung der eigenen Leistungen sowie der eigenen Werbung eingeschränktes Nutzungsrecht an den zur Leistungserfüllung benötigten Dateien, die Sie über unsere Plattformen veröffentlichen oder auf unsere Plattform oder in das Nutzerkonto zur Leistungserfüllung hochladen oder uns auf jede andere Weise zuleiten.
            </p>
            <p>
                (3) Soweit wir Dateien für Sie erstellen, verbleiben sämtliche Urheber- und Nutzerrechte bei uns.
            </p>
            <p>
                (4) Wurde die App von Plattformen Dritter herunterladen, können die Vertragsbedingungen AGB und Datenschutzbestimmungen des jeweiligen Drittanbieters gelten, soweit inhaltliche Überschneidungen bestehen.
            </p>
            <h3>§ 15 Unsere Rechte an unseren Plattformen</h3>
            <p>
                (1) Sie erklären sich einverstanden, dass es sich bei den Plattformen und allen mit ihnen zusammenhängenden Anwendungen um Datenbankwerke und um Datenbanken i. S. v. §§ 4 Abs. 2, 87a Abs. 1 UrhG handelt, deren rechtliche Inhaber wir sind. Alle zugehörigen Anwendungen unterfallen dem Schutz nach §§ 69a ff. UrhG. Sie sind urheberrechtlich geschützt.
            </p>
            <p>
                (2) Die Rechte an allen sonstigen Elementen unserer Plattformen, insbesondere die Nutzungs- und Leistungsschutzrechte an den von uns eingestellten oder per Rechteeinräumung erworbenen Inhalten und Dokumenten, stehen ebenfalls ausschließlich uns zu. Insbesondere Marken, sonstige Kennzeichen, Firmenlogos, Schutzvermerke, Urhebervermerke oder andere der Identifikation unserer Plattformen dienender einzelner Elemente davon dienende Merkmale dürfen nicht entfernt oder verändert werden. Das gilt ebenso für Ausdrucke.
            </p>
            <h3>§ 16 Änderung der Dienste</h3>
            <p>
                Wir behalten uns vor, den zur Inanspruchnahme unserer Leistungen erforderlichen Zugriff auf Software, Online-Datenbanken, Funktionen, Betriebssysteme, Dokumentationen und alle anderen Bestandteile unserer Software sowie ihre Funktionsweise– soweit rechtlich zulässig auch ohne vorherige Ankündigung – insgesamt oder in Teilen, jederzeit, vorübergehend oder auf Dauer, einzustellen, zu verändern, oder einzuschränken. Insbesondere behalten wir uns vor, Eigenschaften unserer Leistungen (beispielsweise Design, Layout, Rubriken, Struktur oder Verfügbarkeit) zu verändern, zu deaktivieren, kostenfreie Bestandteile in kostenpflichtige umzustellen, bestimmte Funktionen nicht weiter zu unterstützen oder die Komptabilität (beispielsweise zu bestimmten Gerätetypen oder Betriebssystemen) auszusetzen.
            </p>
            <h3>§ 17 Endbenutzer-Lizenzvertrag (EULA)</h3>
            <p>
                (1) Wir gewähren Ihnen ein persönliches, nicht exklusives, widerrufliches, nicht übertragbares und weltweites Nutzungsrecht an den Plattformen - insbesondere jeglichen Softwarefunktionen auf der Webseite oder Apps -, ihren Inhalten, Diensten, sonstigen Funktionen und allen Updates. Dieses wird ausschließlich für Ihren eigenen Bedarf und im Rahmen der Nutzung der Plattformen und deren Diensten und unter Ausschluss jeglicher anderen Zwecke gewährt.
            </p>
            <p>
                (2) Unsere digitalen Produkte (insbesondere Apps, Software) werden an Sie lizenziert und nicht an Sie verkauft.
            </p>
            <p>
                (3) Die Lizenz gibt ihnen kein Nutzungsrecht am Inhalt. Es ist insbesondere verboten:
            </p>
            <p className="text-indented">
                -	Die Plattformen, ihre Inhalte, Dienste, sonstige Funktionen oder Updates anzupassen, zu verändern, zu übersetzen, zu bearbeiten, eine Rückumstellung vorzunehmen, zu zerlegen, zu transkodieren oder durch Reverse Engineering die Plattform oder einen Teil davon abzubilden;
            </p>
            <p className="text-indented">
                -	Die Plattformen, ihre Inhalte, Dienste, sonstige Funktionen oder Updates zu exportieren, oder ganz oder teilweise mit anderen Softwareprogrammen zu verbinden, oder sie ganz oder teilweise, mit jeglichem Mittel und in jeglicher Form dauerhaft oder vorläufig zu reproduzieren;
            </p>
            <p className="text-indented">
                -	Inhalte der Datenbanken, die aus den Plattformen entstanden sind, zu extrahieren oder weiterzuverwenden;
            </p>
            <p className="text-indented">
                -	Werke zu erstellen, die von der lizenzierten Plattform abgeleitet sind;
            </p>
            <p className="text-indented">
                -	Prozesse oder Software zu nutzen, die dazu bestimmt sind, die Plattformen, ihre Inhalte, Dienste, sonstige Funktionen oder Updates ohne unsere Zustimmung zu kopieren;
            </p>
            <p className="text-indented">
                -	Systeme einzurichten, die imstande sind, die Plattformen zu hacken.
            </p>
            <p className="text-indented">
                -	Dritten unsere Leistungen ohne unsere Zustimmung anzubieten oder zu überlassen.
            </p>
            <p>
                (4) Bei einer Verletzung des Verbots bestehen Strafbarkeit und Schadensersatzpflicht.
            </p>
            <p>
                (5) Wurde die App von Plattformen Dritter herunterladen, können die Vertragsbedingungen AGB und Datenschutzbestimmungen des jeweiligen Drittanbieters gelten, soweit inhaltliche Überschneidungen bestehen.
            </p>
            <h3>§ 18  Urheber- und sonstige Rechte</h3>
            <p>
                Wir haben an allen Bildern, Filmen, Texten und sonstigen vom Urheberrecht oder ähnlichen Rechten, insbesondere durch geistige Eigentumsrechte, geschützten Inhalten, die auf unserer Webseite, unseren Profilen auf anderen Webseiten, unseren Social-Media-Profilen und allen unseren Plattformen veröffentlicht werden, Urheberrechte oder sonstige Rechte. Eine Verwendung der Bilder, Filme, Texte und sonstiger Rechte ist ohne unsere schriftliche Zustimmung nicht gestattet.
            </p>
            <h3>§ 19 Datenschutz und Datensicherheit</h3>
            <p>
                (1) Wir erheben personenbezogene Daten von Ihnen sowie ggf. andere, von Ihnen zugeleitete oder im Zuge der Vertragserfüllung von uns erlangte Daten zum Zweck der Vertragsdurchführung sowie zur Erfüllung der vertraglichen und vorvertraglichen Pflichten. Die Datenerhebung und Datenverarbeitung ist zur Vertragserfüllung erforderlich und beruht auf Artikel 6 Abs.1 b) DSGVO. Wir verarbeiten sie nach den Verpflichtungen der DSGVO. Nach § 5 Abs. 1 DSGVO müssen personenbezogene Daten im Wesentlichen:
            </p>
            <p className="text-indented">
                (a) auf rechtmäßige und faire Weise und in einer für die betroffene Person nachvollziehbaren Weise verarbeitet werden („Rechtmäßigkeit, Verarbeitung nach Treu und Glauben, Transparenz“);
            </p>
            <p className="text-indented">
                (b) für festgelegte, eindeutige und legitime Zwecke erhoben werden und dürfen nicht in einer mit diesen Zwecken nicht zu vereinbarenden Weise weiterverarbeitet werden („Zweckbindung“);
            </p>
            <p className="text-indented">
                (c) dem Zweck angemessen und erheblich sowie auf das für die Zwecke der Verarbeitung notwendige Maß beschränkt sein („Datenminimierung“);
            </p>
            <p className="text-indented">
                (d) sachlich richtig und erforderlichenfalls auf dem neuesten Stand sein; es sind alle angemessenen Maßnahmen zu treffen, damit personenbezogene Daten, die im Hinblick auf die Zwecke ihrer Verarbeitung unrichtig sind, unverzüglich gelöscht oder berichtigt werden („Richtigkeit“);
            </p>
            <p className="text-indented">
                (e) in einer Form gespeichert werden, die die Identifizierung der betroffenen Personen nur so lange ermöglicht, wie es für die Zwecke, für die sie verarbeitet werden, erforderlich ist („Speicherbegrenzung“);
            </p>
            <p className="text-indented">
                (f) in einer Weise verarbeitet werden, die eine angemessene Sicherheit der personenbezogenen Daten gewährleistet, einschließlich Schutz vor unbefugter oder unrechtmäßiger Verarbeitung und vor unbeabsichtigtem Verlust, unbeabsichtigter Zerstörung oder unbeabsichtigter Schädigung durch geeignete technische und organisatorische Maßnahmen („Integrität und Vertraulichkeit“).
            </p>
            <p>
                (2) Daten werden grundsätzlich nicht an Dritte übermittelt, wenn keine entsprechende Pflicht besteht oder die Vertragsdurchführung oder der Einhaltung einer gesetzlichen Frist eine Datenübermittlung erforderlich macht, beispielsweise wenn die Weitergabe der Daten erforderlich sind, um für Sie eine zur Vertragsdurchführung notwendige Abfrage durch einen Drittanbieter durchzuführen, Ihre Daten an einen Zahlungsanbieter weitergeleitet werden oder freie Mitarbeiter in Anspruch genommen werden, um zur Erfüllung einer Leistungspflicht Ihnen gegenüber beizutragen. In diesen Fällen werden die Dienstleister vielfach mit Ihnen ein Vertragsverhältnis haben, so dass sie auf eigene Verantwortung handeln.
            </p>
            <p>
                (3) Sobald Daten für den Zweck ihrer Verarbeitung nicht mehr erforderlich sind und falls eine gesetzliche Aufbewahrungspflicht nicht weiter besteht, werden sie von uns gelöscht. In Anbahnung unseres Vertragsverhältnisses sowie bei dessen Durchführung bewahren wir Ihre Daten auf. Dabei kann es auch notwendig sein, dass nach Kündigung unseres Vertragsverhältnisses Daten weiter aufbewahrt werden. Beispielsweise müssen Rechnungsdaten (Abrechnungsunterlagen) gemäß § 147 Abgabenordnung 10 Jahre aufbewahrt werden. Solange ein für uns ausführender Dienstleister ebenso einen Vertrag über die Durchführung Ihrer Leistung mit uns hat, bleiben wir verpflichtet, die Daten entsprechend der vereinbarten Aufbewahrungsfristen vorzuhalten.
            </p>
            <p>
                (4) Sie haben das Recht auf Auskunft, Datenübertragung, Löschung, Berichtigung, Einschränkung oder Sperrung Ihrer personenbezogenen Daten. Insbesondere haben Sie einen Anspruch auf eine unentgeltliche Auskunft über alle personenbezogenen Daten.
            </p>
            <p>
                Ihre Anfrage kann an uns gestellt werden. Außerdem stehen Ihnen entsprechende verwaltungsrechtliche oder gerichtliche Rechtsbehelfe oder die bei einer Aufsichtsbehörde offen.
            </p>
            <p>
                (5) Wurde die App von Plattformen Dritter herunterladen, können die Vertragsbedingungen AGB und Datenschutzbestimmungen des jeweiligen Drittanbieters gelten, soweit inhaltliche Überschneidungen bestehen
            </p>
            <h3>§ 20 Haftung, Freistellung und Aufwendungsersatz</h3>
            <p>
                (1) Wir haften gegenüber Ihnen in allen Fällen vertraglicher und außervertraglicher Haftung bei Vorsatz und grober Fahrlässigkeit nach Maßgabe der gesetzlichen Bestimmungen auf Schadensersatz oder Ersatz vergeblicher Aufwendungen.
            </p>
            <p>
                (2) In sonstigen Fällen haften wir – soweit in Abs. 3 nicht abweichend geregelt – nur bei Verletzung einer Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung Sie als Vertragspartner regelmäßig vertrauen dürfen (so genannte Kardinalpflicht), und zwar beschränkt auf den Ersatz des vorhersehbaren und typischen Schadens. In allen übrigen Fällen ist unsere Haftung vorbehaltlich der Regelung in Abs. 3 ausgeschlossen.
            </p>
            <p>
                (3) Unsere Haftung für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz bleibt von den vorstehenden sowie allen übrigen in diesen AGB sowie zwischen uns getroffenen Haftungs-, Gewährleistungs- oder Verantwortungsbeschränkungen und Haftungs-, Gewährleistungs- oder Verantwortungsausschlüssen unberührt.
            </p>
            <p>
                (4) Sie stellen uns von etwaigen Ansprüchen Dritter, die wegen möglicher schuldhafter Verletzungen des Partners gegen seine Pflichten – insbesondere aus diesen AGB – gegen uns und/oder unseren Erfüllungsgehilfen geltend gemacht werden, auf erstes Anfordern frei. Sie ersetzen uns jeglichen Schaden, der wegen der Inanspruchnahme durch den Dritten entsteht, einschließlich etwaiger für die Rechtsverteidigung anfallenden Gerichts- und Anwaltskosten. Im Übrigen gelten die gesetzlichen Bestimmungen.
            </p>
            <p>
                (5) Wir haben Anspruch auf Ersatz der Aufwendungen, die wir den Umständen nach für erforderlich halten durften und nicht zu vertreten hatten, insbesondere jegliche Aufwendungen zum Schutz des Vertragsgutes sowie daneben auf eine ortsübliche, angemessene Vergütung.
            </p>
            <h3>§ 21 Leistungsort, Anwendbares Recht, Vertragssprache und Gerichtsstand</h3>
            <p>
                (1) Für alle Leistungen aus dem Vertrag wird als Erfüllungsort Köln vereinbart.
            </p>
            <p>
                (2) Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Sind sowohl Sie als auch wir zum Zeitpunkt des Vertragsschlusses Kaufleute und haben Sie Ihren Sitz zum Zeitpunkt des Vertragsschlusses in Deutschland, ist ausschließlicher Gerichtsstand unser Sitz in Köln. Im Übrigen gelten für die örtliche und die internationale Zuständigkeit die anwendbaren gesetzlichen Bestimmungen.
            </p>
            <p>
                (3) Vertragssprache ist, soweit nichts Anderes schriftlich vereinbart ist, Deutsch. Jegliche übersetzten Rechtstexte oder Dokumente dienen alleine einem besseren Verständnis. Insbesondere in Bezug auf eine Vertragsabrede als auch auf diese AGB, die Datenschutzbestimmungen oder alle anderen Rechtstexte oder Dokumente sind die deutschen Versionen rechtsverbindlich; dies gilt insbesondere bei Abweichungen oder Auslegungsunterschieden zwischen solchen Rechtstexten oder Dokumenten.
            </p>
            <p>
                (4) In Bezug auf Streitigkeiten mit Verbrauchern hat die   EU-Kommission eine Internetplattform zur Online-Streitbeilegung geschaffen – die alternative Streitbeilegung nach der ODR-Verordnung und § 36 VSBG. Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen. Nähere Informationen sind unter dem folgenden Link verfügbar: <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>
            </p>
            <p>
                Die Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle ist nicht verpflichtend und wird von uns nicht wahrgenommen.
            </p>
            <h3>§ 22 Schlussbestimmungen</h3>
            <p>
                (1) Änderungen und Ergänzungen dieser AGB erfolgen schriftlich, das Recht hierzu behalten wir uns vor. Änderungen setzen voraus, dass Sie nicht unangemessen benachteiligt werden, kein Verstoß gegen Treu und Glauben geschieht und der Änderung nicht widersprochen wird. Im Fall einer Änderung erfolgt eine Mitteilung über einen der Kommunikationskanäle – insbesondere per E-Mail – 2 Monate vor ihrer Wirksamkeit. Die Änderung wird wirksam, wenn ihr nicht innerhalb dieser Frist widersprochen wird – hiernach werden die geänderten AGB gültig.
            </p>
            <p>
                (2) Eine Abtretung dieses Vertrags an ein anderes Unternehmen wird vorbehalten. Sie wird 1 Monat nach Absendung einer Abtretungsmitteilung über einen unserer Kommunikationskanäle – insbesondere per E-Mail – an Sie gültig. Sie haben im Fall einer Abtretung ein Kündigungsrecht, welches 1 Monat nach Zugang der Mitteilung der Abtretung gilt. Alle uns eingeräumten Rechte gelten zugleich als unseren Rechtsnachfolgern eingeräumt.
            </p>
            <p>
                (3) Im Falle der Unwirksamkeit einzelner Bestimmungen dieser AGB, wird die Rechtswirksamkeit der übrigen Bestimmungen nicht berührt. Die unwirksame Bestimmung wird durch eine wirksame Bestimmung ersetzt, die dem beabsichtigten wirtschaftlichen Zweck am nächsten kommt.
            </p>
        </div>
    );
};

export default TermsDe;