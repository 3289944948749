import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';

const Login = function () {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const navigate = useNavigate(); // create a history object

    const login = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        // validation can be added here

        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/login`, { email, password }, { withCredentials: true });
            setIsLoading(false);
            setMessage('🚀' + res.data.message);
            // Redirect to '/home' after 1 second
            if (res.data.isRedirect) {
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            }
        } catch (err) {
            setIsLoading(false);
            // Check if the server sent a response status
            if (err.response) {
                // Differentiate between a 400 and 401 error
                if (err.response.status === 400) {
                    setMessage('❗ Invalid username and/or password');
                } else if (err.response.status === 401) {
                    setMessage('❗ Incorrect username and/or password');
                } else if (err.response.status === 418) {
                    setMessage('❗ Please verify your Email first (check your email inbox for more info)');
                } else if (err.response.status === 429) {
                    setMessage('❗ You have made too many login requests, please try again later');
                } else {
                    setMessage('❗ Our server doesn&apos;nt seem to be responding. Please wait and try again');
                }
            }
            else {
                setMessage(`❗ Our server doesn't seem to be responding. Please wait and try again`);
            }
        }
    }


    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Login</title>
                <meta name="description" content="Have a generatebg account already? Log in to use access the services." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/login`} />
            </Helmet>
            <h1 className="form-title">Enter Email and Password to Login</h1>
            <form onSubmit={login} className="user-form form-card">
                <label className="user-label">
                    Email:
                    <input className="user-input" type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                </label>
                <label className="user-label">
                    Password:
                    <input className="user-input" type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                </label>
                <button type="submit" className="user-button">Login</button>
                <a href="/request-password-reset" className="forgot-link">Forgot password?</a>
                {isLoading ? (
                    <div className="app-loading-container">
                        <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="app-loading-spinner">
                            <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                                <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                                <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                            </g>
                        </svg>
                    </div>
                ) : (
                    message && <p className={`user-message`}>{message}</p>
                )
                }
            </form>
        </div>
    );
}

export default Login;