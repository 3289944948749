import React, { useState } from 'react';
import axios from 'axios';
import '../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';

const RequestPasswordReset = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const requestReset = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/request-password-reset`, { email }, { withCredentials: true });
            setIsLoading(false);
            setMessage(res.data.message);
            setStatus('success');
        } catch (err) {
            if (err.response.status === 429) {
                setMessage('❗ You have made too password-reset requests, please try again later');
                setStatus('failure');
            } else {
                setMessage('❗ An error occurred while requesting password reset.');
                setStatus('failure');
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Request Password Reset</title>
                <meta name="description" content="Forgot your generatebg account password? Request for a password reset here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/request-password-reset`} />
            </Helmet>
            <h1>Forgot Your Password? Enter Your Email.</h1>
            <form onSubmit={requestReset} className="user-form form-card">
                <label className="user-label">
                    Email:
                    <input type="email" className="user-input" value={email} onChange={e => setEmail(e.target.value)} required />
                </label>
                <button type="submit" className="user-button">Request Password Reset</button>
                {isLoading ? (
                    <div className="app-loading-container">
                        <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="app-loading-spinner">
                            <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                                <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                                <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                            </g>
                        </svg>
                    </div>
                ) : (
                    message && <p className={`user-message ${status}`}>{message}</p>
                )
                }
            </form>
        </div>
    );
}

export default RequestPasswordReset;