import React, { useState, useEffect } from 'react';
import '../styles/DocsStyles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import IsThisProductForYou from './SubDocs/IsThisProductForYou';
import HowToUseThisProduct from './SubDocs/HowToUseThisProduct';
import UseCases from './SubDocs/useCases';
import { Helmet } from 'react-helmet-async';

const Docs = () => {
    const navigate = useNavigate();
    const { docTitle } = useParams();
    const documents = [
        { title: 'Is Generatebg the Right Choice for You?', component: <IsThisProductForYou /> },
        { title: 'How to Use Our AI Background Generator?', component: <HowToUseThisProduct /> },
        { title: 'Use Cases for Our Background Generator', component: <UseCases /> },
        // Add more documents here
    ];

    const [selectedDoc, setSelectedDoc] = useState(documents[0]); // default to the first doc


    useEffect(() => {
        if (!docTitle) {
            // Only update the URL if docTitle is not present
            const firstDocTitle = documents[0].title.replaceAll(' ', '-').toLowerCase();
            navigate(`/docs/${firstDocTitle}`);
        } else {
            const normalizedDocTitle = decodeURIComponent(docTitle).replaceAll('-', ' ').toLowerCase();
            // Find the doc title, switch to lower case, and remove all special chars (like ?) except spaces
            const foundDoc = documents.find(doc => doc.title.toLowerCase().replace(/[^\w\s]/g, '') === normalizedDocTitle);

            if (foundDoc) {
                setSelectedDoc(foundDoc);
                navigate(`/docs/${foundDoc.title.replaceAll(' ', '-').toLowerCase()}`);
            } else {
                // Redirect to the default doc or show an error
                const firstDocTitle = documents[0].title.replaceAll(' ', '-').toLowerCase();
                navigate(`/docs/${firstDocTitle}`);
            }
        }
    }, []); // Empty dependency array to run only on mount

    // useEffect(() => {
    //     const doc = documents.find(d => d.title.replaceAll(' ', '-').toLowerCase() === docTitle);
    //     if (doc) {
    //         setSelectedDoc(doc);
    //         navigate(`/docs/${doc.title.replaceAll(' ', '-').toLowerCase()}`);
    //     }
    // }, [docTitle]);

    const handleBackRequest = () => {
        navigate("/");
    };

    const selectDocument = (doc) => {
        setSelectedDoc(doc);
        // First replace all special chars except spaces and hyphens, then replace spaces with hyphens, and finally, switch to lower case
        navigate(`/docs/${doc.title.replace(/[^\w\s-]/g, '').replaceAll(' ', '-').toLowerCase()}`);
    };

    return (
        <div className="docs-main-container">
            <h1 className="docs-title">Docs</h1>
            <Helmet>
                <title>generatebg - Docs</title>
                <meta name="description" content="Looking for something? Checkout the generatebg docs." />

                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/docs`} />
            </Helmet>
            <button className="generic-docs-button docs-button" onClick={handleBackRequest}>Back Home</button>
            <div className="docs-layout">
                <div className="sidebar">
                    <select className="docs-dropdown" onChange={(e) => selectDocument(documents[e.target.value])} value={documents.findIndex(doc => doc.title === selectedDoc.title)}>
                        {documents.map((doc, index) => (
                            <option key={index} value={index}>{doc.title}</option>
                        ))}
                    </select>
                    <div className="docs-links">
                        {documents.map((doc, index) => (
                            <a className="doc-link" key={index} onClick={() => selectDocument(doc)}>
                                {doc.title}
                            </a>
                        ))}
                    </div>
                </div>
                <div className="main-content--body">
                    {selectedDoc ? selectedDoc.component : <p>Select a document to view</p>}
                </div>
            </div>
        </div>
    );
};

export default Docs;
