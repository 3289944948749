import PackageCard from './PackageCard';
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import '../styles/paymentManagementStyles.scss';
import LoadingSpinner from '../LoadingSpinner';
import GenericError from './GenericError';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from 'react-helmet-async';

const Pricing = () => {
    const { isAuthorized } = useContext(AuthContext);
    const [subscriptionPackages, setSubscriptionPackages] = useState([]);
    const [payAsYouGoPackages, setPayAsYouGoPackages] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState([]);
    const [selectedImageToGo, setSelectedImageToGo] = useState([]);
    const [hasActivePackage, setHasActivePackage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showSubscriptionWarning, setShowSubscriptionWarning] = useState(false);
    const [showServiceStatusWarning, setShowServiceStatusWarning] = useState(false);
    const [isServiceDeclined, setIsServiceDeclined] = useState(false);
    const activeSubscriptionWarning = "You already have an active package. Please use your plan or unsubscribe before purchasing another plan.";
    const serviceDeclinedWarning = "Apologies. We are currently unable to fulfill your request.";

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-subscription`, { withCredentials: true });
                setHasActivePackage(response.data.hasActivePackage);
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            } finally {
                setIsLoading(false);
            }
        };
        if (isAuthorized) {
            fetchSubscriptionStatus();
        }
    }, []);

    useEffect(() => {
        const checkServiceStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/check-service-status`, { withCredentials: true });
                setIsServiceDeclined(response.data.isServiceDeclined);
            } catch (error) {
                console.error("Error fetching service status:", error);
            } finally {
                setIsLoading(false);
            }

            if (isAuthorized) {
                checkServiceStatus();
            }
        };


    }, []);


    useEffect(() => {
        // Fetch available packages from backend using axios
        const fetchPackages = async () => {
            try {
                const subscriptionResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/payment/get-packages`, { params: { type: 'subscription' } });
                setSubscriptionPackages(subscriptionResponse.data);

                const payAsYouGoResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/payment/get-packages`, { params: { type: 'imageToGo' } });
                setPayAsYouGoPackages(payAsYouGoResponse.data);
            } catch (error) {
                console.error("Error fetching packages:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPackages();
    }, []);

    const handleCheckout = async (pkg) => {
        if (hasActivePackage) {
            setShowSubscriptionWarning(true);
            return;
        }

        if (isServiceDeclined) {
            setShowServiceStatusWarning(true);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/payment/create-checkout-session`,
                { packageId: pkg._id, },
                { withCredentials: true }
            );

            const sessionId = response.data.id;
            const stripe = await window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
                console.error('Stripe redirectToCheckout error:', error);
            }
        } catch (error) {
            // console.log('error.response.status: ', error.response.status);
            if (error.response.status === 503) {
                setShowServiceStatusWarning(true);
            }
            console.error("Error initiating checkout:", error);
            // Optionally, display an error message to the user here.
        }
    }

    return (
        <div className="pricing-main-container">
            <Helmet>
                <title>generatebg - Pricing</title>
                <meta name="description" content="Check out the pricing for generatebg background removal plans here. We offer both one-time and monthly plans." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/pricing`} />
            </Helmet>
            <h1 className="pricing-title">Generate High-Res Backgrounds</h1>
            <p className="pricing-text">🚀 <b>2048 x 2048 </b> Pixels. Ideal for crisp photography, high-quality graphics, social media, and print.</p>
            <br></br>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className='pricing-secondary-container'>
                    {showSubscriptionWarning && <GenericError errorMessage={activeSubscriptionWarning} />}
                    {showServiceStatusWarning && <GenericError errorMessage={serviceDeclinedWarning} />}
                    <PackageCard
                        title="Free Plan"
                        packageType="free"
                    />
                    <PackageCard
                        title="Monthly Plans"
                        packageType="subscription"
                        packages={subscriptionPackages}
                        isHighlighted={true}
                        selectedPkg={selectedSubscription}
                        setSelectedPkg={setSelectedSubscription}
                        handleCheckout={() => handleCheckout(selectedSubscription)}
                    />
                    <PackageCard
                        title="One-time Plans"
                        packageType="imageToGo"
                        packages={payAsYouGoPackages}
                        selectedPkg={selectedImageToGo}
                        setSelectedPkg={setSelectedImageToGo}
                        handleCheckout={() => handleCheckout(selectedImageToGo)}
                    />
                </div>
            )}
            <div className="pricing-tertiary-container">
                <p className="pricing-text">🚀 <b>1 Credit = 1 High-res Image</b> (2048 x 2048 px) and <b>16 Previews</b> (512 x 512 px).</p>
                <p className="pricing-text">🚀 Previews don't use any credits. <b>You pay only (with 1 credit) when you choose to generate a high-res image</b> using a preview.</p>
                <p className="pricing-text">🚀 <b>Example:</b> Buy 10 credits, get 160 low-res previews (512 x 512 px) and 10 high-res images (2048 x 2048 px).</p>
                <p className="pricing-text">🚀 Prices are inclusive of VAT, if applicable.</p>
            </div>
            {/* <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br> */}
            {/* <h3 className="pricing-title">Generate Low-Res Backgrounds</h3>
            <p className="pricing-text"><b>512 x 512 </b> Pixels. Ideal for crisp photography, high-quality graphics, social media, and print.</p>
            <br></br> */}
        </div>
    );
};

// Note: The handleCheckout function would initiate the Stripe Checkout process using the selected package's details.

export default Pricing;