import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';

const ResetPassword = () => {
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [passwordMismatchError, setPasswordMismatchError] = useState("");
    const [status, setStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Handle password mismatch
    useEffect(() => {
        if (password !== confirmPassword) {
            setPasswordMismatchError('Passwords do not match.');
            setStatus('failure');
        } else {
            setPasswordMismatchError('');
            setStatus(null);
        }
    }, [password, confirmPassword, setPasswordMismatchError, setStatus]);

    // Handle token extraction from URL
    useEffect(() => {
        // Extract the token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const extractedToken = urlParams.get('token');

        // Set the token
        if (extractedToken) {
            setToken(extractedToken);
        }
    }, []);

    const resetPassword = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/reset-password`, {
                token,
                newPassword: password,
                confirmNewPassword: confirmPassword
            }, { withCredentials: true });
            setIsLoading(false);
            setMessage(res.data.message);
            setStatus('success');
            // Redirect to '/login' after a delay
            setTimeout(() => {
                navigate('/login');
            }, 4000); // 4000ms delay. Adjust as needed.
        } catch (err) {
            setIsLoading(false);
            // console.log('err.response: ', err.response); // Debug code - remove later in prod
            // Update to display the error message from the backend
            if ((err.response.status === 400) && err.response.data) {
                setMessage('❗ ' + err.response.data);
            } else {
                setMessage('An error occurred while resetting the password.');
            }
        }
    }

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Password Reset</title>
                <meta name="description" content="Do you have a valid password-reset-link from generatebg? Reset your account password here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/reset-password`} />
            </Helmet>
            <h1>Reset Password</h1>
            <form onSubmit={resetPassword} className="user-form form-card">
                <label className="user-label">
                    New Password:
                    <p className="small-text">(min. 8 chars - 1 num, 1 lowercase, 1 uppercase, & 1 special char (hyphen/dash '-' not allowed))</p>
                    <input type="password" className="user-input" value={password} onChange={e => setPassword(e.target.value)} required />
                </label>
                <label className="user-label">
                    Confirm New Password:
                    <p className="small-text">(min. 8 chars - 1 num, 1 lowercase, 1 uppercase, & 1 special char (hyphen/dash '-' not allowed))</p>
                    <input type="password" className="user-input" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
                </label>
                {passwordMismatchError && <p className="small-text failure">{passwordMismatchError}</p>}
                <button
                    type="submit"
                    className={`user-button ${passwordMismatchError || !password || !confirmPassword ? 'user-button-disabled' : ''}`}
                    disabled={passwordMismatchError || !password || !confirmPassword}>Reset Password
                </button>
                {isLoading ? (
                    <div className="app-loading-container">
                        <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none" className="app-loading-spinner">
                            <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                                <path d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z" opacity=".2" />
                                <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
                            </g>
                        </svg>
                    </div>
                ) : (
                    message && <p className={`user-message ${status}`}>{message}</p>
                )
                }
            </form>
        </div>
    );
}

export default ResetPassword;