import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import RasterToVectorAuthorized from './SubComponents/RasterToVectorAuthorized';
import RasterToVectorLanding from './SubComponents/RasterToVectorLanding';
import 'react-image-crop/dist/ReactCrop.css';
import '../styles/CommonComponentStyles.scss';

const RasterToVector = () => {
    const { isAuthorized } = useContext(AuthContext);


    return (
        <>
            <div className="common-styles">

                {isAuthorized ? <RasterToVectorAuthorized /> : <RasterToVectorLanding />}
            </div>
        </>
    );
};

export default RasterToVector;