import React from 'react';
import './styles/CookiePopup.scss'; // Import your CSS file for styling

const CookiePopup = ({ onAccept, onReject }) => {
    return (
        <div className="cookie-popup">
            <p>

                We use cookies to enhance your experience and for analytical purposes. You have the option to disable them anytime.
                You can read our <a href={`${process.env.REACT_APP_CLIENT_URL}/cookie-policy`}>cookie policy here.</a>
            </p>
            <div className="cookie-buttons">
                <button onClick={onAccept} className="generic-account-button accept">Accept all</button>
                <button onClick={onReject} className="generic-account-button reject">Reject all</button>
            </div>
        </div>
    );
};

export default CookiePopup;
