import React, { useState, useRef, useEffect } from 'react';
import './styles/ImageSliderStyles.scss';

const ImageSlider = ({ image1, image2 }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [sliderPosition, setSliderPosition] = useState(50);
    const sliderRef = useRef(null);

    const updateSliderPosition = (clientX) => {
        const rect = sliderRef.current.getBoundingClientRect();
        const newPosition = ((clientX - rect.left) / rect.width) * 100;
        setSliderPosition(Math.min(Math.max(newPosition, 0), 100)); // Clamps value between 0 and 100
    };

    const handleMouseDown = (event) => {
        setIsDragging(true);
        updateSliderPosition(event.clientX);
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            updateSliderPosition(event.clientX);
        }
    };

    const handleTouchMove = (event) => {
        if (isDragging) {
            updateSliderPosition(event.touches[0].clientX);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleContainerClick = (event) => {
        updateSliderPosition(event.clientX);
    };

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    return (
        <div
            className="image-slider"
            ref={sliderRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
            onClick={handleContainerClick}
        >
            <img src={image1} alt="A spice grinder placed on a wooden floor" className="slider-image" />
            <div className="slider-image slider-image-overlay" style={{ width: `${sliderPosition}%` }}>
                <img src={image2} alt="The same spice grinder with a beautiful AI generated background with spices and pots around" className="generated-image" />
            </div>
            <div
                className="slider-handle"
                style={{ left: `${sliderPosition}%` }}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
            >
                <div className="slider-arrow"></div>
            </div>
            <span className="image-label before-label">Before</span>
            <span className="image-label after-label">After</span>
        </div>
    );
};

export default ImageSlider;
