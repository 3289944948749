import './App.scss';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import Header from './components/Header';
import { AuthProvider } from './context/AuthContext';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/userManagement/Register';
import Login from './components/userManagement/Login';
import Logout from './components/userManagement/Logout';
import ProcessImage from './components/imageProcessing/ProcessImage';
import GenerateBackground from './components/imageProcessing/GenerateBackground';
import PurchasePackageMessage from './components/imageProcessing/PurchasePackageMessage';
import RequestPasswordReset from './components/userManagement/RequestPasswordReset';
import ResetPassword from './components/userManagement/ResetPassword';
import VerifyEmail from './components/userManagement/VerifyEmail';
import ProtectedRoute from './components/ProtectedRoute';
import RemoveBackground from './components/imageProcessing/RemoveBackground';
import Pricing from './components/paymentManagement/Pricing';
import PaymentSuccess from './components/paymentManagement/PaymentSuccess';
import PaymentCanceled from './components/paymentManagement/PaymentCanceled';
import Account from './components/userManagement/Account';
import Invoice from './components/userManagement/Invoice';
import GeneratedImages from './components/imageProcessing/GeneratedImages';
import UpscaledImages from './components/imageProcessing/UpscaledImages';
import FreeMattedImages from './components/imageProcessing/FreeMattedImages';
import RegenerateBackgrounds from './components/imageProcessing/RegenerateBackgrounds';
// Turning off invitation-system in production as of version 0.0.2 on 20.12.2023
// import InviteFriend from './components/userManagement/InviteFriend';
import Help from './components/helpAndSupport/Help';
import Docs from './components/helpAndSupport/Docs';
import Contact from './components/helpAndSupport/Contact';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ManageSubscription from './components/userManagement/ManageSubscription';
import GeneralTermsAndConditions from './components/LegalDocs/GeneralTermsAndConditions';
import CookiePolicy from './components/LegalDocs/CookiePolicy';
import PrivacyPolicy from './components/LegalDocs/PrivacyPolicy';
import Imprint from './components/LegalDocs/Imprint';
import Footer from './components/Footer';
import CookiePopup from './components/CookiePopup';
import getLocalStorageItem from './utils/getLocalStorageItem';
import setLocalStorageItem from './utils/setLocalStorageItem';
import UpscaleImage from './components/imageProcessing/UpscaleImage';
import TagManager from 'react-gtm-module';
import UnsubscribeFromEmails from './components/userManagement/UnsubscribeFromEmails';
import LoginRequired from './components/LoginRequired';
import FreeTools from './components/FreeTools';
import RasterToVector from './components/imageProcessing/RasterToVector';

// This is the new ProtectedRoute component
const RequireCroppedImageID = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const croppedImageID = location.state?.croppedImageID;

    // If transImageID is not provided, navigate back to the process image page
    if (!croppedImageID) {
        navigate('/process');
        return null; // Return null while the navigation is happening
    }

    return children; // If transImageID is provided, render the children (i.e., the intended component)
};

// This is the new ProtectedRoute component
const RequireGeneratedImageID = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const generatedImageID = location.state?.generatedImageID;

    // If transImageID is not provided, navigate back to the process image page
    if (!generatedImageID) {
        navigate('/process');
        return null; // Return null while the navigation is happening
    }

    return children; // If transImageID is provided, render the children (i.e., the intended component)
};

function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showCookiePopup, setShowCookiePopup] = useState(false);
    const tagManagerArgs = {
        gtmId: 'GTM-5KJV8K64', // Replace 'GTM-XXXXXX' with your actual GTM ID
    };

    // Dynamically turn on and off menu toggling on screen resize - START ***********************
    const checkScreenWidth = () => {
        if (window.innerWidth > 768 && isMenuOpen) {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, [isMenuOpen]);

    const toggleMenu = () => {
        if (window.innerWidth <= 768) {
            setIsMenuOpen(!isMenuOpen);
        }
    };
    // Dynamically turn on and off menu toggling on screen resize - END ************************

    // UseEffect to check and set the state of the cookie popup to true if localstorage does not have an "accepted" state
    useEffect(() => {
        const cookieAccepted = getLocalStorageItem('cookieAccepted');
        if (!cookieAccepted || cookieAccepted === 'false') {
            setShowCookiePopup(true);
        } else {
            TagManager.initialize(tagManagerArgs); // Initialize GTM
            // console.log('tag_manager_initialized: ', tagManagerArgs);
        }
    }, []);

    // Handle cookie acceptance
    const handleCookieAccept = () => {
        setLocalStorageItem('cookieAccepted', 'true', 7 * 24 * 60 * 60 * 1000); // Expires in 7*24 hours
        setShowCookiePopup(false);
        TagManager.initialize(tagManagerArgs); // Initialize GTM
    };

    // Handle cookie rejection
    const handleCookieReject = () => {
        setLocalStorageItem('cookieAccepted', 'false', 1 * 60 * 60 * 1000); // Expires in 1 hour
        setShowCookiePopup(false);
    };

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
        // advancedFraudSignals: false
    });

    return (
        <>
            <div className="App">
                <AuthProvider>
                    <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                    <div className={`main-content ${isMenuOpen ? 'nav-open' : ''}`}>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/login-required" element={<LoginRequired />} />
                            <Route path="/logout" element={
                                // <ProtectedRoute>
                                <Logout />
                                // </ProtectedRoute>
                            } />
                            <Route path="/free-tools" element={
                                <FreeTools />
                            } />
                            <Route path="/free-tools/remove-background" element={
                                <RemoveBackground />
                            } />
                            <Route path="/free-tools/raster-to-vector" element={
                                <RasterToVector />
                            } />
                            <Route path="/process" element={
                                <ProtectedRoute>
                                    <ProcessImage />
                                </ProtectedRoute>
                            } />
                            <Route path="/verify-email" element={<VerifyEmail />} />
                            <Route path="/process/generate-background" element={
                                <ProtectedRoute>
                                    <RequireCroppedImageID>
                                        <GenerateBackground />
                                    </RequireCroppedImageID>
                                </ProtectedRoute>
                            } />
                            <Route path="/process/generate-highres-image" element={
                                <ProtectedRoute>
                                    <RequireGeneratedImageID>
                                        <UpscaleImage />
                                    </RequireGeneratedImageID>
                                </ProtectedRoute>
                            } />
                            <Route path="/pricing" element={
                                <Elements stripe={stripePromise}>
                                    <Pricing />
                                </Elements>
                            } />
                            <Route path="/docs" element={
                                <Docs />
                            } />
                            <Route path="/docs/:docTitle" element={<Docs />} />
                            <Route path="/payment/success" element={
                                <ProtectedRoute>
                                    <Elements stripe={stripePromise}>
                                        <PaymentSuccess />
                                    </Elements>
                                </ProtectedRoute>
                            } />
                            <Route path="/payment/cancel" element={
                                <ProtectedRoute>
                                    <Elements stripe={stripePromise}>
                                        <PaymentCanceled />
                                    </Elements>
                                </ProtectedRoute>
                            } />
                            <Route path="/account" element={
                                <ProtectedRoute>
                                    <Account />
                                </ProtectedRoute>
                            } />
                            <Route path="/account/invoice" element={
                                <ProtectedRoute>
                                    <Invoice />
                                </ProtectedRoute>
                            } />
                            <Route path="/account/upscaled-images" element={
                                <ProtectedRoute>
                                    <UpscaledImages />
                                </ProtectedRoute>
                            } />
                            <Route path="/account/generated-images" element={
                                <ProtectedRoute>
                                    <GeneratedImages />
                                </ProtectedRoute>
                            } />
                            <Route path="/account/background-removed-images" element={
                                <ProtectedRoute>
                                    <FreeMattedImages />
                                </ProtectedRoute>
                            } />
                            <Route path="/account/regenerate-backgrounds" element={
                                <ProtectedRoute>
                                    <RegenerateBackgrounds />
                                </ProtectedRoute>
                            } />
                            {/* Turning off invitation-system in production as of version 0.0.2 on 20.12.2023 */}
                            {/* <Route path="/account/invite-friend" element={
                                <ProtectedRoute>
                                    <InviteFriend />
                                </ProtectedRoute>
                            } /> */}
                            <Route path="/account/help" element={
                                <ProtectedRoute>
                                    <Help />
                                </ProtectedRoute>
                            } />
                            <Route path="/account/help/contact" element={
                                <ProtectedRoute>
                                    <Contact />
                                </ProtectedRoute>
                            } />
                            <Route path="/account/manage-subscription" element={
                                <ProtectedRoute>
                                    <ManageSubscription />
                                </ProtectedRoute>
                            } />
                            <Route path="/service-declined" element={
                                <ProtectedRoute>
                                    <PurchasePackageMessage />
                                </ProtectedRoute>
                            } />
                            <Route path="/unsubscribe-emails" element={
                                <ProtectedRoute>
                                    <UnsubscribeFromEmails />
                                </ProtectedRoute>
                            } />
                            <Route path="/request-password-reset" element={<RequestPasswordReset />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/general-terms-conditions" element={<GeneralTermsAndConditions />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/cookie-policy" element={<CookiePolicy />} />
                            <Route path="/imprint" element={<Imprint />} />
                            <Route path="/" element={<Home />} />
                            // Catch-all route
                            <Route path="*" element={<Navigate replace to="/" />} />
                        </Routes>
                    </div>
                    {showCookiePopup && <CookiePopup onAccept={handleCookieAccept} onReject={handleCookieReject} />}
                    <Footer></Footer>
                </AuthProvider>
            </div>
        </>
    );
}

export default App;