import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import '../../styles/CommonComponentStyles.scss';
// import '../../styles/RasterToVectorLandingStyles.scss';
import { Helmet } from 'react-helmet-async';
import ImageSlider from './ImageSliderRemoveBackground';
import FAQSection from './FAQSectionRemoveBackground';
import spiceGrinderInput from '../../../assets/spice_grinder_input_500.jpg';
import spiceGrinderBackgroundRemoved from '../../../assets/spice_grinder_background_removed.png';
import { ReactComponent as TrustPilotLogo } from '../../../assets/asSeenOn/trustpilot.svg';
import { ReactComponent as ProductHuntLogo } from '../../../assets/asSeenOn/producthunt.svg';


const RemoveBackgroundLanding = () => {

    const navigate = useNavigate();


    const handleGetStartedRequest = () => {
        navigate("/register");
    }

    return (
        <>
            <div className="App-home">
                <Helmet>
                    <title>generatebg - Remove Background</title>
                    <meta name="description" content="Remove background from your input image here." />
                    <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/remove-background`} />
                </Helmet>

                <div className="App-landing--primary-block">
                    <div className="App-landing-hero-left">
                        <ImageSlider
                            image1={spiceGrinderBackgroundRemoved}
                            image2={spiceGrinderInput}
                        />
                    </div>
                    <div className="App-landing-hero-right">
                        <h1 className="site-title">
                            {/* <span className="rocket--small">🚀</span>  */}
                            Remove background from image with one click!
                            {/* Generate High-res Backgrounds In An Instant */}
                        </h1>
                        <h3 className="site-super-subtitle">Free, high-quality, & easy to use!</h3>
                        <h3 className="site-super-subtitle">Instant downloads!</h3>
                        <div className="call-to-action-section">
                            <button className="generic-button" onClick={handleGetStartedRequest}>Remove Background</button>
                        </div>
                    </div>
                </div>

                <div className="hero-footer-logos">
                    <span>as seen on</span>
                    {/* <TrustPilotLogo className="hero-footer-logo" /> */}
                    {/* <img src={TrustPilotLogo}
                    alt="Trustpilot logo"
                    className="hero-footer-logo">
                </img> */}
                    <TrustPilotLogo className="hero-footer-logo trustpilot" />
                    <ProductHuntLogo className="hero-footer-logo producthunt" />
                    {/* <img src={HackerNewsLogo}
                    alt="Hacker News logo"
                    className="hero-footer-logo hacker-news">
                </img> */}
                </div>

                <div className="App-landing--secondary-block">
                    <h3 className="faq--heading">Frequently Asked Questions</h3>
                    <FAQSection />
                </div>
            </div>
        </>
    );
};

export default RemoveBackgroundLanding;