import React, { useState } from 'react';
import '../styles/CommonComponentStyles.scss';
import { useNavigate } from 'react-router-dom';
import CookiesEn from './core_content/CookiesEn.js'; // English version
import CookiesDe from './core_content/CookiesDe.js'; // German version
import { Helmet } from 'react-helmet-async';

const CookiePolicy = () => {
    const navigate = useNavigate();
    const [isEnglish, setIsEnglish] = useState(true); // State to toggle between languages

    // Routes one level back to /account/help
    const handleBackRequest = () => {
        navigate("/");
    };

    const toggleLanguage = () => {
        setIsEnglish(!isEnglish); // Toggle the language state
    };

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Cookie Policy</title>
                <meta name="description" content="Read generatebg cookie policy here." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/cookie-policy`} />
            </Helmet>
            <button className="generic-account-button--center" onClick={handleBackRequest}>Go Back Home</button>
            <button className="generic-account-button--center" onClick={toggleLanguage}>
                {isEnglish ? 'Switch to German' : 'Auf Englisch wechseln'}
            </button>
            {isEnglish ? <CookiesEn /> : <CookiesDe />}
        </div>
    );
};

export default CookiePolicy;
