import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../styles/CommonComponentStyles.scss';

const VerifyEmail = () => {
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const executed = useRef(false);

    useEffect(() => {
        // Extract the token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        // If there's no token, set an error message
        if (!token) {
            setMessage("Invalid or missing verification token.");
            return;
        }

        // Send the token to the backend for verification
        const verifyEmail = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/verify-registration-email`, { token }, { withCredentials: true });
                setMessage(response.data.message);
                setTimeout(() => {
                    navigate('/login');
                }, 6000);
            } catch (error) {
                setMessage(error.response?.data?.message || "An error occurred during verification.");
            }
        }

        // Call the async function
        if (process.env.NODE_ENV === 'production' || !executed.current) {
            verifyEmail();
            executed.current = true;
        }

    }, [navigate]);

    const handleDocsRequest = () => {
        navigate("/docs");
    };

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Verify Email</title>
                <meta name="description" content="Did you signup for a generatebg account? Verfiy your email address before you can access the services." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/verify-email`} />
            </Helmet>

            <h1>Email Verification</h1>
            <p className="text-center">{message}</p>
            <p className="text-center">Refer to our docs to see if Generatebg is the right choice for you:</p>
            <button className="generic-account-button--center" onClick={handleDocsRequest}>Docs</button>
        </div >
    );
}

export default VerifyEmail;