import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import RemoveBackgroundAuthorized from './SubComponents/RemoveBackgroundAuthorized';
import RemoveBackgroundLanding from './SubComponents/RemoveBackgroundLanding';
import 'react-image-crop/dist/ReactCrop.css';
import '../styles/CommonComponentStyles.scss';

const RemoveBackground = () => {
    const { isAuthorized } = useContext(AuthContext);

    return (
        <>
            <div className="common-styles">
                {isAuthorized ? <RemoveBackgroundAuthorized /> : <RemoveBackgroundLanding />}
            </div>
        </>
    );
};

export default RemoveBackground;