import React, { createContext, useReducer } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const ACTION = {
    CHECK_USER: 'CHECK_USER',
    SET_USER: 'SET_USER',
    SET_ERROR: 'SET_ERROR',
    REGISTER: 'REGISTER',
    CLEAR_ERROR: 'CLEAR_ERROR',
    START_LOGOUT: 'START_LOGOUT',
    FINISH_LOGOUT: 'FINISH_LOGOUT',
};

// Initial state
const initialState = {
    user: null,
    isAuthorized: false,
    isLoading: true,
    error: null,
    registrationSuccess: null,
    isLoggingOut: false,
};

// Create context
export const AuthContext = createContext(initialState);

// Reducer
function authReducer(state, action) {
    switch (action.type) {
        case ACTION.SET_USER:
            return {
                ...state,
                user: action.payload,
                isAuthorized: action.payload !== null,
                isLoading: false
            };
        case ACTION.CHECK_USER:
            return {
                ...state,
                isAuthorized: false,
                isLoading: true,
                error: null
            };
        case ACTION.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case ACTION.REGISTER:
            return {
                ...state,
                registrationSuccess: action.payload,
            };
        case ACTION.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case ACTION.START_LOGOUT:
            return {
                ...state,
                isLoggingOut: true,
            };
        case ACTION.FINISH_LOGOUT:
            return {
                ...state,
                isLoggingOut: false,
            };
        default:
            return state;
    }
}

// Provider component
export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    // Actions
    const setUser = (user) => {
        dispatch({
            type: ACTION.SET_USER,
            payload: user,
        });
    };

    const setError = (error) => {
        dispatch({
            type: ACTION.SET_ERROR,
            payload: error,
        });
    };

    const clearError = () => {
        dispatch({ type: ACTION.CLEAR_ERROR });
    };

    const startLogout = () => {
        dispatch({ type: ACTION.START_LOGOUT });
    };

    const finishLogout = () => {
        dispatch({ type: ACTION.FINISH_LOGOUT });
        setTimeout(() => {
            navigate('/'); // Move the navigation logic here to ensure it happens after the state is updated
        }, 1000);
    };

    // User authorisation logic

    const checkUserSession = async () => {

        //You can perhaps use the isLoading flag here to display a loading screen of some sort
        dispatch({
            type: ACTION.CHECK_USER,
        });

        try {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/get-user`, { withCredentials: true });
            setUser(res.data.user);
        } catch (error) {
            let errorMessage;
            if (error.response) {
                if (error.response.status === 401) {
                    try {
                        // Try to refresh the token
                        const refreshRes = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/refresh-token`, {}, { withCredentials: true });
                        setUser(refreshRes.data.user);
                        return;
                    } catch (refreshError) {
                        // errorMessage = 'Your session has expired, please login again'; // Probably in Prod
                        errorMessage = 'You are not logged in';
                        setUser(null);
                    }
                } else if (error.response.status >= 500) {
                    errorMessage = 'Sorry, we\'re currently experiencing technical difficulties. Please try again later.';
                } else {
                    errorMessage = 'An unexpected error occurred. Please try again later.';
                }
            } else {
                errorMessage = 'Cannot connect to the server. Check your internet connection and try again.'
            }
            setError(errorMessage);
        }
    };

    // Logout logic

    const navigate = useNavigate(); // create a history object

    const logout = async () => {
        // Set isLoggingOut to true
        startLogout();
        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/logout`, {}, { withCredentials: true });
            setUser(null);
            finishLogout(); // Reset isLoggingOut to false and navigate to '/'
            // Redirect to '/home' after 1 second
            // if (res.data.isRedirect) {
            //     setTimeout(() => {
            //         navigate('/');
            //     }, 1000);
            // }
        } catch (err) {
            // Differentiate between a 400 and 401 error
            let errorMessage;
            if (err.response.status === 500) {
                errorMessage = 'A server error occured while logging out'
            } else {
                errorMessage = 'Something went wrong while logging out'
            }
            setError(errorMessage);
            finishLogout(); // Ensure to call finishLogout even if there's an error
        }
    };

    // User Registration Logic

    const registerUser = async (email, password, passwordConfirm, isNotificationActive) => {
        dispatch({ type: ACTION.CHECK_USER });

        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/register`,
                { email, password, passwordConfirm, isNotificationActive },
                { withCredentials: true });
            dispatch({ type: ACTION.REGISTER, payload: res.data.message });
            setUser(null);
            // dispatch({ type: ACTION.SET_USER, payload: null }); // Assuming registration doesn't automatically log the user in.
        } catch (error) {
            if (error.response) {
                let errorMessage = error.response.data;
                // console.log('error.response: ', error.response)
                setError(errorMessage);
                // dispatch({ type: ACTION.SET_USER, payload: null });
                setUser(null);
            } else {
                setError(`Our server doesn't seem to be responding. Please wait and try again`);
            }
        }
    };

    // const registerUser = (email, password) => {
    //     return new Promise((resolve, reject) => {
    //         dispatch({ type: ACTION.CHECK_USER });

    //         axios.post('/register', { email, password })
    //             .then(res => {
    //                 setRegistration('abc');
    //                 console.log(res.data.message);
    //                 resolve(res.data.message);  // resolve the Promise with the success message
    //             })
    //             .catch(error => {
    //                 let errorMessage = error.response.data.message
    //                 setError(errorMessage);
    //                 reject(errorMessage);  // reject the Promise with the error message
    //             });
    //     });
    // };


    return (
        <AuthContext.Provider
            value={{
                user: state.user,
                isAuthorized: state.isAuthorized,
                isLoading: state.isLoading,
                error: state.error,
                registrationSuccess: state.registrationSuccess,
                checkUserSession,
                isLoggingOut: state.isLoggingOut,
                startLogout,
                finishLogout,
                logout,
                registerUser,
                clearError
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};