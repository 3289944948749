import React from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/CommonComponentStyles.scss';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Invoice = () => {
    const location = useLocation();
    const invoiceList = location.state?.invoiceList || [];
    const navigate = useNavigate();

    // Routes one level back to /account
    const handleBackRequest = () => {
        navigate("/account");
    };

    return (
        <div className="common-styles">
            <Helmet>
                <title>generatebg - Invoice</title>
                <meta name="description" content="Access your generatebg invoices here. All invoices are historically listed." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/account/invoice`} />
            </Helmet>
            <p className="text-center">
                <button className="generic-account-button" onClick={handleBackRequest}>Go Back</button>
            </p>
            <h1>Your Invoices</h1>
            <ul className="unordered-list text-center">
                {invoiceList.map(invoice => (
                    <li className="list-item" key={invoice._id}>
                        Invoice Date: {new Date(invoice.creationTime).toLocaleDateString()} -
                        <a href={invoice.invoicePdfUrl} target="_blank" rel="noopener noreferrer">Download Invoice as PDF</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Invoice;
