import React from 'react';
import firstImage from '../../../assets/docs/003_usecases/usecases_product_photography.webp';
import secondImage from '../../../assets/docs/003_usecases/usecases_headshot_photography.webp';
import thirdImage from '../../../assets/docs/003_usecases/usecases_hobby_photography.webp';


const useCases = () => {

    return (
        <>
            <h2 className="docs-header">Use Cases For Our Background Generator</h2>
            <h3 className="docs-read-time">Estimated Reading Time: 1 minute</h3>
            <h3 className="docs-subheader table-of-contents">Table of Contents</h3>
            <ul className="docs-list">
                <li className="docs-list-item">Product Photography</li>
                <li className="docs-list-item">Headshots and Portraits</li>
                <li className="docs-list-item">Showcasing Hobby Projects</li>
            </ul>
            <hr className="horizontal-rule"></hr>
            <h3 className="docs-subheader">Product Photography</h3>
            <p>
                Our models work well for product photography. If you are an Ecommerce operator who doesn't have the time to do complex photo shoots or cannot hire an expensive product photographer, Generatebg can be a good choice for you.
            </p>
            <img src={firstImage}
                alt="A smartphone photo of a product on the left with generated background on the right. The image on the left is generatebg input, whereas the generatebg output is on the right."
                className="docs-image">
            </img>
            <h3 className="docs-subheader">Headshots and Portraits</h3>
            <p>If you are looking for a high-quality, cost-efficient headshot solution for your resume or Linkedin profile picture, Generatebg would be a great choice for you.</p>
            <img src={secondImage}
                alt="A person posing against a dull wall on the left and the same person posing against a professional backdrop on the right. The image on the left is generatebg input, whereas the generatebg output is on the right."
                className="docs-image">
            </img>
            <h3 className="docs-subheader">Showcasing Hobby Projects</h3>
            <p>The warhammer 40K figurine-painging community has been very positive about the use of Generatebg for showcasing their projects.</p>
            <p>It's interesting to note that we did not originally think about this use case; it came about organically.</p>
            <img src={thirdImage}
                alt="A dragon statue placed on the floor on the left, and the same dragon statue presented in a mythical, fantasy world on the right. The image on the left is generatebg input, whereas the generatebg output is on the right."
                className="docs-image">
            </img>
            <p>So, if you are a hobbyist who wishes to present their art with mythical, surreal backgrounds, Generatebg would be a great choice for you.</p>
            <p>We will keep updating this doc with new use cases as and when we establish them.</p>
        </>
    );
};

export default useCases;
