import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = function () {

    return (
        <nav className="App-nav--footer">
            <ul className="App-navList--left">
                <li><NavLink className="App-link" to="/cookie-policy">Cookie Policy</NavLink></li>
                <li><NavLink className="App-link" to="/privacy-policy">Privacy Policy</NavLink></li>
                <li><NavLink className="App-link" to="/general-terms-conditions">General Terms and Conditions</NavLink></li>
                <li><NavLink className="App-link" to="/imprint">Imprint</NavLink></li>
                {/* {isAuthorized && (
                    <>
                        <li onClick={toggleMenu}><NavLink className="App-link" to="/remove-background">Remove Background</NavLink></li>
                        <li onClick={toggleMenu}><NavLink className="App-link" to="/process">Generate Background</NavLink></li>
                        <li onClick={toggleMenu}><NavLink className="App-link" to="/pricing">Pricing</NavLink></li>
                        <li onClick={toggleMenu}><NavLink className="App-link" to="/account">Account</NavLink></li>
                        <li onClick={toggleMenu}><NavLink className="App-link" to="/logout">Logout</NavLink></li>
                    </>
                )} */}
            </ul>
            <ul className="App-navList--right"><li className="copyright">© generatebg UG (haftungsbeschränkt) 2024</li></ul>
        </nav >
    )
};

export default React.memo(Footer);