import { AuthContext } from '../../context/AuthContext';
import { useContext, useEffect } from 'react';
import '../styles/CommonComponentStyles.scss';
import { Helmet } from 'react-helmet-async';


const Logout = function () {
    const { logout } = useContext(AuthContext);
    // let isMounted = true; // add a flag 

    useEffect(() => {
        logout();
    }, []);

    return (
        <div className="common-styles">
            {/* <h1>{logoutMessage && <p className={`user-message ${logoutStatus}`}>{logoutMessage}</p>}</h1> */}
            <Helmet>
                <title>generatebg - Logout</title>
                <meta name="description" content="Logging you out of your generatebg account." />
                <link rel="canonical" href={`${process.env.REACT_APP_CLIENT_URL}/logout`} />
            </Helmet>
            <h1>Logging Out...</h1>
        </div>
    );
}

export default Logout;