import React, { useState } from 'react';
import './styles/CollageStyles.scss';
import { useNavigate } from 'react-router-dom';
import collageImage1 from '../assets/collage/collage_1.webp';
import collageImage2 from '../assets/collage/collage_2.webp';
import collageImage3 from '../assets/collage/collage_3.webp';
import collageImage4 from '../assets/collage/collage_4.webp';
import collageImage5 from '../assets/collage/collage_5.webp';
import collageImage6 from '../assets/collage/collage_6.webp';
import collageImage7 from '../assets/collage/collage_7.webp';
import collageImage8 from '../assets/collage/collage_8.webp';

const Collage = ({ handleGetStartedRequest, isAuthorized }) => {
    // Array of imported images
    const images = [
        collageImage1,
        collageImage2,
        collageImage3,
        collageImage4,
        collageImage5,
        collageImage6,
        collageImage7,
        collageImage8,
    ];

    // return (
    //     <div className="collage">
    //         {images.map((image, index) => (
    //             <img key={index} src={image} alt={`Collage ${index + 1}`} className={`collage-item collage-item-${index + 1}`} />
    //         ))}
    //     </div>
    // );

    const [mousePosition, setMousePosition] = useState(null);

    const handleMouseMove = (event) => {
        const { left, top } = event.target.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;
        setMousePosition({ x, y });
    };

    return (
        <div className="collage">
            {/* Images before the button */}
            {images.slice(0, 4).map((image, index) => (
                <img key={index} src={image} alt={`Collage ${index + 1}`} className="collage-item" />
            ))}

            {/* Button in the middle */}
            <button
                className="collage-button collage-item"
                onClick={handleGetStartedRequest}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setMousePosition(null)}
                style={
                    mousePosition ? {
                        // background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, #9B77FF, #5927E5, #7A4FF3)`
                        background: `conic-gradient(at ${mousePosition.x}px ${mousePosition.y}px, #9B77FF, #5927E5, #7A4FF3, #9B77FF)`
                    } : {}
                }
            >
                Start Generating Now
            </button>

            {/* Images after the button */}
            {images.slice(4).map((image, index) => (
                <img key={index + 4} src={image} alt={`Collage ${index + 5}`} className="collage-item" />
            ))}
        </div>
    );
};

export default Collage;