import React, { useState } from 'react';
import axios from 'axios';
import '../styles/CommonComponentStyles.scss';

const UnsubscribeFromEmails = () => {
    const [inputValue, setInputValue] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const isInputCorrect = inputValue.toLowerCase() === "unsubscribe";

    const unsubscribe = async () => {
        setIsLoading(true);

        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/unsubscribe-emails`, {}, { withCredentials: true });
            setIsLoading(false);
            setMessage(res.data.message || "You've been successfully unsubscribed from our emails.");
        } catch (err) {
            setIsLoading(false);
            if (err.response && err.response.data) {
                setMessage(`Error: ${err.response.data}`);
            } else {
                setMessage("An error occurred while processing your request.");
            }
        }
    }

    return (
        <div className="common-styles">
            <h1>Are you sure about this?</h1>
            <p className="text-center">Once you unsubscribe, you will no longer receive <b>new feature updates</b> or <b>special offers</b>!</p>
            <div className="user-form form-card">
                <label className="user-label">
                    Type "unsubscribe" to confirm:
                    <input type="text" className="user-input" value={inputValue} onChange={e => setInputValue(e.target.value)} />
                </label>
                <button
                    className={`user-button ${!isInputCorrect ? 'user-button-disabled' : ''}`}
                    disabled={!isInputCorrect}
                    onClick={unsubscribe}>Unsubscribe
                </button>
                {isLoading ? (
                    <div className="app-loading-container">Loading...</div>
                ) : (
                    message && <p className="user-message">{message}</p>
                )}
            </div>
        </div>
    );
}

export default UnsubscribeFromEmails;
