import React from 'react';

const TestimonialCard = ({ testimonial }) => {
    const { title, text, imageSrc, author, role, stars } = testimonial;

    // Generate star elements
    const starElements = [];
    for (let i = 0; i < 5; i++) {
        starElements.push(
            <span key={i} className="star" style={{ opacity: i < stars ? 1 : 0.2 }}>⭐️</span>
        );
    }

    return (
        <div className="card">
            <div className="layer"></div>
            <div className="content">
                <h3 className="card-title">{title}</h3>
                <div className="stars">{starElements}</div>
                <p>{text}</p>
                <div className="image">
                    <img src={imageSrc} alt={author} width="100" height="100" />
                </div>
                <div className="details">
                    <h2>
                        {author}<br />
                        <span>{role}</span>
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;